import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import auth from '../../services/authService';
import usePagination from '../../hooks/usePagination.js';
import { useStateValue } from '../../context/StateProvider';

function NewGuysToAdd({ setAddingOpenModal, setOpenModal, selectedRoom }) {
  const [roomiesList, setRoomiesList] = useState();
  const [loadingState, setLoadingState] = useState('loading');
  const { Pagination, displayData } = usePagination({
    data: roomiesList,
    amountPerPage: 5,
  });

  const [{ user }] = useStateValue();

  const handleClickOpen = (roomie) => {
    // setRoom(roomie);
    // console.log(roomie);
    // console.log(selectedRoom);
    // console.log(selectedRoom._id);

    // Check if valid room data is provided
    auth
      .editRoomNew(roomie, selectedRoom._id)
      .then(() => {
        toast.success('New Roomie Added!');
        setAddingOpenModal(false);
        setOpenModal(false);
      })
      .catch((err) => {
        // Provide feedback to the user
        // console.log(err.response);
        toast.error(err.response.data.message);
        setAddingOpenModal(false);
        setOpenModal(false);
      });
  };

  const displayRoomies = displayData?.map((roomie) => (
    <tr key={roomie._id}>
      <td className="align-middle">{roomie.first_name}</td>
      <td className="align-middle">{roomie.last_name}</td>
      <td className="align-middle">{roomie.base_rent + roomie.utilities}</td>
      <td className="align-middle">{roomie.signed ? 'YES' : 'NO'}</td>
      <td className="align-middle">{roomie.start_date}</td>
      <td className="align-middle">{roomie.end_date}</td>
      <td className="align-middle">
        <div>
          <Button className="" onClick={() => handleClickOpen(roomie)}>
            Add Roomie
          </Button>
        </div>
      </td>
    </tr>
  ));

  useEffect(() => {
    async function getAllRoomies() {
      await auth.getAllNewRoomies(user._id).then((res) => {
        // filter res.data if it maches the selected room's house name and room name
        const filtered_rooms = res.data.filter((roomie) => {
          if (
            roomie.house?.house_name === selectedRoom.house.house_name &&
            roomie.room_name === selectedRoom.room_name
          ) {
            return roomie;
          }
        });
        setRoomiesList(filtered_rooms);
        setLoadingState('gotThem');
      });
    }

    getAllRoomies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="card table-responsive p-0">
        {Pagination}
        <table className="table table-striped align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name</th>
              <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">Last Name</th>
              <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">all-in-price</th>
              <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">Did Sign?</th>
              <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">Start Date</th>
              <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">End Date</th>
            </tr>
          </thead>
          <tbody>
            {loadingState === 'loading' ? (
              <tr>
                <th>loading</th>
              </tr>
            ) : (
              displayRoomies
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default NewGuysToAdd;
