import React, { useEffect, useRef } from 'react';
import { ConversationalForm } from 'conversational-form';

export default function QuizForm({ new_roomie, setDone, setSched, setQuestions }) {
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  let cf;
  const elem = useRef(null);
  const formFields = [
    {
      tag: 'input',
      type: 'text',
      name: 'chores',
      'cf-questions': 'Are you willing to participate in weekly cleaning chores?',
    },

    {
      tag: 'input',
      type: 'text',
      name: 'quiet_time',
      'cf-questions':
        'Are you okay with quiet nighttime from 10pm to 7am? (no voice must travel from your room to other rooms, this includes long night conversations/phone calls, movies, music, etc).',
    },

    {
      tag: 'input',
      type: 'text',
      name: 'smoking',
      'cf-questions': 'Do you smoke?',
    },

    {
      tag: 'input',
      type: 'text',
      name: 'job',
      'cf-questions': 'What do you do for a living?',
    },

    {
      tag: 'input',
      type: 'radio',
      name: 'lead_ok',
      'cf-questions':
        'Is it okay with you that the neatkeeper/homeowner give you advices if he/she sees that you are not following the rules correctly? You can request a rule change if you would like to change the rule and admin will review it',
      'cf-label': 'Yes',
      value: 'Yes',
    },

    {
      tag: 'input',
      type: 'radio',
      name: 'lead_ok',
      'cf-label': "No, I don't like advices",
      value: "No, I don't like advices",
    },

    {
      tag: 'input',
      type: 'radio',
      name: 'lead_ok',
      'cf-label': 'Not sure',
      value: 'Not sure',
    },
  ];

  const submitCallback = async () => {
    var formDataSerialized = cf.getFormData(true);
    // setQuiz(formDataSerialized);

    // await auth.editNewRoomie(roomie_w_questions, user._id).then((res) => {
    //   toast.success("Lease Signed/A copy will be sent to you via email...!");

    cf.addRobotChatResponse(
      `Thank you ${new_roomie?.first_name}, Your answers were recorded. Please fill the showing times.`
    );

    await sleep(5000);
    setQuestions(formDataSerialized);
    setDone(false);
    setSched(true);
    // });
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    cf = ConversationalForm.startTheConversation({
      options: {
        submitCallback: submitCallback,
        preventAutoFocus: false,
        context: document.getElementById('cf-context'),
        // loadExternalStyleSheet: false
      },
      tags: formFields,
    });

    elem.current.appendChild(cf.el);
  }, []);

  return (
    <div id="cf-context" style={{ width: '100w', height: '100vh' }}>
      <div ref={elem} />
    </div>
  );
}
