import React, { useEffect, useState } from 'react';
import { useStateValue } from '../../context/StateProvider';
import auth from '../../services/authService';
import ArchiveRoomTable from '../utils/ArchiveRoomTable';

function Archive() {
  const [prevList, setPrevList] = useState(null);
  const [{ user }] = useStateValue();

  useEffect(() => {
    async function getAllPreviousRoomies() {
      await auth.getPreviousRoomie(user._id).then((res) => setPrevList(res.data));
    }
    getAllPreviousRoomies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <ArchiveRoomTable rooms={prevList} />
    </div>
  );
}

export default Archive;
