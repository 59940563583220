import React, { useState, useEffect } from 'react';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';
import auth from '../../services/authService';
import { red } from '@mui/material/colors';

function HomeOwnerTable() {
  const [openModal, setOpenModal] = useState(false);
  const [homeowners, setHomeowners] = useState();
  const [editHomeOwner, setEditHomeOwner] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    async function fetchHomeowners() {
      await auth.getAllHomeOwners().then((res) => {
        setHomeowners(res.data);
      });
    }
    fetchHomeowners();
    console.log('refreshed');
  }, [openModal, openDeleteModal]);

  const { register, handleSubmit, reset } = useForm();

  function onSubmit(data) {
    editHomeOwner
      ? auth.editHomeOwner(editHomeOwner._id, data).then(() => setOpenModal(false))
      : auth.addHomeOwner(data).then(() => setOpenModal(false));
  }

  const deleteHomeOwner = async () => {
    await auth.deleteHomeOwner(editHomeOwner._id);
    setOpenDeleteModal(false);
  };

  return (
    <>
      <div className="table-responsive p-0">
        <table className="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Homeowner</th>
              <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">Phone Number</th>
              <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">
                Registered Houses
              </th>
              <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">Payment Plan</th>

              <th className=""></th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <div className="d-flex px-2 py-1">
                  <div>
                    <AddBoxIcon
                      className=" me-3 border-radius-lg"
                      fontSize="large"
                      onClick={() => setOpenModal(true)}
                    />
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <h6 className="mb-0 text-sm">Add Homeowner</h6>
                  </div>
                </div>
              </td>
            </tr>
            {homeowners?.map((homeowner, i) => (
              <tr key={i}>
                <td>
                  <div className="d-flex px-2 py-1">
                    <div>
                      <img
                        // src={homeowner.url}
                        className="avatar avatar-sm me-3 border-radius-lg"
                        alt=""
                      />
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                      <h6 className="mb-0 text-sm">{homeowner.first_name + ' ' + homeowner.last_name}</h6>
                      <p className="text-xs text-secondary mb-0">{homeowner.email}</p>
                    </div>
                  </div>
                </td>

                <td className="align-middle">
                  <p className="text-sm font-weight-bold mb-0">{homeowner.phone}</p>
                </td>

                <td className="text-center ">
                  <span className="badge bg-secondary badge-circle border border-gray-500 border-4">
                    {homeowner.houses.length}
                  </span>
                </td>
                <td className="align-middle"> something </td>
                <td className="align-middle">
                  <ModeEditIcon
                    sx={{ fontSize: 30 }}
                    className="mx-3 text-start font-weight-bold"
                    onClick={() => {
                      const data = {
                        first_name: homeowner.first_name,
                        last_name: homeowner.last_name,
                        email: homeowner.email,
                        phone: homeowner.phone,
                      };
                      setEditHomeOwner(homeowner);
                      reset(data);
                      setOpenModal(true);
                    }}
                  />
                  <DeleteForeverIcon
                    sx={{ fontSize: 30, color: red }}
                    className="mx-3 text-end font-weight-bold"
                    onClick={() => {
                      setEditHomeOwner(homeowner);
                      setOpenDeleteModal(true);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal
        show={openDeleteModal}
        onHide={() => setOpenDeleteModal(false)}
        backdrop="static"
        keyboard={false}
        className="fade"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Homeowner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do you understand what you are doing? this will remove the selected homeowner, if you are sure, go ahead.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setOpenDeleteModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => deleteHomeOwner()}>
            DELETE Homeowner
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={openModal} onHide={() => setOpenModal(false)} backdrop="static" keyboard={false} className="fade">
        <Modal.Header closeButton>
          <Modal.Title>Add a Homeowner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="form1" className="m-4" onSubmit={handleSubmit(onSubmit)} onReset={reset}>
            <Form.Group className="col-md-4 mb-3" controlId="formGridFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control {...register('first_name')} name="first_name" placeholder="First Name" />
            </Form.Group>

            <Form.Group className="col-md-4 mb-3" controlId="formGridLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control {...register('last_name')} name="last_name" placeholder="Last Name" />
            </Form.Group>

            <Form.Group className="col-md-4 mb-3" controlId="formGridEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control {...register('email')} name="email" placeholder="Email Address" />
            </Form.Group>

            <Form.Group className="col-md-4 mb-3" controlId="formGridPhone">
              <Form.Label>Phone</Form.Label>
              <Form.Control {...register('phone')} name="phone" placeholder="Phone Number" />
            </Form.Group>

            <Form.Group className="col-md-4 mb-3" controlId="formGridPass">
              {editHomeOwner ? <Form.Label>change password?</Form.Label> : <Form.Label>initial password</Form.Label>}
              <Form.Control {...register('password')} name="password" placeholder="password" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setOpenModal(false);
              setEditHomeOwner(null);
              reset();
            }}
          >
            Close
          </Button>
          {editHomeOwner ? (
            <Button variant="primary" type="submit" form="form1">
              Edit Homeowner
            </Button>
          ) : (
            <Button variant="primary" type="submit" form="form1">
              Add Homeowner
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default HomeOwnerTable;
