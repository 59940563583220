import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useStateValue } from '../../context/StateProvider';
import auth from '../../services/authService';
import useFetchOwnersData from '../../hooks/useFetchOwnersData';
import GCard from '../utils/GCard';

function FeedbackAdminPage() {
  const [{ user }] = useStateValue();
  const role = auth.getUserRole();
  const [houses, isHousesReady] = useFetchOwnersData(role === 'neatkeeper' ? 'neatkeepers' : 'owner_houses');
  const { register, handleSubmit, reset, setValue, control, watch } = useForm();
  const housesWatch = watch('house');
  let counter = 0;

  function HousesList({ value, inputRef }) {
    return (
      <Form.Control
        className="form-select m-2"
        ref={inputRef}
        // value={!isAddMode ? editRoomie.house._id : null}
        name="house"
        as="select"
        value={value}
        onChange={(e) => setValue('house', e.target.value)}
      >
        <option value="">Select...</option>
        {houses.map((house) => (
          <option key={house._id} value={house._id}>
            {house.house_name}
          </option>
        ))}
      </Form.Control>
    );
  }

  function onSubmit(data) {
    // for (const [key, value] of Object.entries(data.desc)) {
    //   console.log({
    //     chore: key,
    //     date: new Date().toISOString().slice(0, 10),
    //     verified: 0,
    //     who: {
    //       _id: user._id,
    //       username: user.first_name + " " + user.last_name,
    //     },
    //     feed: value,
    //   });
    // }

    // const chore = data.chore.split(",");
    auth
      .addRoomieFeed(data, user._id)
      .then(() => {
        toast.success('Your feedback was submitted');
      })
      .catch((err) => console.log(err.message));
  }

  return (
    <GCard header="Submit a feedback">
      <Form className="m-0" onSubmit={handleSubmit(onSubmit)} onReset={reset}>
        <Row className="card mx-4 ms-4 mb-3 bg-light">
          <Form.Group className="card-body" as={Col} controlId="houseGridChore">
            <Form.Label>Select a House:</Form.Label>
            {!isHousesReady ? (
              'loading'
            ) : (
              <Controller
                render={(props) => (
                  <HousesList
                    value={props.value}
                    onChange={props.onChange}
                    inputRef={props.ref} // wire up the input ref
                  />
                )}
                name="house"
                control={control}
                rules={{ required: true }}
              />
            )}
          </Form.Group>
        </Row>
        <Row className="m-0 mt-3">
          <Form.Group controlId="formGridFeedback">
            {!housesWatch
              ? ''
              : houses
                  .find((x) => x._id === housesWatch)
                  .weekly_chores_0.map((chores) =>
                    chores.chore?.map((chore) => (
                      <div className="mb-5" key={chore._id}>
                        <GCard header={chore.name}>
                          {chore.Feedback.map((desc, i) => (
                            <div className="m-3 mb-0 ms-4" key={'feed-' + i}>
                              {counter++ + 1}

                              {'. ' + desc}

                              <Form.Check
                                {...register(`desc.${chore._id}[${i}]`)}
                                defaultChecked
                                type="radio"
                                id="id-1"
                                label="Yes"
                                name={`desc.${chore._id}[${i}]`}
                                value="yes"
                              />

                              <Form.Check
                                {...register(`desc.${chore._id}[${i}]`)}
                                type="radio"
                                id="id-2"
                                label="No"
                                name={`desc.${chore._id}[${i}]`}
                                value="no"
                              />

                              <Form.Check
                                {...register(`desc.${chore._id}[${i}]`)}
                                type="radio"
                                id="id-3"
                                label="Not Sure"
                                name={`desc.${chore._id}[${i}]`}
                                value="may"
                              />

                              <Form.Check
                                {...register(`desc.${chore._id}[${i}]`)}
                                type="radio"
                                id="id-4"
                                label="Not Applicable"
                                name={`desc.${chore._id}[${i}]`}
                                value="none"
                              />
                            </div>
                          ))}
                          <Form.Label className="m-3 ms-4">{counter + 1 + '. '} Additional Comments:</Form.Label>
                          <div className="me-5">
                            <Form.Control
                              className="m-4 px-4 bg-white"
                              as="textarea"
                              {...register(`desc.${chore._id}[${counter}]`)}
                              name={`desc.${chore._id}[${counter}]`}
                              placeholder="Write your feedback comment"
                              defaultValue={'no comment'}
                            />
                          </div>

                          <Form.Control
                            type="hidden"
                            as="input"
                            {...register(`desc.${chore._id}[${counter + 1}]`)}
                            name={`desc.${chore._id}[${counter + 1}]`}
                            defaultValue={chores.who._id}
                          />
                          <Form.Control
                            type="hidden"
                            as="input"
                            {...register(`desc.${chore._id}[${counter + 2}]`)}
                            name={`desc.${chore._id}[${counter + 2}]`}
                            defaultValue={chore.name}
                          />
                          <p hidden>{(counter = 0)}</p>
                        </GCard>
                      </div>
                    ))
                  )}
          </Form.Group>
        </Row>

        <Row className="d-flex text-center">
          <Form.Group controlId="formButtons">
            <Button variant="info" className="m-0" type="submit">
              Send
            </Button>
          </Form.Group>
        </Row>
      </Form>
      <br />
      <br />
    </GCard>
  );
}

export default FeedbackAdminPage;
