import React from 'react';
import { useStateValue } from '../../context/StateProvider';

function RoomieInfo() {
  const [{ user }] = useStateValue();

  return (
    <div className="card">
      <div className="card-header pb-0 p-3">
        <h6 className="mb-0">ROOMIE&apos;S INFORMATION</h6>
      </div>

      <div className="card-body p-3">
        <ul className="list-group">
          <li className="list-group-item border-0 ps-0 pt-0 text-sm">
            <strong className="text-dark">Wi-Fi Name:</strong>
            {' ' + user.house.wifi_name}
          </li>
          <li className="list-group-item border-0 ps-0 pt-0 text-sm">
            <strong className="text-dark">Wi-Fi Password:</strong>
            {' ' + user.house.wifi_password}
          </li>
          <li className="list-group-item border-0 ps-0 pt-0 text-sm">
            <strong className="text-dark">House Code:</strong>
            {' ' + user.house.house_code}
          </li>
          <li className="list-group-item border-0 ps-0 pt-0 text-sm">
            <strong className="text-dark">House Name:</strong>
            {' ' + user.house.house_name}
          </li>

          <li className="list-group-item border-0 ps-0 text-sm">
            <strong className="text-dark">Room Name:</strong>
            {' ' + user.room_name}
          </li>
          <li className="list-group-item border-0 ps-0 text-sm">
            <strong className="text-dark"> Points:</strong>
            {' ' + user.points}
          </li>
          <li className="list-group-item border-0 ps-0 text-sm">
            <strong className="text-dark"> Negative D&apos;s:</strong>
            {' ' + user.strikes}
          </li>
          <li className="list-group-item border-0 ps-0 text-sm">
            <strong className="text-dark"> Phone No.:</strong>
            {' ' + user.phone}
          </li>
        </ul>
      </div>
    </div>
  );
}

export default RoomieInfo;

// <div className="centerFrame">
//   <div className="centerFrame__title">
//     <Title />
//   </div>

//   <br />

//   <div className="centerFrame__description">
//     <Description />
//   </div>
// </div>
