import React from 'react';
import GCard from '../utils/GCard';
import HomeIcon from '@mui/icons-material/HomeWork';

const Applications = ({ applications }) => {
  return (
    <GCard header="Applications from Homeowners">
      <div className="row mt-lg-4 m-2">
        {applications.map((application, index) => (
          <div key={'appli-' + index} className="col-lg-6 col-md-6 mb-4">
            <div className="card bg-light">
              <div className="card-body p-3">
                <div className="d-flex">
                  <div className="avatar avatar-xl bg-dark border-radius-md p-5">
                    <HomeIcon />
                  </div>
                  <div className="ms-3 my-auto">
                    <h5 className="card-title">{application.first_name + ' ' + application.last_name}</h5>
                    <div className="d-flex">
                      <h6 className="card-subtitle mb-2 text-muted">
                        <span className="me-2  text-dark">Phone:</span>
                        {application.phone}
                      </h6>
                    </div>
                    <h6 className="card-subtitle mb-2 text-muted">
                      <span className="me-2  text-dark">Email:</span>

                      {application.email}
                    </h6>
                    <h6 className="card-subtitle mb-2 text-muted">
                      <span className="me-2  text-dark">Address:</span>

                      {application.address}
                    </h6>
                  </div>
                </div>
                <hr className="horizontal dark" />
                <h6 className="card-subtitle mb-2 text-dark">Main Problem:</h6>
                <p className="card-text">{application.about_me === '' ? 'Empty' : application.about_me}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </GCard>
  );
};

export default Applications;
