import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import auth from '../../services/authService';
import MyForm from './MyForm';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import Handlebars from 'handlebars';

function NewLease() {
  let today = new Date();
  today = today.toISOString().split('T')[0];
  let history = useNavigate();
  let { slug } = useParams();
  const [user, setUser] = useState({});
  const [loadingState, setLoadingState] = useState('loading');
  const [dayStay, setDayStay] = useState(0);
  const [monthStay, setMonthStay] = useState(0);
  const [allInRent, setAllInRent] = useState(0);
  const [done, setDone] = useState(false);
  const [new_roomie, setNewRoomie] = useState();
  const [agreementHtml, setAgreementHTML] = useState('<div></div>');

  // let schema = Joi.object({
  //   renters_signature: Joi.string().min(1).max(50).required(),
  //   first_name: Joi.string().min(1).max(50).required(),
  //   last_name: Joi.string().min(1).max(50).required(),
  //   email: Joi.string()
  //     .email({ tlds: { allow: false } })
  //     .required(),
  //   phone: Joi.string()
  //     .min(10)
  //     .max(20)
  //     .regex(/^\+(?:[0-9]\x20?){6,14}[0-9]$/)
  //     .required(),
  //   smoke: Joi.boolean(),
  //   music: Joi.boolean(),
  //   showing: Joi.boolean(),
  // });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm({
    // resolver: joiResolver(schema),
  });

  // const handleClose = () => setDone(false);

  useEffect(() => {
    auth
      .getNewRoomie(slug)
      .then((res) => {
        setUser(res.data);
        setLoadingState('userLoggedIn');
      })
      .catch(() => {
        setLoadingState('Error');
      });
  }, [slug]);

  useEffect(() => {
    const start = new Date(user?.start_date);
    const end = new Date(user?.end_date);
    setDayStay(Math.floor((end - start) / 86400000) + 1);
    reset({ ...user });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    setMonthStay((dayStay === 30) | (dayStay === 31) ? 1 : (dayStay / 30).toFixed(2));
  }, [dayStay]);

  useEffect(() => {
    setAllInRent(((user?.utilities + user?.base_rent) * monthStay - user?.discounts).toFixed(2));
  }, [user, monthStay]);

  useEffect(() => {
    if (user.agreement?.agreement_text != undefined) {
      const template = Handlebars.compile(user.agreement.agreement_text);
      setAgreementHTML(
        template({
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          phone: user.phone,
          smoke: user.smoke,
          music: user.music,
          showing: user.showing,
          room_name: user.room_name,
          address: user.house.address,
          start_date: user.start_date,
          end_date: user.end_date,
          base_rent: user.base_rent,
          utilities: user.utilities,
          discounts: user.discounts,
          deposit: user.deposit,
          coop: user.coop,
          etransfer_password: user.etransfer_password,
          monthStay: monthStay,
          allInRent: allInRent,
          today: today,
          allprice: user.coop + user.deposit + parseFloat(allInRent),
          halfRent: allInRent / 2,
          halfDeCoop: allInRent / 2 + user.deposit + user.coop,
          pay_rent: user.pay_rent,
          additional_comments: user.additional_comments,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, monthStay, allInRent]);

  async function onSigning(data) {
    setNewRoomie({ ...user, ...data, house: user.house._id });
    setDone(true);
  }

  return (
    <>
      {loadingState === 'loading' ? (
        <h1>loading</h1>
      ) : loadingState === 'Error' ? (
        <h1>Error</h1>
      ) : !user.agreement?.agreement_text || user.agreement.agreement_text == 'System' ? (
        <div className="m-3">
          <Form onSubmit={handleSubmit(onSigning)} onReset={reset}>
            <p>
              <strong>Welcome New Roomie..!</strong>
            </p>
            <br />
            <p>
              This is our roommate rental agreement offer, we do need to see your responses before we accept it. This is
              just an initial request for information and can then be used as our rental agreement should the
              information fit our requirements.
            </p>
            <p>
              To complete this rental agreement, it is simple, nothing to download or print out, you just fill in your
              information below and priniting your signature!
            </p>
            <p>ROOM RENTAL ROOMMATE RENTAL AGREEMENT</p>
            <p>
              This Rental Agreement (this &ldquo;Lease&rdquo;) between &ldquo;Head roommate/lease holder&rdquo; and
              (please fill your info below)
            </p>
            <h3>Roommate:</h3>
            <br />
            <Row>
              <Form.Group as={Col} controlId="formGridFirstName">
                <Form.Label>First Name:</Form.Label>
                <Form.Control
                  {...register('first_name')}
                  name="first_name"
                  placeholder="First Name"
                  isValid={!errors.first_name}
                  isInvalid={!!errors.first_name}
                  readOnly={done ? true : false}
                />
                <Form.Control.Feedback type="invalid">{errors.first_name?.message}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridLastName">
                <Form.Label>Last Name:</Form.Label>
                <Form.Control
                  {...register('last_name')}
                  name="last_name"
                  placeholder="Last Name"
                  isValid={!errors.last_name}
                  isInvalid={!!errors.last_name}
                  readOnly={done ? true : false}
                />
                <Form.Control.Feedback type="invalid">{errors.last_name?.message}</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  {...register('email')}
                  name="email"
                  type="email"
                  placeholder="Email Address"
                  isValid={!errors.email}
                  isInvalid={!!errors.email}
                  readOnly={done ? true : false}
                />
                <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPhone">
                <Form.Label>Phone Number (must be in this format: +15874050076)</Form.Label>
                <Controller
                  control={control}
                  name="phone"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput placeholder="Enter phone number" value={value} onChange={onChange} />
                  )}
                />
              </Form.Group>
            </Row>
            <p>
              By signing this lease with your email address & phone number you agree to be contacted by email & phone
              via Call, SMS & Whatsapp. Please complete what follows:
            </p>
            <Row>
              <Form.Group as={Col} controlId="formGridSmoke">
                <Form.Check
                  {...register('smoke')}
                  name="smoke"
                  type="switch"
                  id="Smoke-switch"
                  label="Do you smoke?"
                  disabled={done ? true : false}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridMusic">
                <Form.Check
                  {...register('music')}
                  name="music"
                  type="switch"
                  id="Music-switch"
                  label="Do you listen to loud music?"
                  disabled={done ? true : false}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridShowing">
                <Form.Check
                  {...register('showing')}
                  name="showing"
                  type="switch"
                  id="showing-switch"
                  label="Are you willing to show any vacant rooms to prospective roommates?"
                  disabled={done ? true : false}
                />
              </Form.Group>
            </Row>
            <br />
            <b> The parties agree as follows on:</b> {today}
            <br />
            <p>__________________________________________________________</p>
            <br />
            <h3>Rent:</h3>
            <br />
            <p>
              <b>With our short term fixed rent policy , you never get surprised with high utility bills</b>. We always
              give you the price with at least some time you can decide not to rent from us and find other
              accommodations.
            </p>
            <div>
              Your monthly base rent is:
              <strong>
                <div style={{ color: 'red' }}>$ {user.base_rent}</div>
              </strong>
            </div>
            <div>
              Utilities:
              <strong>
                <div style={{ color: 'red' }}>$ {user.utilities}</div>
              </strong>
            </div>
            <div>
              Discounts:
              <strong>
                <div style={{ color: 'red' }}>$ {user.discounts}</div>
              </strong>
            </div>
            <div>
              Our all-in-price for you for {monthStay} month/s is:
              <strong>
                <div style={{ color: 'red' }}>$ {allInRent}</div>
              </strong>
            </div>
            <div>
              Refundable damage deposit:
              <strong>
                <div style={{ color: 'red' }}>$ {user.deposit}</div>
              </strong>
            </div>
            <div>
              Refundable Co-op deposit:
              <strong>
                <div style={{ color: 'red' }}>$ {user.coop}</div>
              </strong>
            </div>
            <div>
              Total due is:
              <strong>
                <div style={{ color: 'red' }}>$ {user.coop + user.deposit + parseFloat(allInRent)}</div>
              </strong>
            </div>
            <div>
              Since you are not here, please pay half the rent + deposit to hold the room and the other half of the rent
              to pick up your room keys when you arrive
            </div>
            <strong>
              <p style={{ color: 'red' }}>
                Please etransfer ${allInRent / 2} + ${user.deposit + user.coop} = ${' '}
                {allInRent / 2 + user.deposit + user.coop} to hold your room, etransfer to{' '}
                <i>theapartmenthead@outlook.com</i> (for etransfer, please use the question, what is my password?,
                answer is: {user.etransfer_password}{' '}
              </p>
            </strong>
            <strong>
              <p style={{ color: 'red' }}>
                When you arrive please then pay us:
                {allInRent / 2}
              </p>
            </strong>
            <br />
            <p>TERMS LISTED ABOVE:</p>
            <br />
            {user?.landlord ? (
              <p>
                <strong>Landlord lives at house</strong>
                Please be aware that the landlord who owns the house does use the house including the bathroom and the
                kitchen. He/she may occupy a room full time, or it might be part time or he/she may have a recreational
                trailer/vehicle on site.
              </p>
            ) : (
              <></>
            )}
            <p>
              <strong>PREMISES.</strong>Lease owner/head roommate, in consideration of the lease payments provided in
              this Rental agreement, to the Roommate a bedroom &ldquo;
              {user.room_name}&rdquo;(the &ldquo;Premises&rdquo;) located at ___
              {user.house.address}
            </p>
            <br />
            <br />
            <p>
              <strong>TERM.</strong> The rental agreement term will begin on
              {' ' + user.start_date.split('T')[0]} , __ ___________ will be a __ {monthStay} _ month rental agreement
              ending _{' ' + user.end_date.split('T')[0]}
            </p>
            <br />
            <p>
              Please note, we have more than a few clauses that relate to cleaning and helping out with the house. Since
              it is a shared house, we need everyone to help make sure the house is a nice place to live. So, in order
              to do this, there is scheduling and some feedback loops to help maintain a nice living arrangement. Also,
              occasionally people move out. We require current roommates to help show the upcoming open room for a
              couple of reasons. First, it helps keep management costs down. Secondly, as important as what the room
              looks like, prospective roommates want to know who they may be living with. So, this is a good chance to
              see if there might be high roommate compatibility.
              <strong>This is NOT a month to month rental agreement.</strong>
            </p>
            <p>
              <strong></strong>This is a fixed term rental agreement and the apartment head is under no legal
              requirement to renew the rental agreement at that time. Likewise, the roommate is under no legal
              requirement to stay on at that time.
            </p>
            <p>
              <strong>RENTAL AGREEMENT LENGTH AND PROCESS</strong>
            </p>
            <p>
              We only offer fixed term rental agreements. When you have just over 2 weeks remaining in your rental
              agreement, you might be provided a new rental agreement. You will have 2 days to accept that offer. Should
              we not hear back from you and a signed rental agreement has not been returned, advertisements will be
              placed and new roommates will be invited to view this rental. Please note that the first showing date will
              be 96 hours after the email has been sent at 7 pm.
            </p>
            <br />
            <p>
              <strong>Guidelines and culture</strong>
            </p>
            <p>
              <em>
                In general, we are looking to create a respectful, quiet, clean, and friendly setting. To do this, we
                need folks to help keep it clean, help each other when we can, and try to keep in mind the effect we
                have on others :)
              </em>
            </p>
            <p>
              <strong>Acceptable Behaviour in the Household</strong>
            </p>
            <p>Drunken, loud, threatening, intimidating, or disrespectful behavior will not be tolerated.</p>
            <br />
            <h3>Visitors</h3>
            <p>
              Occasional visitors are welcome for short visits.
              <br />
              Girlfriend/boyfriends as long as you spend some time at their place as well. You are responsible to see
              that your guests are aware of these guidelines. We would prefer if you do not have overnight guests until
              we get to know you a bit. No strangers, pick ups, hitchhikers, couch surfers overnight. If partners are
              coming from out of town, they can stay for 3 days maximum per month. Other than that, you will need to
              find alternative housing, the apartment is small and it isn&rsquo;t suited for couples longer than a few
              days.
            </p>
            <br />
            <h3>Sexual Guidelines</h3>
            <p>
              Sexual activity is fine, but please clean up after yourself and conduct yourself in a way that is
              respectful of others and their sensibilities, keeping in mind that everyone has their own work and sleep
              schedule
            </p>
            <p>
              <strong>Bedroom Notes</strong>
            </p>
            <p>
              Please don&apos;t smoke in your room....it is instantly noticeable to all rooms even if your window is
              open and towels under your door. Please don&apos;t keep dirty dishes and old food in your room, it
              attracts pests.
            </p>
            <br />
            <p>
              <strong>BATHROOM:</strong>
            </p>
            <p>
              Rinse tub out after use. Clean up sink area after use, especially whiskers (shaving stubble). Our drains
              plug from hair. In the bathtubs, use the drainer hair catchers to prevent this. Otherwise, plumbers will
              be called to clear the drains and our monthly cleaning fee will go up. Please limit your use of 10 minutes
              at a time of bathroom use. Bathroom space is limited. If you need very long periods of bathroom time, DO
              NOT RENT FROM US.
            </p>
            <br />
            <p>
              <strong>Storage</strong>
            </p>
            <p>
              The roommate/tenant/renter/guest shall not store goods, materials, equipment or property of any kind,
              excluding registered vehicles on the rental agreement upon the Premises unless such property is approved
              by the homeowner in writing and notarized by a lawyer or notary public.
            </p>
            <br />
            <p>
              <strong>Use of Common Areas</strong>
            </p>
            <p>
              Our goal is to have uncluttered and clean common areas like living rooms and laundry rooms. To achieve
              this goal, no personal belongings can be left in the common areas. This will ensure that each common area
              will remain clean and uncluttered. So the living room will be nice and uncluttered. The kitchen counters
              will have room for you to cut your food as it will not be loaded with other people’s food in your way.
              Also, when you got to clean the bathroom, there will not be 5 different shampoo bottles in the shower and
              3 tooth brushes you have to move. Also when you take a shower, there just is more room, less bottles and
              soaps everywhere and you can avoid seeing those slimy soaps someone forgot about and you have to try not
              to touch. It just more area for people to use and it makes it easy to clean when it is your turn. Please
              remove shoes or boots when entering the household, making sure that footwear is placed on an appropriate
              mat or boot tray. There is no coat closet in the house. The living room TV is shared.
            </p>
            <br />
            <p>
              <strong>Snow and Lawn:</strong>
            </p>
            <p>In order to keep costs down, you are required to take a turn in cutting grass and shovelling snow.</p>
            <br />
            <p>
              <strong>Use of Utilities</strong>
            </p>
            <p>
              Within reason, please turn off the TV and the lights when leaving the house or when a room is likely to
              remain unoccupied. In cold weather, please do not leave windows open. In your own bedroom, please turn off
              lights, TVs, computers, and small heaters if you know that it will be empty for several hours or more.
            </p>
            <br />
            <p>
              <strong>Parking</strong>
            </p>
            <p>Street parking, please don&apos;t block our neighbours driveway</p>
            <br />
            <p>
              <strong>Noise</strong>
            </p>
            <p>
              We aspire to having a quiet house most of the time. At night, especially from about 10pm to 7am we are
              particularly conscious of keeping the volume down for voices, laughter, TVs and stereos, as some of us
              need to be up for work by 5 am. Volumes should not migrate to other rooms. It is good if we keep each
              other advised of our need for sleep, especially if it is at times other than regular night time hours.
            </p>
            <br />
            <p>
              <strong>Pets</strong>
            </p>
            <p>No pets...sorry.</p>
            <br />
            <p>
              <strong>Alchohol &amp; Hard Drugs</strong>
            </p>
            <p>
              Our houses are for those working or studying. With that said, hard drugs may NOT be consumed in the home
              and anyone with this problem will be asked to leave. We do not have a prohibition against alcohol, social
              drinking is fine. Edible marijuana is fine. No smoking of marijuana inside the house. The smell of
              marijuana should not affect the enjoyment of the apartment for others. Inebriation at the house is not
              allowed. Smoking is prohibited in the house and the vicinity of the house, a minimum of 5 meters is
              required from a door or a window. Hard drugs may not be consumed in the home and anyone with this problem
              will be asked to leave.
            </p>
            <br />
            <br />
            <p>
              <strong>General Points:</strong>
            </p>
            <p>
              We can be equally responsible for the upkeep of this household by sharing chores and tasks. We aim for a
              relaxed, easygoing atmosphere in our home. Our housekeeping standards are about appearing more than
              functional so that the house is clean, comfortable, and attractive for everyone and has some level of curb
              appeal for visitors, friends, or prospective roommates. We generally prefer neat and tidy surrounding
            </p>
            <br />
            <p>
              <strong>Use of Kitchen</strong>
            </p>
            <p>Please no cooking after 9:30 pm</p>
            <p>
              Please no deep frying and no frying for long periods of time, our houses are not equipped with great
              kitchen ventilation systems.
            </p>
            <p>Please wipe up countertops after use.</p>
            <p>You are required to do your dishes within 2 hours of using the kitchen.</p>
            <p>Do not cut on the counters, but use a cutting board.</p>
            <p>Please do not sit on counters. </p>
            <br />
            <p>
              <strong>Use of Laundry Room</strong>
            </p>
            <p>
              Laundry machines (one washer, one dryer) are shared, please clear the dryer filter for the dryer after use
              so dryer does not overheat.
            </p>
            <p>Please no laundry after 9:45 pm</p>
            <br />
            <p>
              <strong>Quiet</strong>
            </p>
            <p>
              We specialise in quiet/ working people type accommodations. Generally everyone is friendly but if you want
              to &ldquo;hang out&rdquo;, have friends over, game in the living room, this is not for you.
            </p>
            <br />
            <p>
              <strong>USE OF FURNITURE INCLUDE BED MATRESS</strong>
            </p>
            <p>
              The furnishing are in good shape including the mattress. Do not sleep on the matress without a sheet. You
              will be asked to show that you have sheets prior to move in. Should you break the furniture or
              <strong>sleep on the mattress without a sheet</strong>, you will be charged replacement cost of the item
              from your damage deposit and/or cleaning deposit.
            </p>
            <br />
            <p>
              <strong>ROOM SHOWINGS.</strong>
            </p>
            <p>
              We need you to help show rooms. That way, you can give us feedback on who you would like to live with and
              the potential roomie can decide if not only the place is right for them, but their new roomies would work
              for them.
            </p>
            <p>
              When we have an upcoming room that will be open in the house, we will whatsapp you on Monday to find one
              specific day/time you pick for your showing date.You can set up and adjust the times and dates you could
              do a showing on the , it
            </p>
            <br />
            <p>
              <strong>HOUSE CLEANING</strong>
            </p>
            <p>
              The roommate agrees to take part in the cleaning schedule of the house and agrees to clean his/her dishes
              after eating. Also, the roommate agrees to contribute to what we need on supplies to buy toilet paper and
              cleaning supplies
            </p>
            <br />
            <p>
              <strong>UTILITIES AND SERVICES</strong>
            </p>
            <p>
              Roommates shall be responsible for their proportion of the utilities and services incurred in connection
              with the Premises. If we have empty rooms, your utility cost will likely increase.
            </p>
            <br />
            <p>
              <strong>VERIFICATION</strong>
            </p>
            <p>
              The roommate allows the head roommate/lease holder/homeowner/landlord to provide rental information to VLR
              verified landlord references corp. so that an accurate head roommate/lease holder reference can be
              provided{' '}
            </p>
            <br />
            <p>
              <strong>SUBORDINATION OF LEASE</strong>
            </p>
            <p>
              This lease is subordinate to any mortgage that now exists, or may be given later by Head roommate/lease
              holder, with respect to the Premises.
            </p>
            <br />
            <p>
              <strong>RULES:</strong>
            </p>
            <ul>
              <li>NO SMOKING</li>
              <li>Do dishes after eating your meal</li>
              <li>Can only have 1 friend over at a time</li>
              <li>Participate in cleaning schedule</li>
              <li>No pets</li>
              <li>No loud music</li>
              <li>Must be actively job searching, working, or in school</li>
            </ul>
            <br />
            <p>
              <strong>FEEDBACK AND LEADERSHIP</strong>
            </p>
            <p>
              Please take careful note that the houses are apartment-lead run. An apartment-lead is a more seasoned
              roomie who has lived at the house for a while and has agreed to be responsible for it. This means that
              decisions about the house will be made by the apartment lead along with the homeowner. We do welcome
              opinions and feedback and please forward them to the lead or homeowner. Your input is appreciated,
              However, roomies are not owners of the house and not owners of the rules. So, if a group of roomies
              “decide something” without approval of the apartment lead or homeowner, it may not come into effect. We
              would like all roomies to take ownership of house rules and inform others not following the rules. Also,
              please do not take offence of apartment leads advice because it is their job and responsibility.
            </p>
            <p>
              {' '}
              <strong>Role of Neat keepers:</strong>{' '}
            </p>
            <p>
              The roommate allows the neat keepers to come as needed to check on and clean the house. Each house has a
              neat keeper who comes on Mondays This person’s roles is provide feedback on cleaning and “fix” areas that
              were not cleaned properly. It is possible that the lead will be acting as a neat keeper due to a variety
              of factors. Both the lead and the neat keepers are required to provide feedback on chores. Please don’t
              give them a hard time for this, it is their job and they are actually help your house stay clean and nice!
              You wouldn’t want to live in a dirty house, would you? The neat keeper will also “fix” any chore that was
              not done well. Please note, the cost comes out of the co-op deposit of the roomie who was supposed to do
              the chore. Chores are evaluated as “complete”, “complete this time but subject to improvement” and “not
              complete”. The cost will be deducted from the co-op fee of the roomie responsible for it. If all the
              chores are done well, we have no problem paying for the neat keeper of our own pocket and we will give the
              house a “gold star” which, as you will see, has certain advantages!
            </p>
            <p>
              <strong>ASSIGNAILITY/SUBLETTING</strong>
            </p>
            <p>
              Roommate may not assign or sublease any interest in the Premises, nor assign, mortgage or pledge this
              Lease, without the prior written consent of Head roommate/lease holder, which consent may be exercised in
              the sole discretion of the Head roommate/lease holder.
            </p>
            <br />
            <p>
              <strong>LANDLORD:</strong>
            </p>
            <p>
              The roommate acknowledges that he/she/they rent a room in the house, the landlord has the right to also
              take a room as a space for him/her/they to live or use and may also use kitchen and bathroom.
            </p>
            <p>
              <strong>DISAGREEMENTS:</strong>
            </p>
            <p>
              Should there be a conflict between roomies or between a roomie and the lead or head office, both parties
              agree to submit the conflict to roomiesarbitration.club. Arbitrators are unbiased seasoned past roomies
              with good judgement.
            </p>
            <br />
            <p>
              <strong>Expiry:</strong>
            </p>
            <p>
              This lease offer expires 24 hours from the date/time it was sent. Should it not be returned signed and
              deposit paid, this lease will be null and void. Should first month rent and damage deposit along with ID,
              appropriate verification of employment or study, this will also render this lease null and void.
            </p>
            <br />
            <p>
              <strong>How do I pay my rent?</strong>
            </p>
            <p>{user.pay_rent}</p>
            <br />
            <p>
              <strong>Additional comments</strong>
            </p>
            <p>{user.additional_comments}</p>
            <br />
            <p>
              <strong>
                <strong>Apartment Lead:</strong>
              </strong>
            </p>
            <p>
              <strong>Name: {user.house.lead}</strong>
            </p>
            <br />
            <p>
              <strong>
                By typing my signature and sending it via the Internet, I acknowledge that I have read and understand
                all information provided in the lease, that I intend Todd Ogryzlo rely upon it; that I intend to be
                bound thereby; and that I understand and agree that my electronic signature is the equivalent of a
                manual written signature.
              </strong>
            </p>
            <br />
            <p>
              <strong>ROOMMATE:</strong>
            </p>
            <p>
              <strong>
                Name: {user.first_name} {user.last_name}
              </strong>
            </p>
            <Form.Group as={Col} controlId="formGridFirstName">
              <Form.Label>Signature:</Form.Label>
              <Form.Control
                {...register('renters_signature')}
                name="renters_signature"
                placeholder="Sign Name"
                isValid={!errors.renters_signature}
                isInvalid={!!errors.renters_signature}
                readOnly={done ? true : false}
              />
              <Form.Control.Feedback type="invalid">{errors.renters_signature?.message}</Form.Control.Feedback>
            </Form.Group>
            <Button className="mt-2" variant="primary" type="submit" disabled={done ? true : false}>
              Sign
            </Button>
            <Button variant="danger" className="mt-2 ms-2" onClick={() => history(-1)} disabled={done ? true : false}>
              Cancel
            </Button>
          </Form>
        </div>
      ) : (
        <div>
          <div className="m-3">
            <div
              dangerouslySetInnerHTML={{
                __html: agreementHtml,
              }}
            />
          </div>

          <Form onSubmit={handleSubmit(onSigning)} onReset={reset}>
            <Form.Group as={Col} controlId="formGridFirstName">
              <Form.Label>Signature:</Form.Label>
              <Form.Control
                {...register('renters_signature')}
                name="renters_signature"
                placeholder="Signature"
                isValid={!errors.renters_signature}
                isInvalid={!!errors.renters_signature}
                readOnly={done ? true : false}
              />
              <Form.Control.Feedback type="invalid">{errors.renters_signature?.message}</Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" type="submit" disabled={done ? true : false}>
              Sign
            </Button>
            <Button variant="danger" className="m-2" onClick={() => history(-1)} disabled={done ? true : false}>
              Cancel
            </Button>
          </Form>
        </div>
      )}
      {done ? <MyForm new_roomie={new_roomie} user={user} /> : <></>}
    </>
  );
}

export default NewLease;
