import axios from 'axios';

if (process.env.REACT_APP_DEMO === 'TRUE') {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL_DEMO;
} else axios.defaults.baseURL = process.env.REACT_APP_API_URL;

if (process.env.NODE_ENV === 'development') axios.defaults.baseURL = 'http://localhost:3003/';

axios.interceptors.response.use(null, (error) => {
  const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;
  if (!expectedError) {
    console.log('Logging the error', error);
    alert('An unexpected error occured');
  }
  return Promise.reject(error);
});

function setJwt(jwt) {
  axios.defaults.headers.common['x-auth-token'] = jwt;
}

const functions = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
};

export default functions;
