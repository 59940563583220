import React, { useState } from 'react';
import { Tab, Tabs, Box } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
// import { useStateValue } from "../../context/StateProvider";

function UserTabs() {
  const tabNametoIndex = {
    '/user': 0,
    '/lease': 1,
    '/feedbackpage': 2,
    '/showings': 3,
    '/supplies': 4,
    '/rulechange': 5,
  };

  const pathname = useLocation().pathname;

  const [value, setValue] = useState(tabNametoIndex[pathname]);

  // const [{ user }] = useStateValue();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="col-lg-7 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
      <div className="nav-wrapper position-relative end-0">
        <ul className="nav nav-pills nav-fill p-1" role="tablist">
          <li className="nav-item col-lg-6 col-md-6 col-sm-2 col-2">
            <Box sx={{}}>
              <Tabs value={value ? value : 0} onChange={handleChange} variant="scrollable" scrollButtons="auto">
                <Tab label="Home" to="/user" component={Link} />
                <Tab
                  label="Rental Agreement"
                  to="/lease"
                  // disabled={user.did_sign_lease === 1}
                  component={Link}
                />
                <Tab label="Feedback" to="/feedbackpage" component={Link} />
                <Tab label="Showings" to="/showings" component={Link} />
                <Tab label="Supplies" to="/supplies" component={Link} />
                <Tab label="Rule Change Request" to="/rulechange" component={Link} />
              </Tabs>
            </Box>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default UserTabs;
