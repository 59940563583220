import HardwareIcon from '@mui/icons-material/Hardware';
import SupportIcon from '@mui/icons-material/Support';
import React, { useEffect, useState } from 'react';
import { useStateValue } from '../../context/StateProvider';
import auth from '../../services/authService';
import Description from './HorizontalCards';
import DueCard from './DueCard';
import MiniCard from './MiniCard';
import ObjectID from 'bson-objectid';
import SuppliesWhiteBoard from './SuppliesWhiteBoard';

function UserHome() {
  const [{ user }] = useStateValue();

  // let today = new Date();
  const [, setSupplyAmount] = useState(0);
  const amount = user.rents?.last_rent?.last_rent_amount
    ? '$' + user.rents?.last_rent?.last_rent_amount
    : 'Amount will be available next month';
  // const month = new Date(user.rents?.last_rent?.last_rent_date);
  // let half_rent = parseFloat(
  //   (
  //     user.base_rent -
  //     user.supplies.anchored_total +
  //     user.locker_rent +
  //     supplyAmount / user.house.numTenants +
  //     user.house.utilities -
  //     user.discounts
  //   ).toFixed(2)
  // );

  // const due = (
  //   user.past_due -
  //   user.payments.payments_total +
  //   user.rents.rent_list_total
  // ).toFixed(2);

  useEffect(() => {
    async function getAllSupplies() {
      let total_supplies = 0;

      await auth.getAllHouseSuppliesAmount(user.house._id).then((res) => {
        total_supplies = res.data['sum'];
      });
      setSupplyAmount(total_supplies);
    }
    getAllSupplies();
  }, [user.house._id]);

  return (
    <>
      <div className="row">
        <div className="col-12 mb-4">
          <DueCard
            icon={<HardwareIcon />}
            color={'danger'}
            title={'Supplies Whiteboard'}
            // content={due >= 0 ? "$" + due : "-$" + Math.abs(due)}
            // the content is a white board for supplies as a table having columns: what and who.
            // what is the supply name and who is the person who bought it.
            // the table has a button to add a new supply and a button to delete a supply.
            content={<SuppliesWhiteBoard />}
          />
        </div>

        <div className="col-12 mb-4">
          <DueCard
            icon={<HardwareIcon />}
            color={'secondary'}
            title={'Latest Amount Due'}
            // content={due >= 0 ? "$" + due : "-$" + Math.abs(due)}
            content={user.did_sign_lease === 0 ? 'PLEASE SIGN THE RENTAL AGREEMENT' : amount}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-4 col-sm-6 mb-4">
          <MiniCard
            icon={<SupportIcon sx={{ fontSize: 30 }} />}
            color={'dark'}
            title={'Co-op Deposit'}
            content={'$' + (user.coop_fee - user.coop_discount)}
            footer={
              <>
                <span className="text-warning text-sm font-weight-bolder">{'$' + user.coop_discount + ' '}</span>
                discounted from original
              </>
            }
          />
        </div>
        <div className="col-xl-4 col-sm-6 mb-4">
          <MiniCard
            icon={<HardwareIcon />}
            color={'primary'}
            title={'Supplies You bought'}
            content={'$' + user.supplies.verified_total}
            footer={
              <>
                <span className=" text-sm ">Will appear on the next agreement</span>
              </>
            }
          />
        </div>

        <div className="col-xl-4 col-sm-12 mb-4">
          <DueCard
            icon={<HardwareIcon />}
            color={'secondary'}
            title={'Weekly Chores'}
            doNotUseHeaderForContent
            content={
              <div className="card table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th className="">What</th>
                      <th className="">Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {user.chore_weekly.map((chore) => (
                      <tr key={chore._id}>
                        <td>{chore.name}</td>
                        <td>{chore.description ?? 'No description provided yet'}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            }
            footer={'Complete by Sunday night'}
          />
        </div>

        {/* <div className="col-xl-3 col-sm-6 mb-4">
          <MiniCard
            icon={<HardwareIcon />}
            color={"danger"}
            title={"Rent Due"}
            content={"$" + half_rent}
            footer={`Half due by 15th and half due by end of ${today.toLocaleString(
              "default",
              {
                month: "long",
              }
            )}`}
          />
        </div> */}

        <div className="col-md-8">
          <Description />
        </div>

        <div className="col-md-4 hidden-xs hidden-sm">
          <div className="card mb-4">
            <div className="card-header pb-0 p-3">
              <h6 className="mb-0">Payment History</h6>
            </div>
            <div className="card-body p-3">
              <ul className="list-group">
                {user.payments.payments_invoices ? (
                  user.payments.payments_invoices.map((com) => (
                    <li className="list-group-item border-0 ps-0 text-sm" key={com._id}>
                      <strong className="text-dark">
                        {ObjectID(com._id).getTimestamp().toISOString().slice(0, 10) + ': '}
                      </strong>
                      ${com.payment_amount + ' ' + com.payment_type}
                    </li>
                  ))
                ) : (
                  <li className="list-group-item border-0 ps-0 text-sm">No Payments Yet</li>
                )}
              </ul>
            </div>
          </div>

          <div className="card mb-4">
            <div className="card-header pb-0 p-3">
              <h6 className="mb-0">Lease History</h6>
            </div>
            <div className="card-body p-3">
              <ul className="list-group">
                {user.rents.rent_list ? (
                  user.rents.rent_list.map((com) => (
                    <li className="list-group-item border-0 ps-0 text-sm" key={com._id}>
                      <strong className="text-dark">
                        {ObjectID(com._id).getTimestamp().toISOString().slice(0, 10) + ': '}
                      </strong>
                      ${com.rent_amount}
                    </li>
                  ))
                ) : (
                  <li className="list-group-item border-0 ps-0 text-sm">No Payments Yet</li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserHome;
