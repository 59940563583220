import React from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SearchIcon from '@mui/icons-material/Search';
import auth from '../../services/authService';
import { Link } from 'react-router-dom';

function AdRoomTable({ rooms }) {
  const onDelete = (adId) => {
    auth.deleteAd(adId).then((res) => console.log(res));
  };

  return (
    <div className="table-responsive p-0">
      <table className="table align-items-center justify-content-center mb-0">
        <thead>
          <tr>
            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Room</th>
            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Base Rent</th>
            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
              Current Utilities
            </th>
            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Date Created</th>
            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Available Date</th>
            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">House</th>
            <th className=""></th>
          </tr>
        </thead>

        <tbody>
          {rooms.map((room, i) => (
            <tr key={i}>
              <td>
                <div className="d-flex px-2">
                  <div>
                    <img src={room.image_array[0]?.url} className="avatar avatar-sm me-3 border-radius-lg" alt="" />
                  </div>
                  <div className="my-auto">
                    <h6 className="mb-0 text-sm">{room.room_id.room_name}</h6>
                  </div>
                </div>
              </td>
              <td className="align-middle">
                <p className="text-sm font-weight-bold mb-0">{'$' + room.base_rent}</p>
              </td>
              <td className="align-middle">
                <p className="text-sm font-weight-bold mb-0">{'$' + room.house.utilities}</p>
              </td>

              <td className="align-middle">
                <p className="text-sm font-weight-bold mb-0">
                  {new Date(room.date_created).toLocaleDateString('en-GB')}
                </p>
              </td>
              <td className="align-middle">
                <p className="text-sm font-weight-bold mb-0">
                  {new Date(room.available_date).toLocaleDateString('en-GB')}
                </p>
              </td>
              <td>
                <div className="d-flex px-2 py-1">
                  <div>
                    <img src={room.house.url} className="avatar avatar-sm me-3 border-radius-lg" alt="" />
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <h6 className="mb-0 text-sm">{room.house.house_name}</h6>
                    <p className="text-xs text-secondary mb-0">{room.house.address}</p>
                  </div>
                </div>
              </td>

              <td className="align-middle">
                <DeleteForeverIcon
                  role="button"
                  className="text-danger enable-button-pointers"
                  onClick={() => onDelete(room._id)}
                />
              </td>

              <td className="align-middle">
                <Link
                  to={'/adview'}
                  state={{
                    data: room,
                  }}
                >
                  <SearchIcon className="text-secondary font-weight-bold" />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AdRoomTable;
