import { joiResolver } from '@hookform/resolvers/joi';
import SearchIcon from '@mui/icons-material/Search';
import Joi from 'joi';
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import auth from '../../services/authService';
import ObjectID from 'bson-objectid';
import Advertise from '../homeowner-components/Advertise';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import NewGuysToAdd from '../homeowner-components/NewGuysToAdd';
import { useStateValue } from '../../context/StateProvider';
import { addBathroomDescriptionToBathroomChore } from '../../utils/chores';

const VacantModal = ({ openModal, setOpenModal, selectedRoom, handleRoomCurrentChanges }) => {
  // get the user id from the context
  const [{ user }] = useStateValue();
  const handleVacantRoomClick = () => {
    auth
      .markVacant(selectedRoom._id, user._id)
      .then(() => {
        setOpenModal(false);
        handleRoomCurrentChanges({
          is_empty: true,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        toast.success('Room marked as vacant');
      });
  };
  return (
    <Modal show={openModal} onHide={() => setOpenModal(false)} backdrop="static" keyboard={false} className="fade">
      <Modal.Header closeButton>
        <Modal.Title>Mark the room as vacant</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        You are about to mark this room as vacant, this will archive the roomie&apos;s information and remove the
        information from the room. The previous roomie,
        <table className="table bg-light">
          <thead>
            <tr className="text-xs">
              <th className="text-start">Roomie&apos;s Information</th>
              <th className="text-start">Values</th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-xxs">
              <td>Previous Roomie:</td>
              <td className="text-dark font-weight-bold ms-sm-2">
                {selectedRoom?.first_name + ' ' + selectedRoom?.last_name}
              </td>
            </tr>
            <tr className="text-xxs">
              <td>Email:</td>
              <td className="text-dark font-weight-bold ms-sm-2">{selectedRoom?.email}</td>
            </tr>
            <tr className="text-xxs">
              <td>Phone:</td>
              <td className="text-dark font-weight-bold ms-sm-2">{selectedRoom?.phone}</td>
            </tr>
            <tr className="text-xxs">
              <td>Damage Deposit:</td>
              <td className="text-dark font-weight-bold ms-sm-2">${selectedRoom?.deposit}</td>
            </tr>
            <tr className="text-xxs">
              <td>Coop Fee:</td>
              <td className="text-dark font-weight-bold ms-sm-2">${selectedRoom?.coop_fee}</td>
            </tr>
            <tr className="text-xxs">
              <td>Supplies:</td>
              <td className="text-dark font-weight-bold ms-sm-2">${selectedRoom?.supplies?.anchored_total}</td>
            </tr>
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setOpenModal(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={() => handleVacantRoomClick()}>
          I understand
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const AddingModal = ({ openModal, setOpenModal, setNewRoomieOpenModal, setAgreementOpenModal }) => {
  // const [roomie, setRoomie] = useState({
  //   first_name: "",
  //   last_name: "",
  //   email: "",
  //   phone: "",
  //   room_name: "",
  //   base_rent: "",
  //   deposit: "",
  //   coop_fee: "",
  //   supplies: {
  //     anchored_total: "",
  //   },
  // });

  // return a modal that asks if the roomies information is to be added from a signed agreement or from scratch

  return (
    <Modal show={openModal} onHide={() => setOpenModal(false)} backdrop="static" keyboard={false} className="fade">
      <Modal.Header closeButton>
        <Modal.Title>Add Roomie</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Do you want to add a roomie from a signed agreement or from scratch?
        <div className="row">
          <div className="col-6">
            <Button variant="primary" onClick={() => setAgreementOpenModal(true)}>
              From Agreement
            </Button>
          </div>
          <div className="col-6">
            <Button variant="primary" onClick={() => setNewRoomieOpenModal(true)}>
              From Scratch
            </Button>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => setOpenModal(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// adding a tenant from scratch modal

const AddingRoomieModal = ({ openModal, setOpenModal, selectedRoom, setAddingOpenModal }) => {
  const schema = Joi.object({
    first_name: Joi.string().min(1).max(50).required(),
    last_name: Joi.string().min(1).max(50).required(),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required(),
    password: Joi.string().min(5).max(50).required(),
    phone: Joi.string()
      .min(10)
      .max(20)
      .regex(/^\+(?:[0-9]\x20?){6,14}[0-9]$/)
      .required(),
    base_rent: Joi.number().required(),
    coop: Joi.number().required(),
    deposit: Joi.number().required(),
    discounts: Joi.number().required(),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm({
    resolver: joiResolver(schema),
  });

  function onSubmit(data) {
    data = {
      ...data,
      house: selectedRoom.house,
    };
    auth
      .editRoomNew(data, selectedRoom._id)
      .then(() => {
        toast.success('Roomie added');
        setOpenModal(false);
        setAddingOpenModal(false);
      })
      .catch((err) => {
        // Provide feedback to the user
        // console.log(err.response);
        toast.error(err.response.data.message);
        setOpenModal(false);
        setAddingOpenModal(false);
      });
  }

  return (
    <Modal
      show={openModal}
      onHide={() => setOpenModal(false)}
      backdrop="static"
      keyboard={false}
      className="fade"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Roomie</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="m-4" onSubmit={handleSubmit(onSubmit)} onReset={reset} id="form_adding_roomie">
          <Row className="mb-3">
            <Form.Group className="col-lg-4 mb-3" controlId="formGridFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                {...register('first_name')}
                name="first_name"
                placeholder="First Name"
                isValid={!errors.first_name}
                isInvalid={!!errors.first_name}
              />
              <Form.Control.Feedback type="invalid">{errors.first_name?.message}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="col-lg-4 mb-3" controlId="formGridLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                {...register('last_name')}
                name="last_name"
                placeholder="Last Name"
                isValid={!errors.last_name}
                isInvalid={!!errors.last_name}
              />
              <Form.Control.Feedback type="invalid">{errors.last_name?.message}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="col-lg-4 mb-3" controlId="formGridEmail">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                {...register('email')}
                name="email"
                type="email"
                placeholder="Email Address"
                isValid={!errors.email}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="col-lg-4 mb-3" controlId="formGridPass">
              <Form.Label>Password</Form.Label>
              <Form.Control
                {...register('password')}
                name="password"
                type="password"
                placeholder="Password"
                isValid={!errors.password}
                isInvalid={!!errors.password}
              />
              <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col-lg-4 mb-3" controlId="formGridPhone">
              <Form.Label>Phone Number</Form.Label>
              <Controller
                control={control}
                name="phone"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <PhoneInput placeholder="Enter phone number" value={value} onChange={onChange} />
                )}
              />
              <Form.Control.Feedback type="invalid">{errors.phone?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col-lg-4 mb-3" controlId="formGridBaseRent">
              <Form.Label>Base Rent</Form.Label>
              <Form.Control
                {...register('base_rent')}
                name="base_rent"
                type="number"
                step="any"
                placeholder="Base Rent"
                isValid={!errors.base_rent}
                isInvalid={!!errors.base_rent}
              />
              <Form.Control.Feedback type="invalid">{errors.base_rent?.message}</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group className="col-lg-4 mb-3" controlId="formGridCoopFees">
              <Form.Label>Coop Fees</Form.Label>
              <Form.Control
                {...register('coop')}
                name="coop"
                type="number"
                step="any"
                placeholder="Coop Fees"
                isValid={!errors.coop}
                isInvalid={!!errors.coop}
              />
              <Form.Control.Feedback type="invalid">{errors.coop?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col-lg-4 mb-3" controlId="formGridDeposit">
              <Form.Label>Deposit</Form.Label>
              <Form.Control
                {...register('deposit')}
                name="deposit"
                type="number"
                step="any"
                placeholder="Deposit"
                isValid={!errors.deposit}
                isInvalid={!!errors.deposit}
              />
              <Form.Control.Feedback type="invalid">{errors.deposit?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col-lg-4 mb-3" controlId="formGridDiscounts">
              <Form.Label>Discounts</Form.Label>
              <Form.Control
                {...register('discounts')}
                name="discounts"
                type="number"
                step="any"
                placeholder="Discounts"
                isValid={!errors.discounts}
                isInvalid={!!errors.discounts}
              />
              <Form.Control.Feedback type="invalid">{errors.discounts?.message}</Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="info" type="submit" form="form_adding_roomie">
          Add
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            setOpenModal(false);
            setAddingOpenModal(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const AgreementModal = ({ openModal, setOpenModal, selectedRoom, setAddingOpenModal }) => {
  return (
    <Modal
      show={openModal}
      onHide={() => setOpenModal(false)}
      backdrop="static"
      keyboard={false}
      className="fade"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Roomie</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <NewGuysToAdd setAddingOpenModal={setAddingOpenModal} setOpenModal={setOpenModal} selectedRoom={selectedRoom} />
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setOpenModal(false);
            setAddingOpenModal(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

function RoomTable({ rooms }) {
  const [open, setOpen] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [vacantOpenModal, setVacantOpenModal] = useState(false);
  const [addingOpenModal, setAddingOpenModal] = useState(false);
  const [newRoomieOpenModal, setNewRoomieOpenModal] = useState(false);
  const [roomsState, setRoomsState] = useState(rooms);
  const [agreementOpenModal, setAgreementOpenModal] = useState(false);

  const schema = Joi.object({
    payment_amount: Joi.number(),
    payment_type: Joi.string().valid('cash', 'e-transfer'),
    payment_message: Joi.string().min(0).max(50),
  });

  // change a field in a room in roomies array to reflect on the page:
  const handleRoomCurrentChanges = (change) => {
    const newRooms = [...rooms];
    const index = newRooms.findIndex((room) => room._id === selectedRoom._id);
    newRooms[index] = { ...newRooms[index], ...change };
    setRoomsState(newRooms);
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(schema),
  });

  const handleClickOpen = (room) => {
    setSelectedRoom(room);
    setOpen(true);
  };

  const handleVacantOpenModal = (room) => {
    setSelectedRoom(room);
    setVacantOpenModal(true);
  };

  const handleClickOpenModal = (room) => {
    setSelectedRoom(room);
    setOpenModal(true);
  };

  const handleAddingOpenModal = (room) => {
    setSelectedRoom(room);
    setAddingOpenModal(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleResetPassword = (room_id) => {
    auth
      .resetPassword(room_id)
      .then(() => {
        toast.success('Password reset');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function onSubmit(data) {
    data = {
      ...data,
      house: typeof selectedRoom.house === 'string' ? selectedRoom.house : selectedRoom.house._id,
    };
    auth
      .addPayment(selectedRoom._id, data)
      .then(() => {
        toast.success('Payment added');
        setOpen(false);
      })
      .catch((err) => console.log(err.message));
  }

  return (
    <div className="table-responsive p-0">
      <table className="table align-items-center justify-content-center mb-0">
        <thead>
          <tr>
            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Room</th>
            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">Mark as vacant</th>
            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">Roomie</th>
            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">Status</th>
            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">Chore</th>
            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Base Rent</th>
            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Due Now</th>

            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Last Lease</th>

            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3 ">Payments</th>
            <th className=""></th>
            <th className=""></th>
          </tr>
        </thead>

        <tbody>
          {roomsState?.map((room, i) =>
            !room.is_empty ? (
              <tr key={i}>
                <td>
                  <div className="d-flex px-2">
                    <div>
                      <img src={room.url} className="avatar avatar-sm me-3 border-radius-lg" alt="" />
                    </div>
                    <div className="my-auto">
                      <h6 className="mb-0 text-sm">{room.room_name}</h6>
                    </div>
                  </div>
                </td>
                <td className="align-middle">
                  <span
                    style={{ cursor: 'pointer' }}
                    className="badge badge-sm bg-danger"
                    onClick={() => handleVacantOpenModal(room)}
                  >
                    MARK AS VACANT
                  </span>
                </td>
                <td>
                  <div className="d-flex px-2 py-1">
                    <div>
                      <img src={room.url} className="avatar avatar-sm me-3 border-radius-lg" alt="" />
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                      <h6 className="mb-0 text-sm">{room.first_name + ' ' + room.last_name}</h6>
                      <p className="text-xs text-secondary mb-0">{room.email}</p>
                    </div>
                  </div>
                </td>
                <td className="align-middle row m-2">
                  <span
                    className={` badge badge-sm ${
                      room.status === 'green' ? 'bg-success' : room.status === 'yellow' ? 'bg-warning' : 'bg-danger'
                    }`}
                  >
                    {room.status}
                  </span>
                </td>
                <td>
                  {room.chore_weekly
                    ?.map((chore) => addBathroomDescriptionToBathroomChore(chore.name, room.bathroom_title))
                    .join(', ')}
                </td>
                <td className="align-middle">
                  <p className="text-sm font-weight-bold mb-0">{'$' + room.base_rent}</p>
                </td>

                <td className="align-middle">
                  <p className="text-sm font-weight-bold mb-0">
                    {'$' + (room.past_due - room.payments.payments_total + room.rents.rent_list_total).toFixed(2)}
                  </p>
                </td>

                <td>
                  <div className="d-flex px-2 py-1">
                    {room.rents.last_rent ? (
                      <div className="d-flex flex-column justify-content-center">
                        <h6 className="mb-0 text-sm">{'$' + room.rents.last_rent?.last_rent_amount}</h6>
                        <p className="text-xs text-secondary mb-0">
                          Lease Signed:{' '}
                          <span className="text-bold">
                            {new Date(room.rents.last_rent?.last_rent_date).toLocaleString('default', {
                              month: 'short',
                            })}
                          </span>
                        </p>
                      </div>
                    ) : (
                      <div className="mb-0 text-sm text-bold">No Lease Yet</div>
                    )}
                  </div>
                </td>

                <td className="align-middle">
                  <span
                    style={{ cursor: 'pointer' }}
                    className="badge badge-sm bg-danger"
                    onClick={() => handleClickOpen(room)}
                  >
                    add payment
                  </span>
                </td>

                <td className="align-middle">
                  <span
                    style={{ cursor: 'pointer' }}
                    className="badge badge-sm bg-danger"
                    onClick={() => handleClickOpenModal(room)}
                  >
                    Advertise
                  </span>
                </td>

                <td className="align-middle">
                  <Link to={'/view'} state={room}>
                    <SearchIcon className="text-secondary font-weight-bold" />
                  </Link>
                </td>
                <td className="align-middle">
                  <span
                    style={{ cursor: 'pointer' }}
                    className="badge badge-sm bg-danger"
                    onClick={() => handleResetPassword(room._id)}
                  >
                    Reset Password
                  </span>
                </td>
              </tr>
            ) : (
              <tr key={i}>
                <td>
                  <div className="d-flex px-2">
                    <div>
                      <img src={room.url} className="avatar avatar-sm me-3 border-radius-lg" alt="" />
                    </div>
                    <div className="my-auto">
                      <h6 className="mb-0 text-sm">{room.room_name}</h6>
                    </div>
                  </div>
                </td>
                <td className="align-middle">
                  <span
                    style={{ cursor: 'pointer' }}
                    className="badge badge-sm bg-success"
                    onClick={() => handleAddingOpenModal(room)}
                  >
                    ADD NEW ROOMIE
                  </span>
                </td>
                <td colSpan="8" className="  text-center ">
                  <div className="row">
                    <span className="badge badge-sm bg-danger">
                      <h6 className="mb-0 ">VACANT</h6>
                    </span>
                  </div>
                </td>
                <td className="align-middle">
                  <Link to={'/view'} state={room}>
                    <SearchIcon className="text-secondary font-weight-bold" />
                  </Link>
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>

      <Modal show={open} onHide={handleClose} backdrop="static" keyboard={false} className="fade ">
        <Modal.Header closeButton>
          <Modal.Title>Add Payments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Specify a payment that you recieved from a roomie.
          <Form className="m-4 " onSubmit={handleSubmit(onSubmit)} onReset={reset} id="myform">
            <Row className="mb-3">
              <Form.Group className="col-lg-4 mb-3" controlId="formGridAmount">
                <Form.Label className="text-bold">Payment Amount</Form.Label>
                <Form.Control
                  {...register('payment_amount')}
                  name="payment_amount"
                  type="number"
                  step="any"
                  placeholder="Amount"
                  isValid={!errors.payment_amount}
                  isInvalid={!!errors.payment_amount}
                />
                <Form.Control.Feedback type="invalid">{errors.payment_amount?.message}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="col mb-3" controlId="formGridType">
                <Form.Label className="text-bold">Payment Type</Form.Label>
                <Form.Control
                  className="bg-light"
                  // defaultValue={!isAddMode ? editRoomie.house._id : null}
                  // value={!isAddMode ? editRoomie.house._id : null}
                  {...register('payment_type')}
                  name="payment_type"
                  as="select"
                  onChange={(e) => setValue('payment_type', e.target.value)}
                >
                  <option value="">Select...</option>
                  <option key="pay-1" value="cash">
                    Cash
                  </option>
                  <option key="pat-2" value="e-transfer">
                    E-Transfer
                  </option>
                  ))
                </Form.Control>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="col mb-3" controlId="formGridMessage">
                <Form.Label className="text-bold">Message</Form.Label>
                <Form.Control
                  className="bg-light"
                  as="textarea"
                  {...register('payment_message')}
                  name="payment_message"
                  placeholder="Add a message"
                />
              </Form.Group>
            </Row>
          </Form>
          <div className="col-auto text-center m-4 ">
            <table className="card table bg-light">
              <thead>
                <tr className="mb-2 text-xs">
                  <th colSpan="2" className="align-middle">
                    Payments History
                  </th>
                </tr>
              </thead>
              <tbody>
                {selectedRoom?.payments.payments_invoices?.map((inv, i) => (
                  <tr key={'invoices-' + i} className="text-xxs">
                    <td className="text-dark font-weight-bold ms-sm-2">
                      {ObjectID(inv._id).getTimestamp().toISOString().slice(0, 10)}
                    </td>
                    <td>{'$' + inv.payment_amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="submit" form="myform">
            Add Payment
          </Button>
        </Modal.Footer>
      </Modal>
      <Advertise openModal={openModal} setOpenModal={setOpenModal} room={selectedRoom} />
      <VacantModal
        openModal={vacantOpenModal}
        setOpenModal={setVacantOpenModal}
        selectedRoom={selectedRoom}
        setRoomState={setSelectedRoom}
        handleRoomCurrentChanges={handleRoomCurrentChanges}
      />
      <AddingModal
        openModal={addingOpenModal}
        setOpenModal={setAddingOpenModal}
        selectedRoom={selectedRoom}
        setRoomState={setSelectedRoom}
        setNewRoomieOpenModal={setNewRoomieOpenModal}
        setAgreementOpenModal={setAgreementOpenModal}
      />
      <AddingRoomieModal
        openModal={newRoomieOpenModal}
        setOpenModal={setNewRoomieOpenModal}
        selectedRoom={selectedRoom}
        setAddingOpenModal={setAddingOpenModal}
      />
      <AgreementModal
        openModal={agreementOpenModal}
        setOpenModal={setAgreementOpenModal}
        selectedRoom={selectedRoom}
        setAddingOpenModal={setAddingOpenModal}
      />
    </div>
  );
}

export default RoomTable;
