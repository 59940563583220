import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import auth from '../../services/authService';
import GCard from '../utils/GCard';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { toast } from 'react-toastify';

function AddTenant() {
  const history = useNavigate();
  const [editRoomie] = useState(useLocation().state);
  const isAddMode = !editRoomie;

  const schema = Joi.object({
    first_name: Joi.string().min(1).max(50).required(),
    last_name: Joi.string().min(1).max(50).required(),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required(),
    is_lead: Joi.boolean(),
    did_move_in: Joi.boolean(),
    did_move_out: Joi.boolean(),
    phone: Joi.string()
      .min(10)
      .max(20)
      .regex(/^\+(?:[0-9]\x20?){6,14}[0-9]$/)
      .required(),
    // room_name: Joi.string().min(1).max(50).required(),
    // room_key: Joi.string().min(0).max(20),
    strikes: Joi.number(),
    locker_rent: Joi.number(),
    // chore_permanent: Joi.string().min(1).max(50),
    points: Joi.number(),
    base_rent: Joi.number(),
    discounts: Joi.number(),
    coop_fee: Joi.number(),
    deposit: Joi.number(),
    past_due: Joi.number(),
    status: Joi.string(),
    // house: Joi.string().required(),
    // password: Joi.string(),
    admin_com: Joi.string(),
  }).options({ allowUnknown: true });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    control,
  } = useForm({
    resolver: joiResolver(schema),
    //defaultValues: { house: editRoomie.house._id },
  });

  useEffect(() => {
    reset(editRoomie);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  function onSubmit(data) {
    data = {
      ...data,
      did_sign_lease: data.did_sign_lease ? 1 : 0,
      notify_on: data.notify_on ? 1 : 0,
      did_move_out: false,
      did_move_in: true,
      house: typeof editRoomie.house === 'string' ? editRoomie.house : editRoomie.house._id,
    };
    // isAddMode
    //   ? auth.addRoomie(data).then(history(-1)):
    auth
      .editRoomie(data, editRoomie._id)
      .then(() => {
        toast.success('Tenant Updated Successfully');
        history(-1);
      })
      .catch((err) => {
        toast.error('Error Updating Tenant, please contact support');
        console.log(err.message);
      });
  }
  console.log({ errors });

  return (
    <GCard header={isAddMode ? 'Add New Tenant' : 'Edit Tenant'}>
      <Form className="m-4" onSubmit={handleSubmit(onSubmit)} onReset={reset}>
        <Row className="mb-3">
          <Form.Group className="col-md-4 mb-3" controlId="formGridFirstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              {...register('first_name')}
              name="first_name"
              placeholder="First Name"
              isValid={!errors.first_name}
              isInvalid={!!errors.first_name}
            />
            <Form.Control.Feedback type="invalid">{errors.first_name?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="col-md-4 mb-3" controlId="formGridLastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              {...register('last_name')}
              name="last_name"
              placeholder="Last Name"
              isValid={!errors.last_name}
              isInvalid={!!errors.last_name}
            />
            <Form.Control.Feedback type="invalid">{errors.last_name?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="col-md-4 mb-3" controlId="formGridEmail">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              {...register('email')}
              name="email"
              type="email"
              placeholder="Email Address"
              isValid={!errors.email}
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
          </Form.Group>

          {isAddMode ? (
            <Form.Group className="col-md-4 mb-3" controlId="formGridPass">
              <Form.Label>Password</Form.Label>
              <Form.Control
                {...register('password')}
                name="password"
                type="password"
                placeholder="Password"
                isValid={!errors.password}
                isInvalid={!!errors.password}
              />
              <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
            </Form.Group>
          ) : (
            <></>
          )}
        </Row>
        <Row className="mb-3">
          <Form.Group className="col-md-4 mb-3" controlId="formGridPhone">
            <Form.Label>Phone Number</Form.Label>
            <Controller
              control={control}
              name="phone"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <PhoneInput placeholder="Enter phone number" value={value} onChange={onChange} />
              )}
            />{' '}
          </Form.Group>

          <Form.Group className="col-md-4 mb-3" controlId="formGridBaseRent">
            <Form.Label>Base Rent</Form.Label>
            <Form.Control
              {...register('base_rent')}
              name="base_rent"
              type="number"
              step="any"
              placeholder="Base Rent"
              isValid={!errors.base_rent}
              isInvalid={!!errors.base_rent}
            />
            <Form.Control.Feedback type="invalid">{errors.base_rent?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="col-md-4 mb-3" controlId="formGridCoopFees">
            <Form.Label>Coop Fees</Form.Label>
            <Form.Control
              {...register('coop_fee')}
              name="coop_fee"
              type="number"
              step="any"
              placeholder="Coop Fees"
              isValid={!errors.coop_fee}
              isInvalid={!!errors.coop_fee}
            />
            <Form.Control.Feedback type="invalid">{errors.coop_fee?.message}</Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group className="col-md-4 mb-3" controlId="formGridDeposit">
            <Form.Label>Deposit</Form.Label>
            <Form.Control
              {...register('deposit')}
              name="deposit"
              type="number"
              step="any"
              placeholder="Deposit"
              isValid={!errors.deposit}
              isInvalid={!!errors.deposit}
            />
            <Form.Control.Feedback type="invalid">{errors.deposit?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="col-md-4 mb-3" controlId="formGridPastDue">
            <Form.Label>Past Due</Form.Label>
            <Form.Control
              {...register('past_due')}
              name="past_due"
              type="number"
              step="any"
              placeholder="Past Due"
              isValid={!errors.past_due}
              isInvalid={!!errors.past_due}
            />
            <Form.Control.Feedback type="invalid">{errors.past_due?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="col-md-4 mb-3" controlId="formGridDiscounts">
            <Form.Label>Discounts</Form.Label>
            <Form.Control
              {...register('discounts')}
              name="discounts"
              type="number"
              step="any"
              placeholder="Discounts"
              isValid={!errors.discounts}
              isInvalid={!!errors.discounts}
            />
            <Form.Control.Feedback type="invalid">{errors.discounts?.message}</Form.Control.Feedback>
          </Form.Group>
        </Row>

        {/* <Row className="mb-3 mt-2">
          <Form.Group className="col-md-4 mb-3" controlId="formGridDidMoveIn">
            <Form.Check
              {...register('did_move_in')}
              name="did_move_in"
              type="switch"
              id="didMoveIn-switch"
              label="Moved In?"
            />
          </Form.Group>

          <Form.Group className="col-md-4 mb-3" controlId="formGridDidMoveOut">
            <Form.Check
              {...register('did_move_out')}
              name="did_move_out"
              type="switch"
              id="DidMoveOut-switch"
              label="Moved Out?"
            />
          </Form.Group>
          <Form.Group
            className="col-md-4 mb-3"
            controlId="formGridDidSignLease"
          >
            <Form.Check
              {...register('did_sign_lease')}
              name="did_sign_lease"
              type="switch"
              id="didSignLease-switch"
              label="Signed the Lease?"
            />
          </Form.Group>
        </Row> */}

        <Row className="mb-3 mt-2">
          <Form.Group className="col-md-4 mb-3" controlId="formGridNotifyOn">
            <Form.Check
              {...register('notify_on')}
              name="notify_on"
              type="switch"
              id="NotifyOn-switch"
              label="Should be notified?"
            />
          </Form.Group>

          <Form.Group className="col-md-4 mb-3" controlId="formGridIsLead">
            <Form.Check {...register('is_lead')} name="is_lead" type="switch" id="isLead-switch" label="Is Lead?" />
          </Form.Group>

          <Form.Group className="col-md-4 mb-3" controlId="formGridIsAdmin">
            <Form.Check type="switch" id="isAdmin-switch" label="Is Admin?" />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group className="col-md-4 mb-3" controlId="formGridPoints">
            <Form.Label>Points</Form.Label>
            <Form.Control
              {...register('points')}
              name="points"
              type="number"
              placeholder="1, 2, 3, ..."
              isValid={!errors.points}
              isInvalid={!!errors.points}
            />
            <Form.Control.Feedback type="invalid">{errors.points?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="col-md-4 mb-3" controlId="formGridStrikes">
            <Form.Label>Strikes</Form.Label>
            <Form.Control
              {...register('strikes')}
              name="strikes"
              type="number"
              placeholder="1, 2, 3, ..."
              isValid={!errors.strikes}
              isInvalid={!!errors.strikes}
            />
            <Form.Control.Feedback type="invalid">{errors.strikes?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="col-md-4 mb-3" controlId="formGridLockerRent">
            <Form.Label>Locker Rent</Form.Label>
            <Form.Control
              {...register('locker_rent')}
              name="locker_rent"
              type="number"
              step="any"
              placeholder="1, 2, 3, ..."
              isValid={!errors.locker_rent}
              isInvalid={!!errors.locker_rent}
            />
            <Form.Control.Feedback type="invalid">{errors.locker_rent?.message}</Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group className="col mb-3" controlId="formGridStatus">
            <Form.Label>Status </Form.Label>
            <Form.Control
              className="bg-light"
              {...register('status')}
              name="status"
              as="select"
              onChange={(e) => setValue('status', e.target.value)}
            >
              <option value="">Select...</option>
              <option key="op-1" value="green">
                Green
              </option>
              <option key="op-2" value="yellow">
                Yellow
              </option>
              <option key="op-3" value="red">
                Red
              </option>
              ))
            </Form.Control>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group className="col mb-3" controlId="formGridComments">
            <Form.Label>Admin Comments: (Add a ** between every comment)</Form.Label>
            <Form.Control
              className="bg-light"
              as="textarea"
              {...register('admin_com')}
              name="admin_com"
              placeholder="Add a ** between every comment"
            />
          </Form.Group>
        </Row>
        <div className="text-center">
          <Button variant="info" type="submit" className="">
            {isAddMode ? 'Add' : 'Save'}
          </Button>

          <Button variant="secondary" className="ms-3 pe-3 ps-3" onClick={() => history(-1)}>
            Cancel
          </Button>
        </div>
      </Form>
    </GCard>
  );
}

export default AddTenant;
