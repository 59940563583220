import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { React, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import { useStateValue } from '../../context/StateProvider';
import auth from '../../services/authService';
import ObjectID from 'bson-objectid';
import GCard from '../utils/GCard';
import usePagination from '../../hooks/usePagination';

function FeedbackPage() {
  const [{ user }] = useStateValue();
  const [feedsList, setFeedsList] = useState();
  const [loadingState, setLoadingState] = useState('loading');
  const [open, setOpen] = useState(false);
  const [feed, setFeed] = useState();
  let counter = 0;

  const { Pagination, displayData } = usePagination({
    data: feedsList,
    amountPerPage: 5,
  });

  const displayFeeds = displayData?.length ? (
    displayData?.map((feed, i) => (
      <tr key={i}>
        <td>{feed.date}</td>
        <td>{feed.chore}</td>
        <td className="text-wrap">
          {'% ' +
            parseFloat(
              ((feed.feedback.match(/\b(yes)(?=,)/g) || [])?.length /
                (feed.feedback.match(/\b(?:yes|no)(?=,)/g) || [])?.length) *
                100
            ).toFixed(0)}
        </td>

        <td className="text-wrap">{feed.feedback}</td>
        <td>
          <Button className="mr-2" onClick={() => appeal(feed.feedback)} variant="danger">
            Appeal
          </Button>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="4" className="text-center">
        <h3>No feedbacks</h3>
      </td>
    </tr>
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    async function getAllFeeds() {
      await auth.getAllRoomieFeeds(user._id).then((res) => {
        const feedbacks = res.data[0]?.feedbacks.sort(
          (a, b) => ObjectID(b._id).getTimestamp() - ObjectID(a._id).getTimestamp()
        );
        setFeedsList(feedbacks);
        setLoadingState('housesReady');
      });
    }
    getAllFeeds();
  }, [user._id]);

  const appeal = (feed) => {
    setFeed(feed);
    handleClickOpen();
  };

  function AppealDialog() {
    const [appeal, setAppeal] = useState(null);

    async function handleAppeal(event) {
      event.preventDefault();
      auth
        .feedbackAppeal({
          rId: user._id,
          first_name: user.first_name,
          last_name: user.last_name,
          chore_name: feed.chore,
          feedback: feed.feedback,
          chore_date: feed.date,
          email: user.email,
          appeal: appeal,
        })
        .then(() => {
          toast.success('Your appeal was submitted');
        })
        .catch((err) => console.log(err.message));
      handleClose();
    }

    return (
      <div>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle>Feedback Appeal</DialogTitle>
          <DialogContent>
            <DialogContentText>what part of the feedback you feel is not accurate? and why?</DialogContentText>
            <Form onSubmit={handleAppeal}>
              <Form.Group controlId="appeal" size="lg">
                <Form.Label>Appeal</Form.Label>
                <Form.Control
                  autoFocus
                  type="text"
                  as="textarea"
                  // value={tenantName}
                  onChange={(e) => setAppeal(e.target.value)}
                />
              </Form.Group>
              <Button size="lg" type="submit">
                Send
              </Button>
            </Form>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </div>
    );
  }

  return (
    <div>
      <div className="">
        <div className="card table-responsive p-0">
          {Pagination}
          <table className="table align-items-center table-striped justify-content-center mb-0">
            <thead>
              <tr>
                <th>Date</th>
                <th>Chore</th>
                <th>Percentage</th>
                <th>Feedback</th>
                <th>Appeal</th>
              </tr>
            </thead>
            <tbody>
              {loadingState === 'loading' ? (
                <tr>
                  <th>loading</th>
                </tr>
              ) : (
                displayFeeds
              )}
            </tbody>
          </table>
        </div>
        <div className="mt-5">
          {user.house.weekly_chores_0.map((chores) =>
            chores.chore?.map((chore) => (
              <div className="mb-5" key={chore._id}>
                <GCard header={chore.name}>
                  {chore.Feedback.map((desc, i) => (
                    <div className="m-3 mb-0 ms-4" key={'feed-' + i}>
                      {counter++ + 1}
                      {'. ' + desc}
                    </div>
                  ))}
                  <p hidden>{(counter = 0)}</p>
                </GCard>
              </div>
            ))
          )}
        </div>
      </div>
      <AppealDialog />
    </div>
  );
}

export default FeedbackPage;
