import React from "react";
import { AppBar, Container, SvgIcon, Toolbar, Typography } from "@mui/material";
import "../../css/Header.css";
import { ReactComponent as SNSIcon } from "../../SNS.svg";

function PublicNav() {
  return (
    <AppBar
      position="static"
      sx={{ borderRadius: "16px", bgcolor: "#13524b", textAlign: "center" }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            sx={{
              mr: 2,
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              color: "inherit",
              textDecoration: "none",
            }}
          >
            SWEEP{" "}
            <SvgIcon
              sx={{
                ml: 0,
                mr: 0,
                mt: 0.1,
                width: 30,
                height: 30,
              }}
              component={SNSIcon}
              viewBox="0 0 2352 1511"
            />{" "}
            SLEEP
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default PublicNav;
