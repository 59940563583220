export const initialState = {
  user: null,
  tenants: null,
  feedbacks: null,
  sidenav: false,
};

export const actionTypes = {
  SET_USER: "SET_USER",
  SET_TENANTS: "SET_TENANTS",
  SET_FEEDBACKS: "SET_FEEDBACKS",
  SET_SIDENAV: "SET_SIDENAV",
};

const reducer = (state, action) => {
  //console.log(action);
  switch (action.type) {
    case actionTypes.SET_USER:
      return {
        ...state,
        user: action.user,
      };

    case actionTypes.SET_TENANTS:
      return {
        ...state,
        tenants: action.tenants,
      };

    case actionTypes.SET_FEEDBACKS:
      return {
        ...state,
        feedbacks: action.feedbacks,
      };

    case actionTypes.SET_SIDENAV:
      return {
        ...state,
        sidenav: action.sidenav,
      };

    default:
      return state;
  }
};

export default reducer;
