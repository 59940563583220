import React from "react";

function MiniCard({ icon, color, title, content, footer }) {
  return (
    <div className="card">
      <div className="card-header p-3 pt-2">
        <div
          className={`icon icon-lg icon-shape bg-${color} shadow-${color} text-center border-radius-xl mt-n4 position-absolute`}
        >
          <i className="material-icons opacity-10">{icon}</i>
        </div>
        <div className="text-end pt-1">
          <p className="text-sm mb-0 text-capitalize">{title}</p>
          <h4 className="mb-0">{content}</h4>
        </div>
      </div>
      <hr className="dark horizontal my-0" />
      <div className="card-footer p-3">
        <p className="mb-0">{footer}</p>
      </div>
    </div>
  );
}

export default MiniCard;
