import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import auth from '../../services/authService';
import QuizForm from './QuizForm';
import ScheduleSelector from 'react-schedule-selector';

function QuizRegister({ openModal, setOpenModal, adInfo }) {
  const [done, setDone] = useState(false);
  const [sched, setSched] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const [newRoomie, setNewRoomie] = useState({});
  const [questions, setQuestions] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm({});

  async function onSubmit(data) {
    setNewRoomie({
      ...data,
      house: adInfo.house._id,
      utilities: adInfo.house.utilities,
      room_name: adInfo.room_name,
      deposit: adInfo.deposit,
      base_rent: adInfo.base_rent,
      home_owner_id: adInfo.home_owner_id,
      coop: adInfo.coop_fee,
    });
    setDone(true);

    //     await auth
    //       .addNewRoomie(data, user._id)
    //       .then((res) => setLink(`sweepnsleep.com/newlease/${res.data._id}`));
  }

  async function onSubmitSchedule() {
    const data = {
      schedule: schedule,
      adId: adInfo._id,
      newRoomie: newRoomie,
      questions: questions,
    };
    await auth.adApplicant(data).then(() => {
      setSched(false);
      setOpenModal(false);
      setDone(false);
      reset();
    });
  }

  return (
    <>
      <Modal show={openModal} onHide={() => setOpenModal(false)} backdrop="static" keyboard={false} className="fade">
        <Modal.Header closeButton>
          <Modal.Title>Quiz Registration</Modal.Title>
        </Modal.Header>

        {!done && !sched ? (
          <>
            <Modal.Body>
              <Form className="m-4" onSubmit={handleSubmit(onSubmit)} onReset={reset} id="myform-quizz">
                <Row className="mb-3">
                  <Form.Group className="col-md-6 mb-3" controlId="formGridFirstName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      {...register('first_name')}
                      name="first_name"
                      placeholder="First Name"
                      isValid={!errors.first_name}
                      isInvalid={!!errors.first_name}
                    />
                    <Form.Control.Feedback type="invalid">{errors.first_name?.message}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="col-md-6 mb-3" controlId="formGridLastName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      {...register('last_name')}
                      name="last_name"
                      placeholder="Last Name"
                      isValid={!errors.last_name}
                      isInvalid={!!errors.last_name}
                    />
                    <Form.Control.Feedback type="invalid">{errors.last_name?.message}</Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group className="col-md-6 mb-3" controlId="formGridEmail">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      {...register('email')}
                      name="email"
                      type="email"
                      placeholder="Email Address"
                      isValid={!errors.email}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="col-md-6 mb-3" controlId="formGridPhone">
                    <Form.Label>Phone Number</Form.Label>
                    <Controller
                      control={control}
                      name="phone"
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <PhoneInput placeholder="Enter phone number" value={value} onChange={onChange} />
                      )}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group className="col mb-3" controlId="formGridStartDate">
                    <Form.Label>Expected Start Date</Form.Label>
                    <Form.Control
                      {...register('start_date')}
                      name="start_date"
                      type="date"
                      placeholder="Start Date"
                      isValid={!errors.start_date}
                      isInvalid={!!errors.start_date}
                    />
                    <Form.Control.Feedback type="invalid">{errors.start_date?.message}</Form.Control.Feedback>
                  </Form.Group>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setOpenModal(false)}>
                Cancel
              </Button>
              <Button variant="primary" type="submit" form="myform-quizz">
                Go to the Quiz
              </Button>
            </Modal.Footer>
          </>
        ) : !sched ? (
          <Modal.Body>
            <div className="w-100">
              <QuizForm new_roomie={newRoomie} setDone={setDone} setSched={setSched} setQuestions={setQuestions} />
            </div>
          </Modal.Body>
        ) : (
          <>
            <Modal.Body>
              <div>
                <ScheduleSelector
                  selection={schedule}
                  numDays={10}
                  minTime={8}
                  maxTime={22}
                  hourlyChunks={1}
                  dateFormat="ddd M/D"
                  selectionColor={'#f0f0f0'}
                  selectionTextColor={'#000'}
                  dayBackgroundColor={'#fff'}
                  dayBackgroundHoverColor={'#f0f0f0'}
                  dayTextColor={'#000'}
                  onChange={setSchedule}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setOpenModal(false)}>
                Cancel
              </Button>
              <Button variant="primary" onClick={() => onSubmitSchedule()}>
                Submit Your Application
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
}

export default QuizRegister;
