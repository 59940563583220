import React from 'react';
import auth from '../../services/authService';
import { useStateValue } from '../../context/StateProvider';
import { useState } from 'react';
import { toast } from 'react-toastify';

function SuppliesWhiteBoard() {
  const [{ user }] = useStateValue();
  const [supplies, setSupplies] = useState(user.house.supplies_whiteboard);
  const [newSupply, setNewSupply] = useState({ supply: '', who: user._id });

  const handleDelete = (index) => {
    const updatedSupplies = [...supplies];
    updatedSupplies.splice(index, 1);
    setSupplies(updatedSupplies);
    auth.updateSupplyBoard(user.house._id, updatedSupplies).then(() => {
      toast.success('The supply item was deleted!');
    });
  };

  const handleAddSupply = () => {
    const updatedSupplies = [...supplies, newSupply];
    setSupplies(updatedSupplies);
    setNewSupply({ supply: '', who: user._id });
    auth.updateSupplyBoard(user.house._id, updatedSupplies).then(() => {
      toast.success('The supply item was added!');
    });
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setNewSupply((prevState) => ({
      ...prevState,
      supply: value,
    }));
  };

  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th scope="col">What</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        {supplies.map((supply, index) => (
          <tr key={supply._id}>
            <td>{supply.supply}</td>
            <td className="text-center">
              <button className="btn btn-danger" onClick={() => handleDelete(index)}>
                Delete
              </button>
            </td>
          </tr>
        ))}
        <tr>
          <td>
            <input type="text" className="form-control" placeholder="What" onChange={handleInputChange} />
          </td>

          <td>
            <button onClick={handleAddSupply} className="btn btn-primary">
              Add
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default SuppliesWhiteBoard;
