import React, { useEffect, useRef } from 'react';
import { ConversationalForm } from 'conversational-form';
import { toast } from 'react-toastify';
import auth from '../../services/authService';

export default function MyForm({ new_roomie, user }) {
  let cf;
  const elem = useRef(null);
  const formFields = [
    {
      tag: 'input',
      type: 'text',
      name: 'marijuana',
      'cf-questions': 'are you allowed to use marijauna and drink in the house?',
    },

    {
      tag: 'input',
      type: 'text',
      name: 'dishes',
      'cf-questions': 'when must you do your dishes after cooking and eating?',
    },

    {
      tag: 'input',
      type: 'text',
      name: 'belongings',
      'cf-questions': 'keeping your belongings in areas outside of your room are:',
      'cf-label': 'Permitted',
    },

    {
      tag: 'input',
      type: 'text',
      name: 'chores_time',
      'cf-questions': 'when must you do your house chore?',
    },

    // {
    //   tag: "input",
    //   type: "text",
    //   name: "lead",
    //   "cf-questions": "what is the role of apartment lead?",
    // },

    {
      tag: 'input',
      type: 'text',
      name: 'quiet_time',
      'cf-questions': 'when is quiet time?',
    },

    {
      tag: 'input',
      type: 'text',
      name: 'friends',
      'cf-questions': 'what are the limitations of having friends over?',
    },

    {
      tag: 'input',
      type: 'radio',
      name: 'lead_ok',
      'cf-questions':
        'Is it okay with you that the neatkeeper/homeowner give you advices if he/she sees that you are not following the rules correctly? You can request a rule change if you would like to change the rule and admin will review it',
      'cf-label': 'Yes',
      value: 'Yes',
    },

    {
      tag: 'input',
      type: 'radio',
      name: 'lead_ok',
      'cf-label': "No, I don't like advices",
      value: "No, I don't like advices",
    },

    {
      tag: 'input',
      type: 'radio',
      name: 'lead_ok',
      'cf-label': 'Not sure',
      value: 'Not sure',
    },

    {
      tag: 'input',
      type: 'radio',
      name: 'chore_participation',
      'cf-questions': 'Are you willing to partcipate in weekly cleaning chores?',
      'cf-label': 'Yes',
      value: 'Yes',
    },

    {
      tag: 'input',
      type: 'radio',
      name: 'chore_participation',
      'cf-label': 'No',
      value: 'No',
    },
    {
      tag: 'input',
      type: 'radio',
      name: 'chore_participation',
      'cf-label': 'Not Sure',
      value: 'maybe',
    },

    {
      tag: 'input',
      type: 'radio',
      name: 'showings',
      'cf-questions': 'Do you realize you are required to help show the house to potential new roomies via whatsapp?',
      'cf-label': 'Yes',
      value: 'Yes',
    },

    {
      tag: 'input',
      type: 'radio',
      name: 'showings',
      'cf-label': "no I wasn't, but that is okay, I can do that",
      value: "no I wasn't, but that is okay, I can do that",
    },
    {
      tag: 'input',
      type: 'radio',
      name: 'showings',
      'cf-label': "No I wasn't and I will not be able to do any room showings",
      value: "No I wasn't and I will not be able to do any room showings",
    },

    {
      tag: 'input',
      type: 'radio',
      name: 'showings',
      'cf-label': "No I wasn't and I will not be able to do any room showings",
      value: "No I wasn't and I will not be able to do any room showings",
    },

    {
      tag: 'input',
      type: 'text',
      name: 'questions',
      'cf-questions': 'Questions or comments to us?',
    },
  ];

  const submitCallback = async () => {
    var formDataSerialized = cf.getFormData(true);
    // setQuiz(formDataSerialized);
    const roomie_w_questions = {
      ...new_roomie,
      questions: formDataSerialized,
    };

    await auth.editNewRoomie(roomie_w_questions, user._id).then(() => {
      toast.success('Lease Signed/A copy will be sent to you via email...!');

      cf.addRobotChatResponse(
        `Thank you ${user?.first_name} ${user?.last_name}, You have successfully signed the rental aggreament, you will send a copy of this aggreament to your email address: ${user?.email}, please reply to the email you get with a copy of your ID.
        Enjoy your day!!`
      );
    });
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    cf = ConversationalForm.startTheConversation({
      options: {
        submitCallback: submitCallback,
        preventAutoFocus: false,
        context: document.getElementById('cf-context'),
        // loadExternalStyleSheet: false
      },
      tags: formFields,
    });

    elem.current.appendChild(cf.el);
  }, []);

  return (
    <div id="cf-context" style={{ width: '100vw', height: '100vh' }}>
      <div ref={elem} />
    </div>
  );
}
