import { useState } from 'react';
import auth from '../../services/authService';

function ImageUpload() {
  const [imagePreview, setImagePreview] = useState([]);
  const imageURL = null; // #ToDo This need to be checked and removed

  const uploadHouseImage = () =>
    new Promise((resolve, reject) => (imagePreview != null ? resolve(uploadImage()) : reject('no image selected')));

  const uploadRoomImages = (roomId) =>
    new Promise((resolve, reject) =>
      imagePreview != null ? resolve(uploadMultiImage(roomId)) : reject('no image selected')
    );

  const handleChangeImage = (e) => {
    const imageFiles = Array.from(e.target.files);

    imageFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImagePreview((prevImages) => [...prevImages, reader.result]);
      };
    });
  };
  const uploadImage = async () => {
    try {
      const image = await auth.uploadImage(imagePreview);
      return image;
    } catch (err) {
      console.error(err);
    }
  };

  const uploadMultiImage = async (roomId) => {
    try {
      const image = await auth.uploadMultiImage(imagePreview, roomId);
      return image;
    } catch (err) {
      console.error(err);
    }
  };

  return [imagePreview, setImagePreview, handleChangeImage, uploadHouseImage, imageURL, uploadRoomImages];
}
export default ImageUpload;
