import { useEffect, useState } from 'react';
import auth from '../services/authService';
import { useStateValue } from '../context/StateProvider';

export default function useFetchOwnersData(dataType) {
  const [{ user }] = useStateValue();
  const [houses, setHouses] = useState(null);
  const [loadingHouseState, setLoadingHouseState] = useState(false);

  useEffect(() => {
    async function fetchHouses() {
      if (dataType === 'owner_houses') {
        await auth.getOwnerHouses(user?.houses).then((res) => {
          setHouses(res.data);
          setLoadingHouseState(true);
        });
      } else if (dataType === 'owner_rooms') {
        await auth.getOwnerRoomies(user.houses).then((res) => {
          setHouses(res.data);
          setLoadingHouseState(true);
        });
      } else if (dataType === 'neatkeepers') {
        await auth.getAllHouses().then((res) => {
          setHouses(res.data);
          setLoadingHouseState(true);
        });
      } else if (dataType === 'empty_rooms') {
        await auth.getAllAds().then((res) => {
          setHouses(res.data);
          setLoadingHouseState(true);
        });
      }
    }
    fetchHouses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [houses, loadingHouseState];
}
