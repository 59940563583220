import React, { useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import auth from '../../services/authService';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useStateValue } from '../../context/StateProvider';
import { actionTypes } from '../../context/reducer';
import { toast } from 'react-toastify';
import GCard from '../utils/GCard';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

function Profile() {
  const history = useNavigate();
  const [{ user }, dispatch] = useStateValue();

  const schema = Yup.object().shape({
    first_name: Yup.string().min(1).max(50).required(),
    last_name: Yup.string().min(1).max(50).required(),
    email: Yup.string()
      .email({ tlds: { allow: false } })
      .required(),
    phone: Yup.string()
      .min(10)
      .max(20)
      .matches(/^\+(?:[0-9]\x20?){6,14}[0-9]$/)
      .required(),
    password: Yup.string(),
    password_confirm: Yup.string()
      .when('password', (password, schema) => {
        if (password) return schema.required('Confirm Password is required');
      })
      .oneOf([Yup.ref('password')], 'Password must match'),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    Object.keys(user).forEach((field) => {
      if (field !== 'password') setValue(field, user[field]);
    });
  }, [user, setValue]);

  function onSubmit(data) {
    if (data.password === '') {
      delete data.password;
    }
    delete data.password_confirm;

    const user_role = auth.getUserRole();

    if (user_role === 'homeowner') {
      auth
        .editHomeOwnerProfile(data, user._id)
        .then(() => {
          dispatch({
            type: actionTypes.SET_USER,
            user: {
              ...user,
              first_name: data.first_name,
              last_name: data.last_name,
              email: data.email,
              phone: data.phone,
            },
          });
          toast.success('Profile updated successfully');
          window.location = '/admin';
        })
        .catch((err) => console.log(err.message));
    } else if (user_role === 'admin') {
      auth
        .editAdminProfile(data, user._id)
        .then(() => {
          dispatch({
            type: actionTypes.SET_USER,
            user: {
              ...user,
              first_name: data.first_name,
              last_name: data.last_name,
              email: data.email,
              phone: data.phone,
            },
          });
          toast.success('Profile updated successfully');
          window.location = '/admino';
        })
        .catch((err) => console.log(err.message));
    } else
      auth
        .editRoomieProfile(data, user._id)
        .then(() => {
          dispatch({
            type: actionTypes.SET_USER,
            user: {
              ...user,
              first_name: data.first_name,
              last_name: data.last_name,
              email: data.email,
              phone: data.phone,
            },
          });
          toast.success('Profile updated successfully');
          window.location = '/';
        })
        .catch((err) => console.log(err.message));
  }

  return (
    <div>
      <Form className="m-1 pt-4" onSubmit={handleSubmit(onSubmit)} onReset={reset}>
        <GCard header="Edit Profile">
          <Col className="m-3">
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  {...register('first_name')}
                  name="first_name"
                  placeholder="First Name"
                  isValid={!errors.first_name}
                  isInvalid={!!errors.first_name}
                />
                <Form.Control.Feedback type="invalid">{errors.first_name?.message}</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridLastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  {...register('last_name')}
                  name="last_name"
                  placeholder="Last Name"
                  isValid={!errors.last_name}
                  isInvalid={!!errors.last_name}
                />
                <Form.Control.Feedback type="invalid">{errors.last_name?.message}</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  {...register('email')}
                  name="email"
                  type="email"
                  placeholder="Email Address"
                  isValid={!errors.email}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridPhone">
                <Form.Label>Phone Number</Form.Label>
                <Controller
                  control={control}
                  name="phone"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput placeholder="Enter phone number" value={value} onChange={onChange} />
                  )}
                />
              </Form.Group>
            </Row>
          </Col>
          <br />
        </GCard>

        <GCard
          header={
            <div className="d-flex flex-column">
              Change Password
              <span className="text-xs text-light">Leave blank if you don&apos;t want to change it</span>
            </div>
          }
        >
          <Col className="m-3">
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridPass">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  {...register('password')}
                  name="password"
                  type="password"
                  placeholder="Password"
                  isValid={!errors.password}
                  isInvalid={!!errors.password}
                />
                <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} controlId="formGridPassConf">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  {...register('password_confirm')}
                  name="password_confirm"
                  type="password"
                  placeholder="Confirm Password"
                  isValid={!errors.password_confirm}
                  isInvalid={!!errors.password_confirm}
                />
                <Form.Control.Feedback type="invalid">{errors.password_confirm?.message}</Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Col>
        </GCard>

        <div className="row">
          <Button variant="info" className="col ms-2 me-2" type="submit">
            Save
          </Button>

          <Button variant="secondary" className="col ms-2 me-2" onClick={() => history(-1)}>
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default Profile;
