import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import MainRoutes from './routes/MainRoutes';

// This is the APP
function App() {
  return (
    <div className="app">
      <ToastContainer />
      <MainRoutes />
    </div>
  );
}
export default App;
