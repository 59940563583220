import React from "react";

function UserAvatar({ avatar, title, subtitle, children }) {
  return (
    <div className="row gx-4 mb-2">
      <div className="col-auto">
        <div className="avatar avatar-xl position-relative">
          <img
            src={avatar}
            alt="profile_image"
            className="w-100 border-radius-lg shadow-sm"
          />
        </div>
      </div>
      <div className="col-auto my-auto">
        <div className="h-100">
          <h5 className="mb-1">{title}</h5>
          <p className="mb-0 font-weight-normal text-sm">{subtitle}</p>
        </div>
      </div>
      {children}
    </div>
  );
}

export default UserAvatar;
