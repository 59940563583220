import React from "react";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <div className="text-center ">
        <div className="">
          <img src="/media/404.png" alt="" className="img-fluid w-25" />
        </div>
        <div className="">
          <p className="fs-3">
            <span className="text-danger">Opps!</span> Nothing Here!
          </p>
          <Link to="/" className="btn btn-secondary">
            Home
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
