import React, { useState, useEffect, useCallback } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import { useForm } from 'react-hook-form';
import GCard from '../utils/GCard';
import '../../css/releasing.css';
import auth from '../../services/authService';
import { useStateValue } from '../../context/StateProvider';
import Testing from './Testing';

function Releasing() {
  let { id: houseId, costPerPerson } = useParams();
  const [houseListFromState, tenants] = useOutletContext();
  const [housesList, setHousesList] = useState(houseListFromState);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openTestingModal, setOpenTestingModal] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [utilitiesHistory, setUtilitiesHistory] = useState(() => {
    const utilitiesLC = localStorage.getItem('utilities');
    return utilitiesLC !== 'undefined' ? JSON.parse(utilitiesLC) : [];
  });
  useEffect(() => {
    setHousesList(houseListFromState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (houseId) {
      let prevUtitlies = utilitiesHistory;
      prevUtitlies = prevUtitlies?.filter((prev) => prev.houseId !== houseId);
      if (prevUtitlies) {
        prevUtitlies.push({ houseId, costPerPerson });
        setUtilitiesHistory(prevUtitlies);
      } else {
        setUtilitiesHistory();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.setItem('utilities', JSON.stringify(utilitiesHistory));
  }, [utilitiesHistory]);

  const houseIdReceived = houseId;
  const getDefaultValue = useCallback(
    (houseId, defaultCostPerPerson) => {
      if (houseId === houseIdReceived) {
        return costPerPerson;
      } else if (localStorage.getItem('utilities') !== 'undefined') {
        const savedValue = utilitiesHistory?.find((u) => u.houseId === houseId)?.costPerPerson;
        return savedValue || defaultCostPerPerson;
      }
      return defaultCostPerPerson;
    },
    [costPerPerson, houseIdReceived, utilitiesHistory]
  );

  const cleanSavedUtilitiesValues = () => {
    localStorage.setItem('utilities', 'undefined');
    setUtilitiesHistory();
  };

  const [{ user }] = useStateValue();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  function onSubmit(data) {
    setButtonDisabled(true);
    const activeMoveOut = [];
    for (let key in data.hello) {
      if (data.hello[key] === true) {
        activeMoveOut.push(key);
      }
    }
    const data2 = {
      utils: data.utils,
      discounts: data.discount,
      moveout: activeMoveOut,
    };

    auth.prereleaseLease(data2, user._id).then(() => {
      setOpenDeleteModal(false);
      setOpenTestingModal(true);
    });
  }
  return (
    <>
      <Form className="" onSubmit={handleSubmit(onSubmit)} onReset={reset} id="form_release">
        <Row className="mb-6">
          <Col md="6">
            <GCard header="Step 1. Setup the utilities">
              {housesList.map((house, i) => (
                <Row key={'house-' + i} className="">
                  <Form.Group as={Row} className="mx-3 mb-1" controlId="formGridUtils">
                    <Form.Label column md="5">
                      <span className="text-bold">{house.house_name + ':'}</span>
                    </Form.Label>
                    <Col md="6">
                      <Form.Control
                        {...register(`utils.${house._id}`)}
                        className=""
                        name={`utils.${house._id}`}
                        type="number"
                        defaultValue={getDefaultValue(house._id, 0)}
                        placeholder="Utils"
                        isValid={!errors.utils}
                        isInvalid={!!errors.utils}
                        step="0.01"
                      />
                      <Form.Control.Feedback type="invalid">{errors.utils?.message}</Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Row>
              ))}
              {utilitiesHistory?.length > 0 && (
                <Button className="ms-4 mt-2" variant="secondary" onClick={() => cleanSavedUtilitiesValues()}>
                  Delete temporal utilities values
                </Button>
              )}
            </GCard>
          </Col>
          <Col md="6">
            <GCard header="Step 2. Assign the discounts">
              {tenants
                .filter((tenant) => tenant.is_lead)
                .map((tenant, i) => (
                  <Row key={'tenant-' + i} className="">
                    <Form.Group as={Row} className="mx-3 mb-1" controlId="formGridDiscount">
                      <Form.Label column md="5">
                        <span className="text-bold">{tenant.first_name + ': '}</span>
                      </Form.Label>
                      <Col md="6">
                        <Form.Control
                          {...register(`discount.${tenant._id}`)}
                          name={`discount.${tenant._id}`}
                          placeholder="Discount"
                          type="number"
                          defaultValue={0.0}
                          step="0.01"
                          isValid={!errors.discount}
                          isInvalid={!!errors.discount}
                        />
                        <Form.Control.Feedback type="invalid">{errors.discount?.message}</Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Row>
                ))}
            </GCard>
          </Col>
        </Row>
        <Row>
          <Col>
            <GCard header="Step 3. Check the roomies who will NOT be offered a new lease.">
              <div className="containerss">
                {tenants
                  .filter((tenant) => !tenant.is_empty && tenant.did_move_in)
                  .map((tenant, i) => (
                    <div key={`default-${i}`} className="mb-2">
                      <Form.Check
                        {...register(`hello.${tenant._id}`)}
                        type="checkbox"
                        id={`default-${i}`}
                        name={`hello.${tenant._id}`}
                        label={tenant.first_name + ' / ' + tenant.room_name}
                      />
                    </div>
                  ))}
              </div>
              <div className="mt-3 text-center">
                <span className="text-bold">
                  If the room is not present, its either empty or the roomie is marked as moving out. Please contact
                  support.
                </span>
              </div>
            </GCard>
          </Col>
        </Row>

        <div className="mt-7 m-4 row">
          <Button
            // disabled={!user.release_lease}
            className=""
            variant="primary"
            onClick={() => setOpenDeleteModal(true)}
          >
            Release the leases
          </Button>
        </div>

        <Modal
          show={openDeleteModal}
          onHide={() => setOpenDeleteModal(false)}
          backdrop="static"
          keyboard={false}
          className="fade"
        >
          <Modal.Header closeButton>
            <Modal.Title>Release the lease</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Do you understand that you are about to send an agreement to all roomies who are not marked as moving out?
            Before releasing, make sure you have: 1- emptied rooms that are empty. 2- marked roomies who are moving out
            3- added utilities 4- added discounts
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setOpenDeleteModal(false)}>
              Close
            </Button>
            <Button variant="primary" type="submit" form="form_release" disabled={buttonDisabled}>
              Step 1: Set up the information
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
      <Testing
        openTestingModal={openTestingModal}
        setOpenTestingModal={setOpenTestingModal}
        openDeleteModal={openDeleteModal}
      />
    </>
  );
}

export default Releasing;
