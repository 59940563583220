import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';

import { useNavigate, useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import auth from '../../services/authService';

function FeedbackTable() {
  let history = useNavigate();
  const [, tenants] = useOutletContext();
  const [feeds, setFeeds] = useState(tenants);

  const getFeeds = () => {
    return feeds.filter((filtered) => {
      let result = false;
      filtered.feedbacks.forEach((verified) => {
        if (verified.verified === 0) result = true;
      });
      return result;
    });
  };

  const Action = ({ rId, whoId, fId, feedbacktext }) => {
    const [point, setPoint] = useState(0);
    const [ps, setPs] = useState(0);
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };
    const onVerify = (rId, fId, point, ps, whoId) => {
      //handleClickOpen();
      auth
        .verifyFeedback(rId, fId, point, ps, whoId)
        .then(removeFeed(rId, fId))
        .then(() => {
          toast.success('The feedback was verified!');
        })
        .catch((err) => console.log(err.message));
      //console.log(point, ps);
    };

    const onDelete = (rId, fId) => {
      auth
        .deleteFeedback(rId, fId)
        .then(removeFeed(rId, fId))
        .then(() => {
          toast.success('The feedback ws sucessfully deleted!');
        })
        .catch((err) => console.log(err.message));
    };

    const removeFeed = (rId, fId) => {
      handleClose();
      setFeeds((feeds) => {
        let feedss = [...feeds];
        feedss.forEach((feed) => {
          if (feed._id === rId) {
            feed.feedbacks.forEach((element, i) => {
              if (element._id === fId) {
                delete feed.feedbacks[i];
              }
            });
          }
        });
        history({ state: feedss }, { replace: true });
        return [...feeds];
        // dispatch({
        //   type: actionTypes.SET_FEEDBACKS,
        //   feedbacks: [...feedss],
        // });
      });
    };

    return (
      <div>
        {/*  */}
        <div>
          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id={fId}>Feedback</DialogTitle>
            <DialogContent>
              <DialogContentText>
                The feedback provided is: {feedbacktext}. {'\n'} Do you want to give a point or a strike?
              </DialogContentText>
              <input
                className="mr-2"
                style={{ width: '50px' }}
                value={point}
                placeholder="points"
                onChange={(e) => setPoint(e.target.value ? e.target.value : 0)}
              />
              <select value={ps} onChange={(e) => setPs(e.target.value)} className="mr-2">
                <option value={0}>point</option>
                <option value={1}>strike</option>
              </select>
            </DialogContent>
            <DialogActions>
              <Button className="mr-2" onClick={() => onVerify(rId, fId, point, ps, whoId)} variant="primary">
                Verify
              </Button>
              <Button onClick={() => onDelete(rId, fId)} variant="danger">
                <span>Delete</span>
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <Button className="mr-2" onClick={handleClickOpen} variant="primary">
          View
        </Button>
      </div>
    );
  };

  return (
    <div className="card table-responsive p-0 ">
      <table className="table align-items-center justify-content-center mb-0">
        <thead>
          <tr>
            <th>To who?</th>
            <th>house</th>
            <th>what?</th>
          </tr>
        </thead>
        <tbody>
          {getFeeds().length ? (
            getFeeds().map((feed) => (
              <tr key={feed._id}>
                <td>
                  {feed.first_name} {feed.last_name}
                </td>
                <td>{feed.house.house_name}</td>
                <td>
                  <div className="card table-responsive p-0">
                    <table className="table align-items-center justify-content-center mb-0 bg-dark">
                      <thead>
                        <tr>
                          <th className="text-white">From who?</th>
                          <th className="text-white">Date</th>
                          <th className="text-white">Chore</th>
                          {/* <th>Points</th> */}
                          <th className="text-white">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {feed.feedbacks
                          .filter((filtered) => filtered.verified === 0)
                          .map((feedback) => (
                            <tr
                              key={feedback._id}
                              style={{
                                backgroundColor:
                                  feedback.kind === 'point' ? '#BBE381' : feedback.kind === 'strike' ? '#E68C76' : null,
                              }}
                            >
                              <td>
                                {/* {console.log(feedback)} */}
                                {feedback.who ? feedback.who.username : 'unknown'}
                              </td>

                              <td>{feedback.date}</td>
                              <td>{feedback.chore}</td>
                              <td>
                                <Action
                                  className="text-center"
                                  rId={feed._id}
                                  whoId={feedback.who._id}
                                  fId={feedback._id}
                                  feedbacktext={feedback.feedback}
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td>
                <h3>No Feedbacks to show</h3>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default FeedbackTable;
