import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { AppBar, Box, Container, IconButton, Menu, SvgIcon, Toolbar, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import auth from '../../services/authService';
import '../../css/Header.css';
import MenuIcon from '@mui/icons-material/Menu';
import { ReactComponent as SNSIcon } from '../../SNS.svg';

function UserNavBar() {
  const history = useNavigate();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <AppBar position="static" sx={{ borderRadius: '16px', bgcolor: '#13524b' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            // component="a"
            // href=""
            sx={{
              mr: 2,
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            SWEEP{' '}
            <SvgIcon
              sx={{
                ml: 0,
                mr: 0,
                mt: 0.1,
                width: 30,
                height: 30,
              }}
              component={SNSIcon}
              viewBox="0 0 2352 1511"
            />{' '}
            SLEEP
          </Typography>

          <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
            <Box sx={{ my: 1, display: 'block' }}>
              <IconButton
                title="Edit Profile"
                className="text-white"
                onClick={() => {
                  history('/Profile');
                }}
              >
                <AccountBoxOutlinedIcon />
              </IconButton>
            </Box>

            <Box sx={{ my: 1, display: 'block' }}>
              <IconButton
                title="Logout"
                className="text-white"
                onClick={() => {
                  auth.logout();
                  window.location = '/';
                }}
              >
                <ExitToAppIcon />
              </IconButton>{' '}
            </Box>
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenUserMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              sx={{ mt: '45px', display: { xs: 'block', md: 'none' } }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <Box sx={{ my: 1, display: 'block' }}>
                <IconButton
                  title="Edit Profile"
                  className="text-primary"
                  onClick={() => {
                    history('/Profile');
                    handleCloseUserMenu();
                  }}
                >
                  <AccountBoxOutlinedIcon />
                </IconButton>
              </Box>

              <Box sx={{ my: 1, display: 'block' }}>
                <IconButton
                  title="Logout"
                  className="text-primary"
                  onClick={() => {
                    auth.logout();
                    window.location = '/';
                  }}
                >
                  <ExitToAppIcon />
                </IconButton>
              </Box>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default UserNavBar;
