import React, { useState } from 'react';
import GCard from './GCard';
import { useLocation } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectCards } from 'swiper';
import 'swiper/swiper-bundle.min.css'; // core Swiper
import 'swiper/css/effect-cards'; // Navigation module
import Button from 'react-bootstrap/Button';
import QuizRegister from '../user-components/QuizRegister';

function RoomAd() {
  const location = useLocation();
  const data = location.state?.data;
  const [openModal, setOpenModal] = useState(false);
  function scale_url(url) {
    const url_split = url.split('/upload');
    return `${url_split[0]}/upload/c_scale,h_511,w_681/${url_split[1]}`;
  }

  return (
    <>
      <div className="card card-body mx-2 mx-md-2 mt-3 bg-light">
        <div className="container-fluid py-4 px-0">
          <div className="row">
            <div className="col-12 col-lg-6">
              <GCard header="Room Pictures">
                <Swiper
                  effect={'cards'}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  grabCursor={true}
                  modules={[Autoplay, EffectCards]}
                  className="test-container"
                >
                  {data.image_array.map((image, i) => (
                    <SwiperSlide key={i} className="test-slide">
                      <div className="row" key={`room-${i}`}>
                        <img src={scale_url(image.url)} alt={`room-${i}`} className="img-fluid" />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </GCard>
            </div>
            <div className="col-12 col-lg-6">
              <GCard header="Details">
                <div className="row ms-2">
                  <div className="col-6">
                    <p>
                      <span className="text-bold">Base Rent:</span> ${data.base_rent}
                    </p>
                    <p>
                      <span className="text-bold">Utilities:</span> ${data.house.utilities}
                    </p>

                    <p>
                      <span className="text-bold">Total:</span> ${data.house.utilities + data.base_rent}
                    </p>
                  </div>
                  <div className="col-6">
                    <p>
                      <span className="text-bold">Coop Deposit:</span> ${data.coop_fee}
                    </p>
                    <p>
                      <span className="text-bold">Damage Deposit:</span> ${data.deposit}
                    </p>

                    <p>
                      <span className="text-bold">Total Deposit:</span> ${data.deposit + data.coop_fee}
                    </p>
                  </div>
                  <br />
                  <br />
                  <p>
                    <span className="text-bold ">Address:</span> {data.house.address}
                  </p>
                </div>
              </GCard>
            </div>
          </div>

          <GCard header="Description">
            <div className="row ms-2">
              <p>{data.room_description}</p>
            </div>
            <div className="row">
              <div className="mt-4 text-center">
                <Button onClick={() => setOpenModal(true)}>Do the Quiz</Button>
              </div>
            </div>
          </GCard>
        </div>
      </div>
      <QuizRegister openModal={openModal} setOpenModal={setOpenModal} adInfo={data} />
    </>
  );
}

export default RoomAd;
