import React, { useState, useEffect } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import GCard from '../../utils/GCard';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Box from '@mui/material/Box';
import { experimentalStyled as styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Tooltip from '@mui/material/Tooltip';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import ButtonBootStrap from 'react-bootstrap/Button';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import auth from '../../../services/authService';
import AssessmentIcon from '@mui/icons-material/Assessment';

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

const currentYearDate = dayjs().subtract(0, 'year');
const maxYearDate = dayjs().add(10, 'year');
const minYearDate = dayjs().subtract(20, 'year');
const defaultGridData = { columns: [{ field: 'id', headerName: 'Loading' }], width: 110, rows: [] };

function VariableCostDialog(props) {
  const history = useNavigate();
  const [loading, setLoading] = useState(true);
  const [variableCostsData, setVariableCostsData] = useState([]);
  const [reportData, setReportData] = useState(defaultGridData);
  const { onClose, open, houseData, year, isFullScreen, setIsFullScreen } = props;

  const handleClose = () => {
    onClose();
  };
  const goToUtilitiesAndSetIt = (houseId, costPerPerson) => {
    history(`/releasing/${houseId}/${costPerPerson}`);
  };

  useEffect(() => {
    if (!houseData?.id) {
      return;
    }
    setLoading(true);
    setVariableCostsData([]);
    setReportData(defaultGridData);
    async function fetchVariableCostsData() {
      await auth.getVariableCosts(houseData.id, year.format('YYYY')).then((res) => {
        setVariableCostsData(res.data);
        setLoading(false);
      });
    }
    fetchVariableCostsData();
  }, [houseData, year]);

  useEffect(() => {
    if (variableCostsData.length <= 0) {
      defaultGridData.columns[0].headerName = 'Not found';
      setReportData(defaultGridData);
      return;
    }

    const { variable_costs: variableCosts } = variableCostsData[0];

    //Creating headers
    const headerMerged = variableCosts
      .map((vcd) => {
        const headers = vcd.costs.map((costs) => costs.costName.toUpperCase());
        return headers;
      })
      .flat();
    const headerNotDuplicated = Array.from(new Set(headerMerged));
    const gridHeaders = headerNotDuplicated.map((header) => ({
      field: header.toUpperCase(),
      headerName: header.toUpperCase(),
      width: 110,
      editable: false,
    }));
    const dynamicHeaders = [...gridHeaders];
    //Fixed fields
    gridHeaders.unshift({ field: 'month', headerName: 'MONTH' });
    gridHeaders.unshift({ field: 'id', headerName: 'ID', hide: true });
    gridHeaders.push({
      field: 'costPerPerson',
      headerName: 'Cost Per Person'.toUpperCase(),
      width: 150,
      editable: false,
    });
    gridHeaders.push({
      field: 'buttonSetUtility',
      headerName: 'Setup Utility'.toUpperCase(),
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <ButtonBootStrap
            className="mt-2 ms-4"
            variant="primary"
            onClick={() => goToUtilitiesAndSetIt(houseData.id, params.row.costPerPerson)}
          >
            SET
          </ButtonBootStrap>
        );
      },
    });
    //end fixed fields
    //End creating headers

    const columns = gridHeaders;
    //Creating the dynamics rows
    const rows = variableCosts.map((data, idx) => {
      const dynamicRows = dynamicHeaders.map((dh) => ({
        [dh.field]: variableCosts[idx].costs.find((c) => c.costName.toUpperCase() === dh.field.toUpperCase())
          ?.costValue,
      }));

      const result = {
        id: idx,
        month: data.month,
      };
      dynamicRows.forEach((drow) => {
        const key = Object.keys(drow)[0];
        result[key] = drow[key];
      });
      result['costPerPerson'] = data.costPerPerson;

      return result;
    });
    //End creating the dynamic rows

    const newReportData = { columns, rows };
    setReportData(newReportData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variableCostsData]);

  const formatedYear = year.format('YYYY');
  const apiRef = useGridApiRef();

  return (
    <Dialog onClose={handleClose} open={open} fullScreen={isFullScreen} maxWidth="xl">
      <DialogTitle>
        Variable costs of <strong>{houseData?.name}</strong> house, year <strong>{formatedYear}</strong>.
      </DialogTitle>
      {loading ? (
        <Box display={'flex'} justifyContent={'center'} padding={2}>
          <div className="spinner-border col-md-1" role="status" />
        </Box>
      ) : (
        <Box sx={{ height: 400, width: '100%' }}>
          <DataGrid
            apiRef={apiRef}
            columns={reportData.columns}
            rows={reportData.rows}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 12,
                },
              },
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
            }}
            pageSizeOptions={[12]}
            disableRowSelectionOnClick
          />
        </Box>
      )}
      <Button variant="outlined" onClick={handleClose}>
        Close
      </Button>
      <Button variant="outlined" onClick={() => setIsFullScreen(!isFullScreen)}>
        {isFullScreen ? 'Minimize' : 'FullScreen'}
      </Button>
    </Dialog>
  );
}

function VariableCostsAveragesDialog(props) {
  const [loading, setLoading] = useState(true);
  const [averagesData, setAveragesData] = useState([]);
  const [reportData, setReportData] = useState(defaultGridData);
  const { onClose, open, houseData, isFullScreen, setIsFullScreen } = props;

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (!houseData?.id) {
      return;
    }
    setLoading(true);
    setAveragesData([]);
    setReportData([]);
    async function fetchVariableCostsAverageData() {
      await auth.getVariableCostsAverages(houseData.id).then((res) => {
        setAveragesData(res.data);
        setLoading(false);
      });
    }
    fetchVariableCostsAverageData();
  }, [houseData]);

  useEffect(() => {
    if (averagesData.length <= 0) {
      defaultGridData.columns[0].headerName = 'Not found';
      setReportData(defaultGridData);
      return;
    }

    //Creating headers
    const columns = [
      { field: 'id', headerName: 'ID', hide: true },
      {
        field: 'year',
        headerName: 'YEAR',
        width: 110,
        editable: false,
      },
      {
        field: 'averageCostPerPerson',
        headerName: 'AVERAGE COST PER PERSON',
        width: 260,
        editable: false,
        renderCell: (params) => {
          return <>$ {Math.round(params.row.averageCostPerPerson)}</>;
        },
      },
    ];
    //end fixed fields
    //End creating headers

    //Creating the dynamics rows
    const rows = averagesData.map((data, idx) => ({
      id: idx,
      year: data._id,
      averageCostPerPerson: data.averageCostPerPerson,
    }));
    //End creating the dynamic rows

    const newReportData = { columns, rows };
    setReportData(newReportData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [averagesData]);

  const apiRef = useGridApiRef();

  return (
    <Dialog onClose={handleClose} open={open} fullScreen={isFullScreen} maxWidth="xl">
      <DialogTitle>
        Variable costs averages for <strong>{houseData?.name}</strong> house.
      </DialogTitle>
      {loading ? (
        <Box display={'flex'} justifyContent={'center'} padding={2}>
          <div className="spinner-border col-md-1" role="status" />
        </Box>
      ) : (
        <Box sx={{ height: 400, width: '100%' }}>
          <DataGrid
            apiRef={apiRef}
            columns={reportData.columns}
            rows={reportData.rows}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 12,
                },
              },
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
            }}
            pageSizeOptions={[12]}
            disableRowSelectionOnClick
          />
        </Box>
      )}
      <Button variant="outlined" onClick={handleClose}>
        Close
      </Button>
      <Button variant="outlined" onClick={() => setIsFullScreen(!isFullScreen)}>
        {isFullScreen ? 'Minimize' : 'FullScreen'}
      </Button>
    </Dialog>
  );
}

function VariableCostReports() {
  const [houseSelected, setHouseSelected] = useState(null);
  const [yearSelected, setYearSelected] = useState(currentYearDate);

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isFullScreenAveragesReport, setIsFullScreenAveragesReport] = useState(false);
  const [open, setOpen] = useState(false);
  const [openAveragesReport, setOpenAveragesReport] = useState(false);
  const [housesList] = useOutletContext();

  const handleDialogClose = () => {
    setOpen(false);
    setHouseSelected(null);
  };
  const handleVariableCostsAveragesDialogClose = () => {
    setOpenAveragesReport(false);
    setHouseSelected(null);
  };

  const handleVaribleCostsDialogOpen = (id, name) => {
    setHouseSelected({ id, name });
    setOpen(true);
  };

  const handleVaribleCostsAverageDialogOpen = (id, name) => {
    setHouseSelected({ id, name });
    setOpenAveragesReport(true);
  };

  return (
    <>
      <VariableCostDialog
        onClose={handleDialogClose}
        open={open}
        houseData={houseSelected}
        year={yearSelected}
        isFullScreen={isFullScreen}
        setIsFullScreen={setIsFullScreen}
      />
      <VariableCostsAveragesDialog
        onClose={handleVariableCostsAveragesDialogClose}
        open={openAveragesReport}
        houseData={houseSelected}
        isFullScreen={isFullScreenAveragesReport}
        setIsFullScreen={setIsFullScreenAveragesReport}
      />

      <GCard header="Variable Cost Reports">
        <Container
          sx={{
            backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#1A2027' : '#fff'),
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
          maxWidth="false"
        >
          <Box display={'flex'} justifyContent={'end'} marginBlockEnd={2} width="94%">
            <Box display={'flex'} alignItems={'center'} marginRight={4}>
              <Typography variant="h6" component="h6" marginRight={2}>
                Select the Year:
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={'"year"'}
                  views={['year']}
                  defaultValue={yearSelected}
                  maxDate={dayjs(maxYearDate)}
                  minDate={dayjs(minYearDate)}
                  onChange={(value) => {
                    setYearSelected(value);
                  }}
                  slotProps={{ textField: { size: 'small' } }}
                  sx={{ width: 200 }}
                />
              </LocalizationProvider>
            </Box>
          </Box>
          <Container sx={{ display: 'flex', flexWrap: 'wrap', width: '96%' }} maxWidth="false">
            {housesList.map((house) => (
              <Grid
                item
                display={'flex'}
                width={'390px'}
                height={'150px'}
                alignItems={'center'}
                className="bg-light border-radius-lg"
                margin={1}
                padding={2}
                key={house._id}
              >
                <ButtonBase
                  sx={{ width: 128, height: 128 }}
                  className="blur-shadow-image"
                  onClick={() => handleVaribleCostsDialogOpen(house._id)}
                >
                  <Tooltip title="View Variable Costs">
                    <Img
                      sx={{
                        transition: 'all 1s ease',
                        '&:hover': {
                          transform: 'scale(1.1)',
                        },
                      }}
                      alt="complex"
                      src={house.url}
                      className="img-fluid shadow border-radius-lg"
                    />
                  </Tooltip>
                </ButtonBase>
                <Grid padding={2}>
                  <Grid>
                    <Tooltip title={house.house_name.toUpperCase()}>
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        textOverflow={'ellipsis'}
                        whiteSpace={'nowrap'}
                        width={200}
                        noWrap
                      >
                        {house.house_name.toUpperCase()}
                      </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid sx={{ display: 'inline-block' }}>
                    <Tooltip title="View Variable Costs">
                      <Typography sx={{ cursor: 'pointer', display: 'inline', marginRight: '4px' }} variant="caption">
                        <VisibilityIcon
                          sx={{ 'color:#e28742;&:hover': { color: '#000' } }}
                          onClick={() => handleVaribleCostsDialogOpen(house._id, house.house_name)}
                        />
                      </Typography>
                    </Tooltip>
                    <Tooltip title="View Variable Costs Averages">
                      <Typography sx={{ cursor: 'pointer', display: 'inline' }} variant="caption">
                        <AssessmentIcon
                          sx={{ 'color:#e28742;&:hover': { color: '#000' } }}
                          onClick={() => handleVaribleCostsAverageDialogOpen(house._id, house.house_name)}
                        />
                      </Typography>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Container>
        </Container>
      </GCard>
    </>
  );
}

export default VariableCostReports;
