import React from "react";
import { useStateValue } from "../../context/StateProvider";
import { Outlet } from "react-router-dom";
import UserTabs from "./UserTabs";
import UserAvatar from "../utils/UserAvatar";

function UserBody() {
  const [{ user }] = useStateValue();

  return (
    <div className="card card-body mt-3 bg-gray-200">
      <UserAvatar
        avatar="/avatar.jpg"
        title={user.first_name + " " + user.last_name}
        subtitle={user.room_name + " | " + user.house.house_name}
      >
        <UserTabs />
      </UserAvatar>
      <div className="container-fluid p-0 py-4">
        <Outlet />
      </div>
    </div>
  );
}

export default UserBody;
