import React, { useEffect, useState } from 'react';
import auth from '../../services/authService';
import { useStateValue } from '../../context/StateProvider';
import UserAvatar from '../utils/UserAvatar';
import HomeOwnerTable from '../utils/HomeOwnerTable';
import Applications from './Applications';

function Homepage() {
  const [{ user }] = useStateValue();
  const [homeowners, setHomeowners] = useState(null);

  useEffect(() => {
    async function fetchHomeowners() {
      await auth.getAllHomeOwners().then((res) => {
        setHomeowners(res.data);
      });
    }
    fetchHomeowners();
  }, []);

  return (
    <div className="card card-body mx-2 mx-md-2 mt-3 bg-light">
      <UserAvatar avatar="/avatar.jpg" title={user.first_name + ' ' + user.last_name}></UserAvatar>

      <div className="container-fluid py-4 px-0">
        {/* <ImageUpload /> */}
        <HomeOwnerTable homeowners={homeowners} />
        <Applications applications={user.homeowners_applicants} />
      </div>
    </div>
  );
}

export default Homepage;
