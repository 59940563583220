import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import WaterDamageIcon from '@mui/icons-material/WaterDamage';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import ButtonBootstrap from 'react-bootstrap/Button';
import { DataGrid, useGridApiRef, GridRowEditStopReasons } from '@mui/x-data-grid';
import auth from '../../../services/authService';
import CheckIcon from '@mui/icons-material/Check';
import ToggleButton from '@mui/material/ToggleButton';
import { toast } from 'react-toastify';

const defaultGridData = { columns: [{ field: 'id', headerName: 'Loading' }], width: 110, rows: [] };

function VariableCostsAveragesDialog(props) {
  const [loading, setLoading] = useState(true);
  const [damageDepositData, setDamageDepositData] = useState([]);
  const [reportData, setReportData] = useState(defaultGridData);
  const { onClose, open, houseData, isFullScreen, setIsFullScreen } = props;

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (!houseData?._id) {
      return;
    }
    setLoading(true);
    setDamageDepositData([]);
    setReportData([]);
    async function fetchVariableCostsAverageData() {
      await auth.getDamageDepositData(houseData._id).then((res) => {
        setDamageDepositData(res.data);
        setLoading(false);
      });
    }
    fetchVariableCostsAverageData();
  }, [houseData]);

  function formatDate(date) {
    if (!date) {
      return false;
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');

    return `${month}/${day}/${year}`;
  }

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    const result = damageDepositData.map((row) =>
      row.all_agreements._id === newRow.id
        ? {
            ...row,
            all_agreements: {
              ...row.all_agreements,
              returned_damaged_deposit: newRow.returned_damaged_deposit,
              returned_damaged_deposit_date: newRow.returned_damaged_deposit_date,
              returned_damaged_form_description: newRow.returned_damaged_form_description,
              comments: newRow.comments,
            },
          }
        : row
    );
    const { all_agreements: data } = result.find((v) => v.all_agreements._id === newRow.id);
    auth.saveDamageDepositData(newRow.id, data).then(() => {
      toast.success('Saved');
    });
    setDamageDepositData(result);
    return updatedRow;
  };

  const handleEditCellChangeCommitted = React.useCallback(
    async ({ id, field, props }) => {
      if (field === 'returned_damaged_deposit') {
        const result = damageDepositData.map((row) =>
          row.all_agreements._id === id
            ? { ...row, all_agreements: { ...row.all_agreements, [field]: props.value } }
            : row
        );
        const { all_agreements: data } = result.find((v) => v.all_agreements._id === id);
        await auth.saveDamageDepositData(id, data).then(() => {
          toast.success('Saved');
        });
        setDamageDepositData(result);
      }
    },
    [damageDepositData]
  );

  useEffect(() => {
    if (damageDepositData.length <= 0) {
      defaultGridData.columns[0].headerName = 'Not found';
      setReportData(defaultGridData);
      return;
    }

    //Creating headers
    const columns = [
      { field: 'id', headerName: 'ID', hide: true },
      {
        field: 'signed_on',
        headerName: 'Date signed',
        width: 110,
        editable: false,
      },
      {
        field: 'name',
        headerName: 'Name',
        editable: false,
      },
      {
        field: 'email',
        headerName: 'Email',
        editable: false,
      },
      {
        field: 'room_name',
        headerName: 'Room rented',
        editable: false,
      },
      {
        field: 'deposit',
        headerName: 'Damage deposit requested',
        editable: false,
        width: 180,
        align: 'center',
        renderCell: (params) => {
          return <>$ {Math.round(params.row.deposit)}</>;
        },
      },
      {
        field: 'returned_damaged_deposit',
        headerName: 'Did I return damage deposit?',
        editable: false,
        width: 170,
        align: 'center',
        renderCell: (params) => {
          return (
            <ToggleButton
              value="check"
              selected={params.row.returned_damaged_deposit}
              onChange={() => {
                handleEditCellChangeCommitted({
                  id: params.id,
                  field: 'returned_damaged_deposit',
                  props: {
                    value: !params.row.returned_damaged_deposit,
                  },
                });
              }}
              sx={{ margin: 'auto' }}
            >
              {params.row.returned_damaged_deposit && <CheckIcon />}
            </ToggleButton>
          );
        },
      },
      {
        field: 'returned_damaged_form_description',
        headerName: 'How did I return it',
        width: 130,
        editable: true,
        align: 'center',
        renderCell: (params) => (
          <>{params.row.returned_damaged_form_description ? params.row.returned_damaged_form_description : '-'}</>
        ),
      },
      {
        field: 'returned_damaged_deposit_date',
        headerName: 'Date damage deposit returned',
        editable: true,
        width: 170,
        type: 'date',
        align: 'center',
        renderCell: (params) => (
          <>{params.row.returned_damaged_deposit_date ? formatDate(params.row.returned_damaged_deposit_date) : '-'}</>
        ),
      },
      {
        field: 'comments',
        headerName: 'Comments',
        editable: true,
        width: 150,
      },
    ];
    //end fixed fields
    //End creating headers

    //Creating the rows
    const rows = damageDepositData.map((data) => {
      return {
        id: data.all_agreements._id,
        identifier: data._id,
        signed_on: data.all_agreements.is_signed ? data.all_agreements.signed_on : 'Not signed',
        name: `${data.first_name} ${data.last_name}`,
        email: data.email,
        room_name: data.room_name,
        deposit: data.deposit,
        returned_damaged_deposit: data.all_agreements.returned_damaged_deposit,
        returned_damaged_form_description: data.all_agreements.returned_damaged_form_description,
        returned_damaged_deposit_date: data.all_agreements.returned_damaged_deposit_date
          ? new Date(data.all_agreements.returned_damaged_deposit_date)
          : data.all_agreements.returned_damaged_deposit_date,
        comments: data.all_agreements.comments,
      };
    });
    //End creating the dynamic rows

    const newReportData = { columns, rows };
    setReportData(newReportData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [damageDepositData]);

  const apiRef = useGridApiRef();

  return (
    <Dialog onClose={handleClose} open={open} fullScreen={isFullScreen} maxWidth="xl">
      <DialogTitle>
        Damage deposit report for <strong>{houseData?.house_name}</strong> house.
      </DialogTitle>
      {loading ? (
        <Box display={'flex'} justifyContent={'center'} padding={2}>
          <div className="spinner-border col-md-1" role="status" />
        </Box>
      ) : (
        <Box sx={{ height: 400, width: '100%' }}>
          <DataGrid
            apiRef={apiRef}
            columns={reportData.columns}
            rows={reportData.rows}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 12,
                },
              },
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
            }}
            pageSizeOptions={[12]}
            disableRowSelectionOnClick
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
          />
        </Box>
      )}
      <Button variant="outlined" onClick={handleClose}>
        Close
      </Button>
      <Button variant="outlined" onClick={() => setIsFullScreen(!isFullScreen)}>
        {isFullScreen ? 'Minimize' : 'FullScreen'}
      </Button>
    </Dialog>
  );
}

function DamageDepositReportButton({ houseSelected }) {
  const [isFullScreenAveragesReport, setIsFullScreenAveragesReport] = useState(false);

  const [openAveragesReport, setOpenAveragesReport] = useState(false);

  const handleDamageDepositDialogClose = () => {
    setOpenAveragesReport(false);
  };

  const handleDamageDepositDialogOpen = () => {
    setOpenAveragesReport(true);
  };

  return (
    <>
      <Box>
        <ButtonBootstrap className="row" variant="warning" onClick={() => handleDamageDepositDialogOpen()}>
          Damage Deposit Report
          <WaterDamageIcon />
        </ButtonBootstrap>
      </Box>
      <VariableCostsAveragesDialog
        onClose={handleDamageDepositDialogClose}
        open={openAveragesReport}
        houseData={houseSelected}
        isFullScreen={isFullScreenAveragesReport}
        setIsFullScreen={setIsFullScreenAveragesReport}
      />
    </>
  );
}

export default DamageDepositReportButton;
