import { Badge, Box, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

function AdminTabs({ supps }) {
  const tabNametoIndex = {
    '/admin': 0,
    '/newagg': 1,
    '/agreements': 2,
    '/feedbackAdminPage': 3,
    '/adminsupplies': 4,
    '/releasing': 5,
    '/roomiearchive': 6,
    '/variablecosts': 7,
  };

  const pathname = useLocation().pathname;
  const [value, setValue] = useState(tabNametoIndex[pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="col-md-7 col-lg-8 col-xl-9 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
      <div className="nav-wrapper position-relative ">
        <Box sx={{}} className="nav nav-pills nav-fill p-1">
          <Tabs value={value ? value : 0} onChange={handleChange} variant="scrollable" scrollButtons={true}>
            <Tab label="Home" to="/admin" component={Link} />
            <Tab label="New Agreement" to="/newagg" component={Link} />
            <Tab label="Design Agreements" to="/agreements" component={Link} />
            <Tab label="Submit a Feedback" to="/feedbackAdminPage" component={Link} />
            <Tab
              label={
                <Badge badgeContent={supps?.length} color="warning">
                  Supplies Page
                </Badge>
              }
              to="/adminsupplies"
              component={Link}
            />
            <Tab label="Release the Lease" to="/releasing" component={Link} />
            <Tab label="Previous Roomies" to="/roomiearchive" component={Link} />
            <Tab label="Variable Costs" to="/variablecostreports" component={Link} />
          </Tabs>
        </Box>
      </div>
    </div>
  );
}

export default AdminTabs;
