import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import PhoneInput from 'react-phone-number-input';
import auth from '../services/authService';
import { toast } from 'react-toastify';

// A form that allows a user to apply to be a homeowner on the site

function HomeOwnerApplication() {
  const { register, handleSubmit, control } = useForm({
    defaultValues: { accountType: 'roomie' },
  });

  async function onSubmit(data) {
    auth.postHouseOwnerApplication(data).then(() => {
      toast.success('Application submitted successfully');
    });
  }

  return (
    <section className="presentation-page">
      <div className="page-header align-items-start min-vh-100" loading="lazy">
        <span className="mask bg-dark opacity-6"></span>
        <div className="container my-auto">
          <div className="row">
            <div className="col-lg-8 col-md-10 col-12 mx-auto mt-4 mb-4">
              <div className="card ">
                <div className="card-header p-0">
                  <div className="bg-black shadow-black rounded-top pt-3 pb-2">
                    <h1 className="text-center text-white mt-2">Homeowner Application</h1>
                  </div>
                </div>
                <div className="card-body">
                  <Form onSubmit={handleSubmit(onSubmit)} role="form" className="text-start">
                    <Form.Group controlId="first_name" size="lg" className="my-3">
                      <Form.Label className="form-label">First Name</Form.Label>
                      <Form.Control type="text" {...register('first_name')} name="first_name" />
                    </Form.Group>
                    <Form.Group controlId="last_name" size="lg" className="my-3">
                      <Form.Label className="form-label">Last Name</Form.Label>
                      <Form.Control type="text" {...register('last_name')} name="last_name" />
                    </Form.Group>

                    <Form.Group controlId="email" size="lg" className="my-3">
                      <Form.Label className="form-label">Email</Form.Label>
                      <Form.Control autoFocus type="email" {...register('email')} name="email" />
                    </Form.Group>
                    <Form.Group className="form-label" controlId="formGridPhone">
                      <Form.Label>Phone Number</Form.Label>
                      <Controller
                        control={control}
                        name="phone"
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <PhoneInput placeholder="Enter phone number" value={value} onChange={onChange} />
                        )}
                      />
                    </Form.Group>
                    <Form.Group controlId="address" size="lg" className="my-3">
                      <Form.Label className="form-label">
                        What province or state does your rental property you manage resides in? (We ask because rental
                        laws change from province to province and state to state)
                      </Form.Label>
                      <Form.Control as="textarea" {...register('address')} name="address" />
                    </Form.Group>
                    <Form.Group controlId="password" size="lg" className="my-3">
                      <Form.Label className="form-label">
                        About Me (What are your problems that you like to solve?)
                      </Form.Label>
                      <Form.Control as="textarea" {...register('about_me')} name="about_me" />
                    </Form.Group>

                    <div className="text-center">
                      <Button size="lg" type="submit" className="my-4" variant="primary">
                        Submit
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeOwnerApplication;
