import React from "react";
import PublicNav from "./PublicNav";

function NoUserHeader() {
  return (
    <div>
      <div className="pt-3">
        <PublicNav />
      </div>
    </div>
  );
}

export default NoUserHeader;
