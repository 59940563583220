import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Controller, useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import GCard from '../utils/GCard';
import auth from '../../services/authService';
import { useStateValue } from '../../context/StateProvider';
import Gavel from '@mui/icons-material/Gavel';
import AgreementModal from './AgreementModal';
import { useOutletContext } from 'react-router-dom';
import AgreementViewModal from './AgreementViewModal';
import { toast } from 'react-toastify';

const variableList = {
  'First Name': 'first_name',
  'Last Name': 'last_name',
  'Roomie Sign': 'renters_signature',
  'Phone Number': 'phone',
  'Email Address': 'email',
  'Room Name': 'room_name',
  'House Address': 'address',
  'Homeowner Name': 'lead',
  'Homeowner Sign': 'landlord_signature',
  'Base Rent': 'base_rent',
  'Utilities Amount': 'utilities',
  Discounts: 'discounts',
  'Coop Fee': 'coop',
  'Pay Rent': 'pay_rent',
  'Additional Comments': 'additional_comments',
  'Damage Deposit': 'deposit',
  'Utilities + Base Rent (Prorated) - Discounts': 'allInRent',
  'Half of the total Rent': 'halfRent',
  'Half of the total Rent + Coop Fee + Damage Deposit': 'halfDeCoop',
  'Locker Rent': 'locker_rent',
  'Total Supplies Amount': 'supplyAmount',
  'Total Supplies per Roomie': 'suppliesPerRoomie',
  'Supplies Bought By The Roomie': 'anchored_total',
  'Total Amount Due': 'allprice',
  'Today Date': 'today',
  'The first day of the agreement': 'start_date',
  'The last day of the agreement': 'end_date',
};

const modules = {
  toolbar: {
    container: [
      [{ placeholder: Object.keys(variableList) }],
      [{ header: [1, 2, 3, 4, 5, 6] }],
      [{ font: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link'],
      ['clean'],
    ],
    handlers: {
      placeholder: function (value) {
        if (value) {
          value = variableList[value];
          const cursorPosition = this.quill.getSelection().index;
          this.quill.insertText(cursorPosition, '{{' + value + '}}');
          this.quill.setSelection(cursorPosition + value.length + 4);
        }
      },
    },
  },
};
function AgreementDesign() {
  const { register, handleSubmit, reset, control } = useForm();
  const [{ user }] = useStateValue();
  const [open, setOpen] = useState(false);
  const [openAg, setOpenAg] = useState(false);
  const [agreement, setAgreement] = useState();
  const [, tenantsList] = useOutletContext();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [agreements, setAgreements] = useState(user.agreements);

  useEffect(() => {
    const placeholderPickerItems = Array.prototype.slice.call(
      document.querySelectorAll('.ql-placeholder .ql-picker-item')
    );

    placeholderPickerItems.forEach((item) => (item.textContent = item.dataset.value));

    document.querySelector('.ql-placeholder .ql-picker-label').innerHTML = 'Add Variables';

    document.querySelector('.ql-placeholder .ql-picker-label').classList.add('mx-2');
  }, []);

  const onSubmit = async (data) => {
    await auth
      .addAgreement(user._id, data)
      .then((res) => {
        toast.success('Agreement added successfully');
        setAgreements([...agreements, res.data?.pop()]);
      })
      .catch((err) => toast.error(err.message));
    //.then((res) => console.log(res));
  };

  const handleClickOpen = (agreement) => {
    setAgreement(agreement);
    setOpen(true);
  };

  const handleViewOpen = (agreement) => {
    setAgreement(agreement);
    setOpenAg(true);
  };

  const handleDelete = async () => {
    const data = {
      ...agreement,
      agreement_id: agreement._id,
    };
    delete data._id;

    await auth.removeAgreement(user._id, data).then(() => {
      toast.success('Agreement deleted successfully');
      setAgreements(agreements.filter((x) => x._id !== agreement._id));
    });

    setOpenDeleteModal(false);
  };

  const handelDeleteModel = (agreement) => {
    const agreement_count = tenantsList.filter(
      (x) => x.agreements.current_agreement?.agreement_id === agreement._id
    )?.length;

    const new_agreement = {
      ...agreement,
      agreement_count: agreement_count,
    };

    setAgreement(new_agreement);
    setOpenDeleteModal(true);
  };
  {
    /* <link href="https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap" rel="stylesheet" */
  }

  return (
    <>
      <div className="mt-9" style={{ zIndex: 10, position: 'relative' }}>
        <GCard header="Add an agreement">
          <div className="card-body pb-5">
            <div className="row">
              <Form className="m-3" onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                <Row className="mb-3 me-2">
                  <Form.Group className="col" controlId="formGridTitle">
                    <Form.Label>Agreement Name</Form.Label>
                    <Form.Control {...register('agreement_title')} name="agreement_title" placeholder="pick a name" />
                  </Form.Group>
                </Row>

                <Row className="mb-3 me-2">
                  <Form.Group className="col" controlId="formGridDesc">
                    <Form.Label>Agreement Description</Form.Label>
                    <Form.Control
                      {...register('agreement_desc')}
                      name="agreement_desc"
                      as="textarea"
                      placeholder="Describe this agreement"
                    />
                  </Form.Group>
                </Row>

                <Row className="mb-3  me-2">
                  <Form.Group className="col" controlId="formGridRTE">
                    <Form.Label>RTE</Form.Label>
                    <Controller
                      control={control}
                      name="agreement_text"
                      defaultValue={''}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <ReactQuill
                          theme="snow"
                          value={value}
                          modules={modules}
                          style={{ backgroundColor: 'white', height: '400px' }}
                          placeholder={'Write a new agreement...'}
                          onChange={onChange}
                        />
                      )}
                    />
                  </Form.Group>
                </Row>
                <div className="m-4 row ">
                  <Button className="bg-info shadow-info" variant="primary" type="submit">
                    Add Agreement
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </GCard>
      </div>
      <div className="mt-9" style={{ zIndex: 1, position: 'relative' }}>
        <GCard header="Agreement templates">
          <div className="row mt-lg-4 m-2">
            {agreements?.map((agreement, i) => (
              <div key={`agreement + ${i}`} className="col-lg-4 col-md-6 mb-4">
                <div className="card bg-light">
                  <div className="card-body p-3">
                    <div className="d-flex">
                      <div className="avatar avatar-xl bg-dark border-radius-md p-2">
                        <Gavel />
                      </div>
                      <div className="ms-3 my-auto">
                        <h6>{agreement.agreement_title}</h6>
                        <div className="avatar-group">
                          {tenantsList
                            .filter((x) => x.agreements.current_agreement?.agreement_id === agreement._id)

                            .map((x, i) => (
                              <a
                                key={'avatar-' + i}
                                className="avatar avatar-xs rounded-circle"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title={x.room_name}
                              >
                                <img alt="Image placeholder" src={x.url} className="" />
                              </a>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <span className="text-sm mt-3 text-start">{agreement.agreement_desc}</span>
                      <span className="text-sm text-bold mb-0 text-end">
                        {
                          tenantsList.filter((x) => x.agreements.current_agreement?.agreement_id === agreement._id)
                            ?.length
                        }{' '}
                        Roomies
                      </span>
                    </div>
                    <hr className="horizontal dark" />
                    <div className="row">
                      <div className="mt-3 col-12 text-center">
                        <Button
                          className="ms-2 bg-danger shadow-danger"
                          variant="primary"
                          onClick={() => handelDeleteModel(agreement)}
                        >
                          Delete
                        </Button>

                        <Button
                          className="ms-2 bg-warning shadow-warning"
                          variant="primary"
                          onClick={() => handleViewOpen(agreement)}
                        >
                          View
                        </Button>

                        <Button
                          className="ms-2 bg-info shadow-info"
                          variant="primary"
                          onClick={() => handleClickOpen(agreement)}
                        >
                          Assign
                        </Button>

                        {/* <h6 class="text-sm mb-0">02.03.22</h6>
                      <p class="text-secondary text-sm font-weight-bold mb-0">
                        Date created
                      </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </GCard>
      </div>
      <AgreementModal open={open} setOpen={setOpen} agreement={agreement} />
      <AgreementViewModal open={openAg} setOpen={setOpenAg} agreement={agreement} />
      <Modal
        show={openDeleteModal}
        onHide={() => setOpenDeleteModal(false)}
        backdrop="static"
        keyboard={false}
        className="fade"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Agreement</Modal.Title>
        </Modal.Header>
        {!agreement?.agreement_count ? (
          <>
            <Modal.Body>Do you understand that this will delete the current agreement</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setOpenDeleteModal(false)}>
                Close
              </Button>
              <Button variant="primary" onClick={() => handleDelete()}>
                DELETE Agreement
              </Button>
            </Modal.Footer>
          </>
        ) : (
          <>
            <Modal.Body>
              Please assign a different agreement to the roomies that are using this agreement before you delete it.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setOpenDeleteModal(false)}>
                Close
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
}

export default AgreementDesign;
