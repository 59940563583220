import React from 'react';
import { Outlet } from 'react-router-dom';
import NoUserHeader from '../components/utils/NoUserHeader.js';

const PublicRoute = () => {
  return (
    <div className="container-fluid px-2 px-md-4 bg-dark bg-">
      <NoUserHeader />
      <Outlet />
    </div>
  );
};

export default PublicRoute;
