import React, { useState } from "react";
import ReactPaginate from "react-paginate";

function usePagination({ data, amountPerPage }) {
  const [pageNumber, setPageNumber] = useState(0);
  const dataPerPage = amountPerPage;
  const pagesVisited = pageNumber * dataPerPage;

  const displayData = data?.slice(pagesVisited, pagesVisited + dataPerPage);

  const pageCount = Math.ceil(data?.length / dataPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return {
    Pagination: (
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={
          "bg-warning shadow-warning rounded-top pagination pagination-info justify-content-center  p-2"
        }
        pageClassName={"page-item"}
        activeClassName={"active"}
        previousLinkClassName={"page-link"}
        nextLinkClassName={"page-link"}
        previousClassName={"page-item"}
        nextClassName={"page-item"}
        pageLinkClassName={"page-link"}
      />
    ),
    displayData,
  };
}

export default usePagination;
