import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Controller, useForm } from 'react-hook-form';
import auth from '../../services/authService';
import GCard from '../utils/GCard';
import NewGuys from './NewGuys';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { useStateValue } from '../../context/StateProvider';
import { useOutletContext } from 'react-router-dom';

function AddNewAggrement() {
  const [link, setLink] = useState('...');
  const [{ user }] = useStateValue();
  const [, tenantsList] = useOutletContext();

  const schema = Joi.object({
    first_name: Joi.string().min(1).max(50).allow(''),
    last_name: Joi.string().min(1).max(50).allow(''),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .allow(''),
    phone: Joi.string()
      .required()
      .min(10)
      .max(20)
      .regex(/^\+(?:[0-9]\x20?){6,14}[0-9]$/),
    discounts: Joi.number().required(),
    utilities: Joi.number().required(),
    base_rent: Joi.number().required(),
    etransfer_password: Joi.string().min(5).required(),
    house: Joi.string().required(),
    room_name: Joi.string(),
    deposit: Joi.number(),
    coop: Joi.number(),
    start_date: Joi.string(),
    end_date: Joi.string(),
    agreement: Joi.string(),
    landlord: Joi.boolean(),
    pay_rent: Joi.string(),
    additional_comments: Joi.string().max(1500),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm({
    resolver: joiResolver(schema),
  });
  const housesWatch = watch('house');

  async function onSubmit(data) {
    if (data.agreement === 'System') {
      data.agreement = {
        agreement_title: 'System',
        agreement_desc: 'System',
        agreement_text: 'System',
      };
    } else data.agreement = user.agreements.find((x) => x._id === data.agreement);
    await auth.addNewRoomie(data, user._id).then((res) => setLink(`https://sweepnsleep.com/newlease/${res.data._id}`));
  }

  return (
    <div className="">
      <GCard header="New Aggreement">
        <Form className="m-3" onSubmit={handleSubmit(onSubmit)} onReset={reset}>
          <Row className="mb-3">
            <Form.Group className="col-md-4 mb-3" controlId="formGridFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                {...register('first_name')}
                name="first_name"
                placeholder="First Name"
                isValid={!errors.first_name}
                isInvalid={!!errors.first_name}
              />
              <Form.Control.Feedback type="invalid">{errors.first_name?.message}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="col-md-4 mb-3" controlId="formGridLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                {...register('last_name')}
                name="last_name"
                placeholder="Last Name"
                isValid={!errors.last_name}
                isInvalid={!!errors.last_name}
              />
              <Form.Control.Feedback type="invalid">{errors.last_name?.message}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="col-md-4 mb-3" controlId="formGridEmail">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                {...register('email')}
                name="email"
                type="email"
                placeholder="Email Address"
                isValid={!errors.email}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group className="col-md-4 mb-3" controlId="formGridPhone">
              <Form.Label>Phone Number</Form.Label>
              <Controller
                control={control}
                name="phone"
                render={({ field: { onChange, value } }) => (
                  <PhoneInput placeholder="Enter phone number" value={value} onChange={onChange} />
                )}
              />
              {errors.phone && (
                <p className="invalid-feedback" style={{ display: 'inline-block' }}>
                  {errors.phone.message}
                </p>
              )}
            </Form.Group>

            <Form.Group className="col mb-3" controlId="formGridType">
              <Form.Label className="text-bold">Select a House</Form.Label>
              <Form.Control
                className="bg-light"
                {...register('house')}
                name="house"
                as="select"
                onChange={(e) => setValue('house', e.target.value)}
                isValid={!errors.house}
                isInvalid={!!errors.house}
              >
                <option value={''}>Select...</option>
                {user.houses.map((house, i) => (
                  <option value={house._id} key={'house-' + i}>
                    {house.house_name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">{errors.house?.message}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="col mb-3" controlId="formGridType">
              <Form.Label className="text-bold">Select Room</Form.Label>
              <Form.Control
                className="bg-light"
                {...register('room_name')}
                name="room_name"
                as="select"
                onChange={(e) => setValue('room_name', e.target.value)}
              >
                <option value={-1}>Select...</option>
                {!housesWatch
                  ? ''
                  : tenantsList
                      .filter((x) => x.house._id === housesWatch)
                      .map((room, i) => (
                        <option value={room.room_name} key={i}>
                          {room.room_name}
                        </option>
                      ))}
              </Form.Control>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group className="col-md-4 mb-3" controlId="formGridBaseRent">
              <Form.Label>Base Rent</Form.Label>
              <Form.Control
                {...register('base_rent')}
                name="base_rent"
                type="number"
                step="any"
                placeholder="Base Rent"
                isValid={!errors.base_rent}
                isInvalid={!!errors.base_rent}
                min={0}
              />
              <Form.Control.Feedback type="invalid">{errors.base_rent?.message}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="col-md-4 mb-3" controlId="formGridUtilities">
              <Form.Label>Utilities</Form.Label>
              <Form.Control
                {...register('utilities')}
                name="utilities"
                type="number"
                step="any"
                placeholder="Utilities"
                isValid={!errors.utilities}
                isInvalid={!!errors.utilities}
                min={0}
              />
              <Form.Control.Feedback type="invalid">{errors.utilities?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col-md-4 mb-3" controlId="formGridCoopFees">
              <Form.Label>Coop Fees</Form.Label>
              <Form.Control
                {...register('coop')}
                name="coop"
                type="number"
                step="any"
                placeholder="Coop Fees"
                isValid={!errors.coop}
                isInvalid={!!errors.coop}
                min={0}
              />
              <Form.Control.Feedback type="invalid">{errors.coop?.message}</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group className="col-md-4 mb-3" controlId="formGridDeposit">
              <Form.Label>Deposit</Form.Label>
              <Form.Control
                {...register('deposit')}
                name="deposit"
                type="number"
                step="any"
                placeholder="Deposit"
                isValid={!errors.deposit}
                isInvalid={!!errors.deposit}
                min={0}
              />
              <Form.Control.Feedback type="invalid">{errors.deposit?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col-md-4 mb-3" controlId="formGridDiscounts">
              <Form.Label>Discounts</Form.Label>
              <Form.Control
                {...register('discounts')}
                name="discounts"
                type="number"
                step="any"
                placeholder="Discounts"
                isValid={!errors.discounts}
                isInvalid={!!errors.discounts}
                min={0}
              />

              <Form.Control.Feedback type="invalid">{errors.discounts?.message}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="col-md-4 mb-3" controlId="formGridRoomName">
              <Form.Label>Etransfer Password</Form.Label>
              <Form.Control
                {...register('etransfer_password')}
                name="etransfer_password"
                placeholder="Etransfer Password"
                isValid={!errors.etransfer_password}
                isInvalid={!!errors.etransfer_password}
              />
              <Form.Control.Feedback type="invalid">{errors.etransfer_password?.message}</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group className="col-md-4 mb-3" controlId="formGridStartDate">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                {...register('start_date')}
                name="start_date"
                type="date"
                placeholder="Start Date"
                isValid={!errors.start_date}
                isInvalid={!!errors.start_date}
              />
              <Form.Control.Feedback type="invalid">{errors.start_date?.message}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="col-md-4 mb-3" controlId="formGridEndDate">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                {...register('end_date')}
                name="end_date"
                type="date"
                placeholder="End Date"
                isValid={!errors.end_date}
                isInvalid={!!errors.end_date}
              />
              <Form.Control.Feedback type="invalid">{errors.end_date?.message}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="col mb-3" controlId="formGridType">
              <Form.Label className="text-bold">Select an Agreement</Form.Label>
              <Form.Control
                className="bg-light"
                // defaultValue={!isAddMode ? editRoomie.house._id : null}
                // value={!isAddMode ? editRoomie.house._id : null}
                {...register('agreement')}
                name="agreement"
                as="select"
                onChange={(e) => setValue('agreement', e.target.value)}
              >
                <option value={'System'}>System</option>

                {/* 
                #ToDo: check how the new agreement templates are handled with Todd, the current ones are not very customizable and this is generating a lot of misunderstandings with the owners.

                {user.agreements.map((agreement, i) => (
                  <option value={agreement._id} key={'agree-' + i}>
                    {agreement.agreement_title}
                  </option>
                ))} */}
              </Form.Control>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group className="col-md-12 mb-3" controlId="formGridPayRent">
              <Form.Label className="text-bold">How do I pay my rent?</Form.Label>
              <Form.Control
                {...register('pay_rent')}
                name="pay_rent"
                as="textarea"
                rows={3}
                placeholder="How do I pay my rent?"
                isValid={!errors.pay_rent}
                isInvalid={!!errors.pay_rent}
              />
              <Form.Control.Feedback type="invalid">{errors.pay_rent?.message}</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group className="col-md-12 mb-3" controlId="formGridPayRent">
              <Form.Label className="text-bold">Additional comments</Form.Label>
              <Form.Control
                {...register('additional_comments')}
                name="additional_comments"
                as="textarea"
                rows={3}
                placeholder="Additional comments"
                isValid={!errors.additional_comments}
                isInvalid={!!errors.additional_comments}
                maxLength={1500}
              />
              <Form.Control.Feedback type="invalid">{errors.additional_comments?.message}</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group className="col mt-4" controlId="formGridLandLord">
              <Form.Check
                {...register('landlord')}
                name="landlord"
                type="checkbox"
                id="landlord-switch"
                label="Does the landlord live in the house?"
              />
            </Form.Group>
          </Row>
          <div className="m-4 row ">
            <Button className="bg-info shadow-info" variant="primary" type="submit">
              Generate Link
            </Button>
          </div>
        </Form>
      </GCard>

      <div className="card mt-4 mb-4">
        <div className="card-header bg-dark text-primary text-bold text-lg">The link is here</div>
        <a href={link} target="_blank" rel="noreferrer">
          <h2>{link}</h2>
        </a>
      </div>
      <NewGuys setLink={setLink} />
    </div>
  );
}

export default AddNewAggrement;
