import React, { useEffect, useState } from 'react';
import { useStateValue } from '../../context/StateProvider';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import auth from '../../services/authService';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { toast } from 'react-toastify';

function Lease() {
  let history = useNavigate();
  const [{ user }] = useStateValue();
  const [supplyAmount, setSupplyAmount] = useState(0);
  const schema = Joi.object({
    sign_name: Joi.any().equal(user.first_name).messages({ 'any.only': '{{#label}} does not match' }),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(schema),
  });

  let today = new Date();
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = today.getMonth() + 2 !== 13 ? String(today.getMonth() + 2).padStart(2, '0') : 1; //January is 0!
  let ld = String(new Date(today.getFullYear(), today.getMonth() + 2, 0).getDate()).padStart(2, '0');
  let yyyy = today.getMonth() + 2 !== 13 ? today.getFullYear() : today.getFullYear() + 1;

  today = String(today.getMonth() + 1).padStart(2, '0') + '/' + dd + '/' + today.getFullYear();

  function onSigning(data) {
    if (data.sign_name === user.first_name) {
      auth
        .signLease(user._id, supplyAmount)
        .then(() => {
          toast.success('Lease Signed');
          window.location = '/user';
        })
        .catch((error) => {
          if (error.response.status === 400) {
            toast.error("You've already signed the lease");
          } else toast.error('Lease is not signed');
          // history(-1);
        });
    }
  }

  useEffect(() => {
    async function getAllSupplies() {
      let total_supplies = 0;

      await auth.getAllHouseSuppliesAmount(user.house._id).then((res) => {
        total_supplies = res.data['sum'];
      });
      setSupplyAmount(total_supplies);
    }
    getAllSupplies();
  }, [user.house._id]);

  return (
    <div>
      <p>
        <strong>Welcome {user.first_name}</strong>
      </p>
      <br />
      <p>This is our roommate rental agreement. &nbsp;</p>
      <p>
        To complete this rental agreement, it is simple, nothing to download or print out, you just need to hit the sign
        button. &nbsp;Then you will be able to edit the document right in your email browser. Please please please fill
        in all the blanks and at end of document type your name and type your name again for signature&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>ROOM RENTAL ROOMMATE &nbsp;RENTAL AGREEMENT</p>
      <p>
        This Lease Agreement (this &ldquo;Lease&rdquo;) between &ldquo;Head roommate/lease holder&rdquo; and
        &nbsp;&ldquo;Roommate&rdquo;. &nbsp;The parties agree as follows:
      </p>
      <h3>Roommate:</h3>
      <br />
      <b>Name:</b>
      {user.first_name} {user.last_name}
      <br />
      <b>Telephone #:</b> {user.phone}
      <br />
      <b>Email Address:</b> {user.email}
      <br />
      <p>
        By signing this lease with your email address & phone number you agree to be contacted by email & phone via
        Call, SMS & Whatsapp.
      </p>
      <br />
      <b>Today&apos;s Date:</b> {today}
      <br />
      <p>__________________________________________________________</p>
      <br />
      <h3>Rent:</h3>
      <br />
      <p>
        {' '}
        <b>With our short term fixed rent policy , you never get surprised with high utility bills</b>. We always give
        you the price with atleast some time you can decide not to rent from us and find other accommodations.
      </p>
      <p>
        Our “all in price” for you this month is{' '}
        <strong>
          <p style={{ color: 'red' }}>
            <h2>
              $
              {parseFloat(
                (
                  user.base_rent -
                  user.supplies.anchored_total +
                  user.locker_rent +
                  supplyAmount / user.house.numTenants +
                  user.house.utilities -
                  user.discounts
                ).toFixed(2)
              )}
            </h2>
          </p>
        </strong>
      </p>
      <p>
        That is all you really need to know, please pay half of this by the 18th of the previous month and the the other
        half on the first day of the new rental month
      </p>
      <p>
        <u>THE BREAKDOWN OF THE ALL IN PRICE</u> <br />
        If you are curious about how we calculated it, please see below
      </p>
      <p>
        <strong>Base rent amount is ${user.base_rent}</strong>
      </p>
      <p>
        <strong>Supplies you bought: {user.supplies.anchored_total}</strong>
      </p>
      <p>
        <strong>Total supplies this month&nbsp;</strong>
        <strong>${supplyAmount}</strong>
        <strong>&nbsp;</strong>
        <br />
      </p>
      <p>
        <strong>Supplies per roomie:</strong>
        <strong>${(supplyAmount / user.house.numTenants).toFixed(2)}</strong>
        <strong>&nbsp;</strong>
        <br />
      </p>
      <p>
        <strong>Utilities this month:&nbsp;</strong>
        <strong>${user.house.utilities}</strong>
        <br />
        to check this see https://roomz.club/utilities-2/
        <br />
        <br />
        <strong>Locker Rent: ${user.locker_rent}</strong>
      </p>
      <p>
        <strong>Your discounts from showings, feedback forms etc: {user.discounts}$</strong>
      </p>
      <br />
      <p>TERMS LISTED ABOVE:</p>
      <p>
        #1 NON REFUNDABLE RENT DEPOSIT: This is basically your monthly rent divided in 2. You will be required to pay
        the second half of the rent when you move in. &nbsp;The reason we do this is that some people will say they will
        rent a room and last minute change their mind and we have no time to re-rent the room out. &nbsp; Please note
        this is non refundable. #4&nbsp;UTILIITIES: Since it is hard to collect utilities at the end of the month,
        especially when people are moving out, we charge your month&rsquo;s utilities at the beginning of the
        month.&nbsp;&nbsp;#5 SUPPLIES: The roomies will buy supplies over the course of a month, this ensures we have
        cleaning supplies and toilet paper at the houses which leads to cleanliness and comfort).
      </p>
      <br />
      <p>
        Etransfer to&nbsp;
        <a href="mailto:theapartmenthead@outlook.com">theapartmenthead@outlook.com</a>
        &nbsp;and make the security question for the etransfer, what is my room name?
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>PREMISES.</strong>&nbsp;Lease owner/head roommate, in consideration of the lease payments provided in
        this Rental agreement, &nbsp;to the &nbsp;Roommate a bedroom&nbsp; &nbsp;&ldquo;
        {user.room_name}&rdquo;&nbsp;(the &ldquo;Premises&rdquo;) located at ___
        {user.house.address}
      </p>
      <br />
      <br />
      <p>
        <strong>TERM.</strong>&nbsp; The rental agreement term will begin on
        {' ' + mm + ' / 1 / ' + yyyy} , __ ___________ will be a__1 month&nbsp;rental agreement ending _
        {' ' + mm + ' / ' + ld + ' / ' + yyyy}
      </p>
      <br />
      <p>
        Please note, we have more than a few clauses that relate to cleaning and helping out with the house. &nbsp;Since
        it is a shared house, we need everyone to help make sure the house is a nice place to live. &nbsp;So, in order
        to do this, there is scheduling and some feedback loops to help maintain a nice living arrangement. Also,
        occasionally people move out. &nbsp;We require current roommates to help show the upcoming open room for a
        couple of reasons. First, it helps keep management costs down. &nbsp;Secondly, as important as what the room
        looks like, prospective roommates want to know who they may be living with. &nbsp;So, this is a good chance to
        see if there might be high roommate compatibility. &nbsp;
        <strong>This is NOT a month &nbsp;to month rental agreement.</strong>
      </p>
      <p>
        <strong>&nbsp;</strong>This is a fixed term rental agreement and the apartment head is under no legal
        requirement to renew the rental agreement at that time. &nbsp;Likewise, the roommate is under no legal
        requirement to stay on at that time.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>RENTAL AGREEMENT LENGTH AND PROCESS</strong>
      </p>
      <p>
        We only offer fixed term rental agreements. &nbsp;When you have just over 2 weeks remaining in your rental
        agreement, you might be provided a new rental agreement. &nbsp;You will have 2 days &nbsp;to accept that offer.
        &nbsp;Should we not hear back from you and a signed rental agreement has not been returned, advertisements will
        be placed and new roommates will be invited to view this rental. &nbsp;Please note that the first showing date
        will be 96 hours after the email has been sent at 7 pm.&nbsp;
      </p>
      <br />
      <p>
        <strong>Guidelines and culture</strong>
      </p>
      <p>
        <em>
          In general, we are looking to create a respectful, quiet, clean, and friendly setting. To do this, we need
          folks to help keep it clean, help each other when we can, and try to keep in mind the effect we have on others
          :)
        </em>
        &nbsp;
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>Acceptable Behaviour in the Household</strong>
      </p>
      <p>Drunken, loud, threatening, intimidating, or disrespectful behavior will not be tolerated.</p>
      <br />
      <h3>Visitors</h3>
      <p>
        Occasional visitors are welcome for short visits.&nbsp;
        <br />
        &nbsp;Girlfriend/boyfriends as long as you spend some time at their place as well. You are responsible to see
        that your guests are aware of these guidelines. We would prefer if you do not have overnight guests until we get
        to know you a bit. No strangers, pick ups, hitchhikers, couch surfers overnight. &nbsp;If partners are coming
        from out of town, they can stay for 3 days maximum per month. &nbsp;Other than that, you will need to find
        alternative housing, the apartment is small and it isn&rsquo;t suited for couples longer than a few days.
      </p>
      <br />
      <h3>Sexual Guidelines</h3>
      <p>
        Sexual activity is fine, but please clean up after yourself and conduct yourself in a way that is respectful of
        others and their sensibilities, keeping in mind that everyone has their own work and sleep schedule
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>Bedroom Notes</strong>
      </p>
      <p>
        Please don&apos;t smoke in your room....it is instantly noticeable to all rooms even if your window is open and
        towels under your door. Please don&apos;t keep dirty dishes and old food in your room, it attracts
        pests.&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
      </p>
      <br />
      <p>
        <strong>BATHROOM:</strong>
      </p>
      <p>
        Rinse tub out after use. Clean up sink area after use, especially whiskers (shaving stubble). Our drains plug
        from hair. In the bathtubs, use the drainer hair catchers to prevent this. Otherwise, plumbers will be called to
        clear the drains and our monthly cleaning fee will go up. &nbsp;Please limit your use of 10 minutes at a time of
        bathroom use. &nbsp;Bathroom space is limited. &nbsp;If you need very long periods of bathroom time, DO NOT RENT
        FROM US. &nbsp;
      </p>
      <br />
      <p>
        <strong>Storage</strong>
      </p>
      <p>
        The roommate/tenant/renter/guest shall not store goods, materials, equipment or property of any kind, excluding
        registered vehicles on the rental agreement upon the Premises unless such property is approved by the homeowner
        in writing and notarized by a lawyer or notary public.
      </p>
      <br />
      <p>
        <strong>Use of Common Areas</strong>
      </p>
      <p>
        A minimum of personal belongings can be left in the common areas. Please remove shoes or boots when entering the
        household, making sure that footwear is placed on an appropriate mat or boot tray. There is no coat closet in
        the house. The living room TV is shared.
      </p>
      <br />
      <p>
        <strong>Snow and Lawn:</strong>
      </p>
      <p>In order to keep costs down, you are required to take a turn in cutting grass and shovelling snow.</p>
      <br />
      <p>
        <strong>Use of Utilities</strong>
      </p>
      <p>
        Within reason, please turn off the TV and the lights when leaving the house or when a room is likely to remain
        unoccupied. In cold weather, please do not leave windows open. In your own bedroom, please turn off lights, TVs,
        computers, and small heaters if you know that it will be empty for several hours or more.
      </p>
      <br />
      <p>
        <strong>Parking</strong>
      </p>
      <p>Street parking, please don&apos;t block our neighbours driveway</p>
      <br />
      <p>
        <strong>Noise</strong>
      </p>
      <p>
        We aspire to having a quiet house most of the time. At night, especially from about 10pm to 7am we are
        particularly conscious of keeping the volume down for voices, laughter, TVs and stereos, as some of us need to
        be up for work by 5 am. Volumes should not migrate to other rooms. It is good if we keep each other advised of
        our need for sleep, especially if it is at times other than regular night time hours.
      </p>
      <br />
      <p>
        <strong>Pets</strong>
      </p>
      <p>No pets...sorry.</p>
      <br />
      <p>
        <strong>Alchohol &amp; Hard Drugs</strong>
      </p>
      <p>
        Hard drugs may not be consumed in the home and anyone with this problem will be asked to leave. We do not have a
        prohibition against alcohol, social drinking is fine. No smoking of marijuana inside the house. &nbsp;The smell
        of marijuana should not affect the enjoyment of the apartment for others.
      </p>
      <br />
      <p>
        <strong>General Points:</strong>
      </p>
      <p>
        We can be equally responsible for the upkeep of this household by sharing chores and tasks. We aim for a
        relaxed, easygoing atmosphere in our home. Our housekeeping standards are about appearing more than functional
        so that the house is clean, comfortable, and attractive for everyone and has some level of curb appeal for
        visitors, friends, or prospective roommates. We generally prefer neat and tidy surrounding
      </p>
      <br />
      <p>
        <strong>Use of Kitchen</strong>
      </p>
      <p>Please no cooking after 9:30 pm</p>
      <p>
        Please no deep frying and no frying for long periods of time, our houses are not equipped with great kitchen
        ventilation systems. &nbsp;
      </p>
      <p>Please wipe up countertops after use.</p>
      <p>You are required to do your dishes within 2 hours of using the kitchen.</p>
      <p>Do not cut on the counters, but use a cutting board.</p>
      <p>Please do not sit on counters. &nbsp;</p>
      <br />
      <p>
        <strong>Use of Laundry Room</strong>
      </p>
      <p>
        Laundry machines (one washer, one dryer) are shared, please clear the dryer filter for the dryer after use so
        dryer does not overheat.
      </p>
      <p>Please no laundry after 9:45 pm</p>
      <br />
      <p>
        <strong>Quiet</strong>
      </p>
      <p>
        We specialise in quiet/ working people type accommodations. &nbsp;Generally everyone is friendly but if you want
        to &ldquo;hang out&rdquo;, have friends over, game in the living room, this is not for you.
      </p>
      <br />
      <p>
        <strong>USE OF FURNITURE INCLUDE BED MATRESS</strong>
      </p>
      <p>
        The furnishing are in good shape including the mattress. &nbsp;Do not sleep on the matress without a sheet.
        &nbsp;You will be asked to show that you have sheets prior to move in. &nbsp;Should you break the furniture
        or&nbsp;<strong>sleep on the mattress without a sheet</strong>, you will be charged replacement cost of the item
        from your damage deposit and/or cleaning deposit.&nbsp;
      </p>
      <br />
      <p>
        <strong>ROOM SHOWINGS.</strong>&nbsp;&nbsp;
      </p>
      <p>
        We need you to help show rooms. &nbsp;That way, you can give us feedback on who you would like to live with and
        the potential roomie can decide if not only the place is right for them, but their new roomies would work for
        them.&nbsp;&nbsp;
      </p>
      <p>
        When we have an upcoming room that will be open in the house, we will whatsapp you on Monday to find one
        specific day/time you pick for your showing date.&nbsp;&nbsp;You can set up and adjust the times and dates you
        could do a showing on the &nbsp;, it&nbsp;
      </p>
      <br />
      <p>
        <strong>HOUSE CLEANING</strong>
      </p>
      <p>
        The roommate agrees to take part in the cleaning schedule of the house and agrees to clean his/her dishes after
        eating. &nbsp;Also, the roommate agrees to contribute &nbsp;to what we need on supplies to buy toilet paper and
        cleaning supplies
      </p>
      <br />
      <p>
        <strong>UTILITIES AND SERVICES</strong>
      </p>
      <p>
        Roommates shall be responsible for their proportion of the utilities and services incurred in connection with
        the Premises. &nbsp;If we have empty rooms, your utility cost will likely increase.
      </p>
      <br />
      <p>
        <strong>VERIFICATION</strong>
      </p>
      <p>
        The roommate allows the head roommate/lease holder/homeowner/landlord to provide rental information to VLR
        verified landlord references corp. so that an accurate head roommate/lease holder reference can be provided{' '}
      </p>
      <br />
      <p>
        <strong>SUBORDINATION OF LEASE</strong>
      </p>
      <p>
        This lease is subordinate to any mortgage that now exists, or may be given later by Head roommate/lease holder,
        with respect to the Premises.
      </p>
      <br />
      <p>
        <strong>RULES:</strong>
      </p>
      <ul>
        <li>NO SMOKING</li>
        <li>Do dishes after eating your meal</li>
        <li>Can only have 1 friend over at a time</li>
        <li>Participate in cleaning schedule</li>
        <li>No pets</li>
        <li>No loud music</li>
        <li>Must be actively job searching, working, or in school&nbsp;</li>
      </ul>
      <br />
      <p>
        <strong>FEEDBACK AND LEADERSHIP</strong>
      </p>
      <p>
        Please take careful note that the houses are apartment-lead run. An apartment-lead is a more seasoned roomie who
        has lived at the house for a while and has agreed to be responsible for it. This means that decisions about the
        house will be made by the apartment lead along with the homeowner. We do welcome opinions and feedback and
        please forward them to the lead or homeowner. Your input is appreciated, However, roomies are not owners of the
        house and not owners of the rules. So, if a group of roomies “decide something” without approval of the
        apartment lead or homeowner, it may not come into effect. We would like all roomies to take ownership of house
        rules and inform others not following the rules. Also, please do not take offence of apartment leads advice
        because it is their job and responsibility.
      </p>
      <br />
      <p>
        <strong>Role of Neat keepers:</strong>
      </p>
      <p>
        The roommate allows the neat keepers to come as needed to check on and clean the house. Each house has a neat
        keeper who comes on Mondays This person’s roles is provide feedback on cleaning and “fix” areas that were not
        cleaned properly. It is possible that the lead will be acting as a neat keeper due to a variety of factors. Both
        the lead and the neat keepers are required to provide feedback on chores. Please don’t give them a hard time for
        this, it is their job and they are actually help your house stay clean and nice! You wouldn’t want to live in a
        dirty house, would you? The neat keeper will also “fix” any chore that was not done well. Please note, the cost
        comes out of the co-op deposit of the roomie who was supposed to do the chore. Chores are evaluated as
        “complete”, “complete this time but subject to improvement” and “not complete”. The cost will be deducted from
        the co-op fee of the roomie responsible for it. If all the chores are done well, we have no problem paying for
        the neat keeper of our own pocket and we will give the house a “gold star” which, as you will see, has certain
        advantages!
      </p>
      <br />
      <p>
        <strong>ASSIGNAILITY/SUBLETTING</strong>
      </p>
      <p>
        Roommate may not assign or sublease any interest in the Premises, nor assign, mortgage or pledge this Lease,
        without the prior written consent of Head roommate/lease holder, which consent may be exercised in the sole
        discretion of the Head roommate/lease holder.
      </p>
      <br />
      <p>
        <strong>LANDLORD:</strong>
      </p>
      <p>
        The roommate acknowledges that he/she/they rent a room in the house, the landlord has the right to also take a
        room as a space for him/her/they to live or use and may also use kitchen and bathroom.
      </p>
      <p>
        <strong>DISAGREEMENTS:</strong>
      </p>
      <p>
        Should there be a conflict between roomies or between a roomie and the lead or head office, both parties agree
        to submit the conflict to roomiesarbitration.club. Arbitrators are unbiased seasoned past roomies with good
        judgement.
      </p>
      <br />
      <p>
        <strong>Expiry:</strong>
      </p>
      <p>
        This lease offer expires 24 hours from the date/time it was sent via email. Should it not be returned signed
        &nbsp;and deposit paid, this lease will be null and void. &nbsp;Should first month rent and damage deposit along
        with ID, appropriate verification of employment or study, this will also render this lease null and void.&nbsp;
      </p>
      <br />
      <p>
        <strong>How do I pay my rent?</strong>
      </p>
      <p>{user.pay_rent}</p>
      <br />
      <p>
        <strong>Additional comments</strong>
      </p>
      <p>{user.additional_comments}</p>
      <br />
      <p>
        <strong>
          <strong>Apartment Lead:</strong>
        </strong>
      </p>
      <p>
        <strong>Name: {user.house.lead}</strong>
      </p>
      <br />
      <p>
        <strong>
          By typing my signature and sending it via the Internet, I acknowledge that I have read and understand all
          information provided in the lease, &nbsp;that I intend Todd Ogryzlo rely upon it; that I intend to be bound
          thereby; and that I understand and agree that my electronic signature is the equivalent of a manual written
          signature.
        </strong>
      </p>
      <br />
      <p>
        <strong>ROOMMATE:</strong>
      </p>
      <p>
        <strong>
          Name: {user.first_name} {user.last_name}
        </strong>
      </p>
      <div>
        <Form onSubmit={handleSubmit(onSigning)} onReset={reset}>
          <Form.Group controlId="formGridFirstName">
            <Form.Label>Signature:</Form.Label>
            <Form.Control
              {...register('sign_name')}
              name="sign_name"
              placeholder="Sign Name"
              isValid={!errors.sign_name}
              isInvalid={!!errors.sign_name}
            />
            <Form.Control.Feedback type="invalid">{errors.sign_name?.message}</Form.Control.Feedback>
          </Form.Group>

          <Button variant="primary" className="mt-3" type="submit">
            Sign
          </Button>

          <Button variant="danger" className="mt-3 mx-3" onClick={() => history(-1)}>
            Cancel
          </Button>
        </Form>
      </div>
    </div>
  );
}

export default Lease;
