import React from "react";
import { ReactComponent as SNSIcon } from "../SNS.svg";
import { SvgIcon } from "@mui/material";

function Footer() {
  return (
    <footer className="footer pt-5 mt-5">
      <div className="container">
        <div className=" row">
          <div className="col-md-3 text-center mb-4 ms-auto">
            <div>
              <a>
                <SvgIcon
                  className="text-primary"
                  sx={{
                    mr: 1,
                    width: 100,
                    height: 100,
                  }}
                  component={SNSIcon}
                  viewBox="0 0 2352 1511"
                />
              </a>
              <h6 className="font-weight-bolder mb-4">Sweep & Sleep</h6>
            </div>
            <div>
              <ul className="d-flex flex-row ms-n3 nav">
                <li className="nav-item">
                  <a className="nav-link pe-1" href="1" target="_blank">
                    <i className="fab fa-facebook text-lg opacity-8"></i>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link pe-1" href="1" target="_blank">
                    <i className="fab fa-twitter text-lg opacity-8"></i>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link pe-1" href="1" target="_blank">
                    <i className="fab fa-dribbble text-lg opacity-8"></i>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link pe-1" href="1" target="_blank">
                    <i className="fab fa-github text-lg opacity-8"></i>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link pe-1" href="1" target="_blank">
                    <i className="fab fa-youtube text-lg opacity-8"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-2 col-sm-6 col-6 mb-4">
            <div>
              <h6 className="text-sm text-primary">Company</h6>
              <ul className="flex-column ms-n3 nav">
                <li className="nav-item">
                  <a className="nav-link" href="aboutus" target="_blank">
                    About Us
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a className="nav-link" href="1" target="_blank">
                    Blog
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="col-md-2 col-sm-6 col-6 mb-4">
            <div>
              <h6 className="text-sm text-primary">Help & Support</h6>
              <ul className="flex-column ms-n3 nav">
                <li className="nav-item">
                  <a className="nav-link">Comming Soon ...</a>
                </li>

                {/* <li className="nav-item">
                  <a className="nav-link" href="contactus" target="_blank">
                    Contact Us
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="1" target="_blank">
                    Sponsorships
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="col-md-2 col-sm-6 col-6 mb-4 me-auto">
            <div>
              <h6 className="text-sm text-primary">Legal</h6>
              <ul className="flex-column ms-n3 nav">
                <li className="nav-item">
                  <a className="nav-link">Comming Soon ...</a>
                </li>
                {/* <li className="nav-item">
                  <a className="nav-link" href="1" target="_blank">
                    Terms & Conditions
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="1" target="_blank">
                    Privacy Policy
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="1" target="_blank">
                    Licenses (EULA)
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="col-12">
            <div className="text-center">
              <p className="text-dark my-4 text-sm font-weight-normal">
                All rights reserved. Copyright © {new Date().getFullYear()}{" "}
                Sweep & Sleep Inc
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
