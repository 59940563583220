import React, { useState, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import auth from '../services/authService';
import { useStateValue } from '../context/StateProvider';
import { actionTypes } from '../context/reducer';
import Header from '../components/utils/Header.jsx';

const PrivateRoute = ({ allowedRoles }) => {
  const [{ user }, dispatch] = useStateValue();
  const [loadingState, setLoadingState] = useState('loading');
  const location = useLocation();
  const role = auth.getUserRole();

  useEffect(() => {
    async function fetchData() {
      if (auth.checkExpJwt()) {
        await auth
          .getRoomie()
          .then((res) => {
            dispatch({
              type: actionTypes.SET_USER,
              user: res.data,
            });
            setLoadingState('userLoggedIn');
          })
          .catch((error) => {
            setLoadingState('userNotLoggedIn');
            alert(error.message);
          });
      } else {
        dispatch({
          type: actionTypes.SET_USER,
          user: null,
        });
        setLoadingState('userNotLoggedIn');
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Show the outlet only when the user is logged in
  // Otherwise, check if user is still logging in, if not, redirect the user to /signin page
  return user ? (
    <div className="container-fluid px-2 px-md-4 bg-dark bg-">
      <Header />
      {(allowedRoles?.includes('homeowners') && role === 'homeowner') ||
      (allowedRoles?.includes('neatkeepers') && role === 'neatkeeper') ||
      (allowedRoles?.includes('admins') && role === 'admin') ||
      allowedRoles?.includes('roomies') ? (
        <Outlet />
      ) : (
        <h1>Not Authorized ... </h1>
      )}
    </div>
  ) : loadingState === 'loading' ? (
    <h1>loading ...</h1>
  ) : (
    <Navigate to="/signin" replace state={{ from: location }} />
  );
};

export default PrivateRoute;
