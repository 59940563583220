import { React } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';
import auth from '../../services/authService';
import { useStateValue } from '../../context/StateProvider';
import { toast } from 'react-toastify';
import GCard from '../utils/GCard';

function RuleChange() {
  const [{ user }] = useStateValue();
  const { register, handleSubmit, reset } = useForm();

  function onSubmit(data) {
    auth
      .ruleChangeRequest({
        first_name: user.first_name,
        last_name: user.last_name,
        rule: data.rule,
        why_not: data.why_not,
        suggestion: data.suggestion,
        email: user.email,
      })
      .then(toast.success('Your Rule Change request is submited'))
      .catch((err) => console.log(err.message));
  }

  return (
    <div>
      <GCard header="Rule Change Request">
        <div className="card-body">
          <div className="card bg-light">
            <div className="card-body">
              <span className="text-bold">Note: </span>
              Please read the rules carfully in the rental agreement. If you do not like a rule, you may request a
              change and suggest an alternative. The admin will look into your request and may or may not approve. You
              have to stick to the current house rules in the rental agreement until a change is accepted by the admin.
              Thanks!!
            </div>
          </div>

          <Form className="m-4" onSubmit={handleSubmit(onSubmit)} onReset={reset}>
            <Col>
              <Form.Group as={Row} controlId="formGridRule" className="input-group input-group-static mb-4">
                <label>What rule you don&apos;t like?</label>
                <Form.Control as="textarea" {...register('rule')} name="rule" />
              </Form.Group>

              <Form.Group as={Row} controlId="formGridWhy" className="input-group input-group-static mb-4">
                <label>What are the reasons behind this request?</label>
                <Form.Control as="textarea" {...register('why_not')} name="why_not" />
              </Form.Group>

              <Form.Group as={Row} controlId="formGridSuggest" className="input-group input-group-static mb-4">
                <label>What are your suggestions?</label>
                <Form.Control as="textarea" rows={3} {...register('suggestion')} name="suggestion" />
              </Form.Group>
              <Form.Group as={Row} controlId="formButtons">
                <Button variant="info" className="m-2" type="submit">
                  Send
                </Button>
              </Form.Group>
            </Col>
          </Form>
        </div>
      </GCard>
    </div>
  );
}

export default RuleChange;
