import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import EditHouse from '../components/homeowner-components/EditHouse';
import AddNewAggrement from '../components/homeowner-components/AddNewAggrement';
import AddTenant from '../components/homeowner-components/AddTenant';
import AdminMain from '../components/homeowner-components/AdminMain';
import AdminBody from '../components/homeowner-components/AdminBody';
import AdminSuppliesPage from '../components/homeowner-components/AdminSuppliesPage';
import FeedbackAdminPage from '../components/homeowner-components/FeedbackAdminPage';
import FeedbackTable from '../components/homeowner-components/FeedbackTable';
import NewGuys from '../components/homeowner-components/NewGuys';
import View from '../components/homeowner-components/View';
import ViewHouse from '../components/homeowner-components/ViewHouse';
import FeedbackPage from '../components/user-components/FeedbackPage';
// import SideRight from "./components/RComp/SideRight";
import Lease from '../components/user-components/Lease';
import Profile from '../components/user-components/Profile';
import RuleChange from '../components/user-components/RuleChange';
import Showings from '../components/user-components/Showings';
import SuppliesPage from '../components/user-components/SuppliesPage';
import UserBody from '../components/user-components/UserBody';
import UserHome from '../components/user-components/UserHome';
import NewLease from '../components/user-components/NewLease';
import VariableCostReports from '../components/homeowner-components/VariableCosts/VariableCostReports';
import AddVariableCosts from '../components/homeowner-components/VariableCosts/AddVariableCosts';
import Login from '../pages/Login';
import NotFound from '../pages/NotFound';
import PrivateRoute from './PrivateRoute';
import Releasing from '../components/homeowner-components/Releasing';
import Archive from '../components/homeowner-components/Archive';
import ArchiveView from '../components/homeowner-components/ArchiveView';
import Homepage from '../components/admin-components/Homepage';
import AgreementDesign from '../components/homeowner-components/AgreementDesign';
import LeaseRoute from '../components/user-components/LeaseRoute';
import Landing2 from '../pages/Landing2';
import PublicRoute from './PublicRoute';
import RoomAd from '../components/utils/RoomAd';
import AdView from '../components/homeowner-components/AdView';
import HomeOwnerApplication from '../pages/HomeOwnerApplication';
import AboutUs from '../pages/AboutUs';
import ContactUs from '../pages/ContactUs';
import RentalAgreementList from '../components/user-components/RentalAgreementList';

function MainRoutes() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Landing2 />} />
      <Route path="/signin" element={<Login />} />
      <Route path="/newlease/:slug" element={<NewLease />} />

      <Route element={<PrivateRoute allowedRoles={['roomies']} />}>
        <Route element={<UserBody />}>
          <Route path="/user" element={<UserHome />} />
          <Route path="/lease" element={<LeaseRoute />} />
          <Route path="/feedbackpage" element={<FeedbackPage />} />
          <Route path="/supplies" element={<SuppliesPage />} />
          <Route path="/showings" element={<Showings />} />
          <Route path="/rulechange" element={<RuleChange />} />
        </Route>
      </Route>

      <Route element={<PrivateRoute allowedRoles={['admins']} />}>
        {/* <Route element={<UserBody />}> */}
        <Route path="/admino" element={<Homepage />} />
        <Route path="/user" element={<UserHome />} />
        <Route path="/lease" element={<Lease />} />
        <Route path="/feedbackpage" element={<FeedbackPage />} />
        {/* </Route> */}
      </Route>

      <Route element={<PrivateRoute allowedRoles={['roomies', 'homeowners', 'admins', 'neatkeepers']} />}>
        <Route path="/profile" element={<Profile />} />
      </Route>

      <Route element={<PrivateRoute allowedRoles={['homeowners']} />}>
        <Route element={<AdminBody />}>
          <Route path="/admin" element={<AdminMain />} />
          <Route path="/view" element={<View />} />
          <Route path="/archiveview" element={<ArchiveView />} />
          <Route path="/add" element={<AddTenant />} />
          <Route path="/feedback" element={<FeedbackTable />} />
          <Route path="/newagg" element={<AddNewAggrement />} />
          <Route path="/newguys" element={<NewGuys />} />
          <Route path="/feedbackAdminPage" element={<FeedbackAdminPage />} />
          {/* <Route path="/houses" element={<HousesPage />} /> */}
          <Route path="/adminsupplies" element={<AdminSuppliesPage />} />
          <Route path="/edithouse" element={<EditHouse />} />
          <Route path="/house" element={<ViewHouse />} />
          <Route path="/releasing" element={<Releasing />} />
          <Route path="/releasing/:id/:costPerPerson" element={<Releasing />} />
          <Route path="/roomiearchive" element={<Archive />} />
          <Route path="/agreements" element={<AgreementDesign />} />
          <Route path="/adview" element={<AdView />} />
          <Route path="/variablecostreports" element={<VariableCostReports />} />
          <Route path="/addvariablecosts/:id" element={<AddVariableCosts />} />
        </Route>
      </Route>

      <Route element={<PrivateRoute allowedRoles={['neatkeepers']} />}>
        <Route path="/neatkeepers" element={<FeedbackAdminPage />} />
      </Route>

      <Route element={<PublicRoute />}>
        <Route path="/roomad" element={<RoomAd />} />
        <Route path="/apply" element={<HomeOwnerApplication />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/agreementtry" element={<RentalAgreementList />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default MainRoutes;
