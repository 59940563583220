import React from "react";

function PersonalCard({ name, title, description, image }) {
  return (
    <div className="card card-profile mt-lg-4 mt-5">
      <div className="row">
        <div className="col-lg-4 col-md-6 col-12 mt-n5">
          <a href="javascript:;">
            <div className="p-3 pe-md-0">
              <img
                className="w-100 border-radius-md shadow-lg"
                src={image}
                alt="image"
              />
            </div>
          </a>
        </div>
        <div className="col-lg-8 col-md-6 col-12 my-auto">
          <div className="card-body ps-lg-0">
            <h5 className="mb-0">{name}</h5>
            <h6 className="text-info mt-1">{title}</h6>
            <p className="mb-0">{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalCard;
