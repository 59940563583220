import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import '../../css/imageSelect.css';
import ImageUpload from './ImageUpload';
import auth from '../../services/authService';

function Advertise({ openModal, setOpenModal, room }) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});
  const [imagePreview, setImagePreview, handleChangeImage, , , uploadRoomImages] = ImageUpload();

  async function onSubmit(data) {
    let image = null;
    try {
      image = await uploadRoomImages(room._id);
    } catch (err) {
      console.log(err);
      return;
    }

    data = {
      ...data,
      image_array: image.data,
      house: room.house?._id || room.house,
      room_id: room._id,
    };

    try {
      await auth.postAd(data).then(() => {
        setOpenModal(false);
      });
    } catch (err) {
      console.log(err);
      return;
    }
  }

  return (
    <>
      <Modal show={openModal} onHide={() => setOpenModal(false)} backdrop="static" keyboard={false} className="fade">
        <Modal.Header closeButton>
          <Modal.Title>Advertise {room?.room_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="m-4" onSubmit={handleSubmit(onSubmit)} onReset={reset} id="myform-ad">
            <Row className="mb-3">
              <Form.Group className="col-md-4 mb-3" controlId="formGridAmount">
                <Form.Label className="text-bold">Base Rent</Form.Label>
                <Form.Control
                  {...register('base_rent')}
                  name="base_rent"
                  type="number"
                  step="any"
                  placeholder="Amount"
                  isValid={!errors.base_rent}
                  isInvalid={!!errors.base_rent}
                />
                <Form.Control.Feedback type="invalid">{errors.base_rent?.message}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="col-md-4 mb-3" controlId="formGridCoopFees">
                <Form.Label>Coop Fees</Form.Label>
                <Form.Control
                  {...register('coop_fee')}
                  name="coop_fee"
                  type="number"
                  step="any"
                  placeholder="Coop Fees"
                  isValid={!errors.coop}
                  isInvalid={!!errors.coop}
                />
                <Form.Control.Feedback type="invalid">{errors.coop?.message}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="col-md-4 mb-3" controlId="formGridDeposit">
                <Form.Label>Deposit</Form.Label>
                <Form.Control
                  {...register('deposit')}
                  name="deposit"
                  type="number"
                  step="any"
                  placeholder="Deposit"
                  isValid={!errors.deposit}
                  isInvalid={!!errors.deposit}
                />
                <Form.Control.Feedback type="invalid">{errors.deposit?.message}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="col-md-4 mb-3" controlId="formGridAvailableDate">
                <Form.Label>Available Date</Form.Label>
                <Form.Control
                  {...register('available_date')}
                  name="available_date"
                  type="date"
                  placeholder="Available Date"
                  isValid={!errors.available_date}
                  isInvalid={!!errors.available_date}
                />
                <Form.Control.Feedback type="invalid">{errors.available_date?.message}</Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group className="col-md-12 mb-3" controlId="formGridDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  {...register('room_description')}
                  name="room_description"
                  as="textarea"
                  placeholder="Description"
                  isValid={!errors.room_description}
                  isInvalid={!!errors.room_description}
                />
                <Form.Control.Feedback type="invalid">{errors.room_description?.message}</Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="py-4">
              <div className="col-lg-12 mx-auto">
                <div className="input-group mb-3 px-2 py-2 rounded-pill bg-white shadow-sm">
                  <label id="upload-label" htmlFor="upload" className="form-label font-weight-light text-muted">
                    Upload an image for the Room
                  </label>
                  <input
                    className="form-control border-0"
                    multiple
                    type="file"
                    id="upload"
                    onChange={handleChangeImage}
                  />
                  <div className="z-index-0">
                    <label htmlFor="upload" className="btn btn-light m-0 rounded-pill px-4">
                      <CloudUploadIcon className=" text-muted" />
                      <small className="text-uppercase font-weight-bold text-muted">Choose file</small>
                    </label>
                  </div>
                </div>
                <p className="mt-5 font-italic text-black text-sm text-center">
                  The image uploaded will be rendered inside the box below.
                </p>
                <div className="image-area border-info mt-4">
                  {imagePreview &&
                    imagePreview.map((image, i) => (
                      <img
                        key={`image-${i}`}
                        id="imageResult"
                        className="img-fluid rounded shadow-sm mx-auto d-block"
                        src={image}
                        alt=""
                      />
                    ))}
                </div>
              </div>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setOpenModal(false);
              setImagePreview([]);
            }}
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit" form="myform-ad">
            Advertise
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Advertise;
