import React from "react";
import { Button } from "react-bootstrap";
import LeaseTemplate from "./LeaseTemplate";

function RentalAgreementList({ agreement }) {
  const [openModal, setOpenModal] = React.useState(0);
  const start_date = new Date(agreement.start_date);
  start_date.setDate(start_date.getDate() + 1);
  const end_date = new Date(agreement.end_date);
  end_date.setDate(end_date.getDate() + 1);

  return (
    <>
      <div className="row row-striped d-flex">
        <div className="col-12 col-sm-3 text-center">
          <div className="display-6">
            <h2 className="mt-4">
              {start_date.toLocaleDateString("default", {
                month: "short",
              })}
            </h2>
            {agreement.is_active ? (
              <span className="badge badge-active">ACTIVE</span>
            ) : agreement.is_signed ? (
              <span className="badge badge-signed">SIGNED</span>
            ) : agreement.is_overdue ? (
              <span className="badge badge-overdue">OVERDUE</span>
            ) : agreement.not_offered ? (
              <span className="badge badge-overdue">NO OFFER</span>
            ) : (
              <span className="badge badge-overdue">CLOSED</span>
            )}
          </div>
        </div>
        <div className="col-12 col-sm-9">
          <h4 className="text-uppercase mt-4 text-center text-md-start">
            <strong>
              Total To be paid: ${agreement.agreement_variables?.allprice}
            </strong>
          </h4>
          <ul className="list-inline text-center text-md-start">
            <li className="list-inline-item">
              <i className="fa fa-calendar-o" aria-hidden="true"></i>
              <b>Starts:</b>
              {start_date.toLocaleDateString()}
            </li>
            <li className="list-inline-item">
              <i className="fa fa-clock-o" aria-hidden="true"></i>
              <b>Ends:</b> {end_date.toLocaleDateString()}
            </li>
          </ul>
          <p className="text-center text-md-start">
            <i className="fa fa-location-arrow" aria-hidden="true"></i>
            Rental Room Location: {agreement.agreement_variables?.address}
          </p>
        </div>
        <div className="col-12 col-sm-3" />

        <div className="col-12 col-sm-9">
          <div className="text-center text-md-start">
            <Button
              className="ms-2 ps-4 pe-4 "
              disabled={!agreement.is_active}
              variant="primary"
              onClick={() => setOpenModal(1)}
            >
              Sign
            </Button>
            <Button
              className="ms-2 ps-4 pe-4"
              variant="danger"
              onClick={() => setOpenModal(3)}
              disabled={agreement.is_active}
            >
              View
            </Button>
          </div>
        </div>
      </div>
      <LeaseTemplate
        agreement_object={{
          ...agreement,
          agreement_variables: {
            ...agreement.agreement_variables,
            start_date: start_date.toLocaleDateString(),
            end_date: end_date.toLocaleDateString(),
            today: new Date(agreement.date_assigned).toLocaleDateString(),
          },
        }}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </>
  );
}

export default RentalAgreementList;
