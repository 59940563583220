import React, { useEffect, useState } from 'react';
import { useStateValue } from '../../context/StateProvider';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';
import auth from '../../services/authService';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { toast } from 'react-toastify';
import Handlebars from 'handlebars';

function LeaseTemplate({ agreement_object, openModal, setOpenModal }) {
  const [{ user }] = useStateValue();
  const [agreementHtml, setAgreementHTML] = useState('<div></div>');

  const schema = Joi.object({
    sign_name: Joi.any().equal(user.first_name).messages({ 'any.only': '{{#label}} does not match' }),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(schema),
  });

  function onSigning(data) {
    if (data.sign_name === user.first_name) {
      auth
        .signLease(user._id, agreement_object._id)
        .then(() => {
          toast.success('Lease Signed');
          window.location = '/user';
        })
        .catch((error) => {
          if (error.response.status === 400) {
            toast.error("You've already signed the lease");
          } else toast.error('Lease is not signed');
          // history(-1);
        });
    }
  }

  useEffect(() => {
    async function getAgreement() {
      await auth.getAgreement(user.house._id, agreement_object.agreement_id).then((res) => {
        const template = Handlebars.compile(res.data);
        setAgreementHTML(template(agreement_object?.agreement_variables));
      });
    }
    getAgreement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const [supplyAmount, setSupplyAmount] = useState(0);

  // const agreement_text = user.agreements?.current_agreement?.agreement_text;

  // let today = new Date();
  // let dd = String(today.getDate()).padStart(2, "0");
  // let mm =
  //   today.getMonth() + 2 !== 13
  //     ? String(today.getMonth() + 2).padStart(2, "0")
  //     : 1; //January is 0!
  // let ld = String(
  //   new Date(today.getFullYear(), today.getMonth() + 2, 0).getDate()
  // ).padStart(2, "0");
  // let yyyy =
  //   today.getMonth() + 2 !== 13 ? today.getFullYear() : today.getFullYear() + 1;

  // today =
  //   String(today.getMonth() + 1).padStart(2, "0") +
  //   "/" +
  //   dd +
  //   "/" +
  //   today.getFullYear();

  // const data = {
  //   first_name: user.first_name,
  //   last_name: user.last_name,
  //   phone: user.phone,
  //   base_rent: user.base_rent,
  //   discounts: user.discounts,
  //   email: user.email,
  //   room_name: user.room_name,
  //   locker_rent: user.locker_rent,
  //   today: today,
  //   mm: mm,
  //   yyyy: yyyy,
  //   ld: ld,
  //   supplyAmount: supplyAmount,
  //   utilities: user.house.utilities,
  //   anchored_total: user.supplies.anchored_total,
  //   address: user.house.address,
  //   lead: user.house.lead,
  //   allprice: parseFloat(
  //     (
  //       user.base_rent -
  //       user.supplies.anchored_total +
  //       user.locker_rent +
  //       supplyAmount / user.house.numTenants +
  //       user.house.utilities -
  //       user.discounts
  //     ).toFixed(2)
  //   ),
  //   suppliesPerRoomie: (supplyAmount / user.house.numTenants).toFixed(2),
  // };

  // useEffect(() => {
  //   async function getAllSupplies() {
  //     let total_supplies = 0;

  //     await auth.getAllHouseSuppliesAmount(user.house._id).then((res) => {
  //       total_supplies = res.data["sum"];
  //     });
  //     setSupplyAmount(total_supplies);
  //   }
  //   getAllSupplies();
  // }, [user.house._id]);

  return (
    <Modal
      show={openModal != 0}
      onHide={() => setOpenModal(false)}
      backdrop="static"
      keyboard={false}
      className="fade"
      fullscreen
    >
      <Modal.Header closeButton className="bg-light">
        <Modal.Title>Sign the Lease</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div
          dangerouslySetInnerHTML={{
            __html: agreementHtml,
          }}
        />
      </Modal.Body>
      {openModal === 1 ? (
        <div className="">
          <Form onSubmit={handleSubmit(onSigning)} onReset={reset}>
            <Row className="bg-light">
              <Form.Group controlId="formGridFirstName" className="mt-3 ps-5 pe-5">
                <Form.Label>Signature: (Please sign with your first name as it appears on the agreement)</Form.Label>
                <Form.Control
                  {...register('sign_name')}
                  name="sign_name"
                  placeholder="First Name"
                  isValid={!errors.sign_name}
                  isInvalid={!!errors.sign_name}
                />
                <Form.Control.Feedback type="invalid">{errors.sign_name?.message}</Form.Control.Feedback>
              </Form.Group>

              <div className="text-center">
                <Button variant="primary" type="submit" className="mt-4 ms-auto me-auto">
                  Sign
                </Button>
              </div>
            </Row>
          </Form>
        </div>
      ) : (
        <Row className="bg-light">
          <h2 className="text-center mt-3 ps-5 pe-5">
            {agreement_object.is_signed ? (
              <span className="badge badge-signed">SIGNED</span>
            ) : agreement_object.is_overdue ? (
              <span className="badge badge-overdue">OVERDUE</span>
            ) : agreement_object.not_offered ? (
              <span className="badge badge-overdue">CONTACT THE LANDLORD</span>
            ) : (
              <span className="badge badge-overdue">CLOSED</span>
            )}
          </h2>
        </Row>
      )}
    </Modal>
  );
}

export default LeaseTemplate;
