import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import auth from '../../services/authService';
import Handlebars from 'handlebars';
import { useStateValue } from '../../context/StateProvider';
import { toast } from 'react-toastify';

function Testing({ setOpenTestingModal, openTestingModal, openDeleteModal }) {
  const [agreementHTML, setAgreementHTML] = useState('<div></div>');
  const [openModal, setOpenModal] = useState(0);
  const [rooms, setRoomes] = useState([]);
  const [isRoomsReady, setLoadingRoomsState] = useState(false);
  const [{ user }] = useStateValue();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    async function fetchRooms() {
      setLoadingRoomsState(false);
      await auth.getOwnerRoomies(user.houses).then((res) => {
        setRoomes(res.data);
        setLoadingRoomsState(true);
      });
    }
    fetchRooms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDeleteModal]);

  function handleRelease() {
    setButtonDisabled(true);
    auth.releaseLease(user._id).then((res) => {
      console.log(res);
      toast.success('Lease released successfully!');
      setOpenTestingModal(false);
    });
  }

  const AgreementModal = () => {
    return (
      <Modal
        show={openModal}
        onHide={() => {
          setOpenModal(false);
          setOpenTestingModal(true);
        }}
        backdrop="static"
        keyboard={false}
        className="fade"
        size="xl"
      >
        <Modal.Header closeButton className="bg-light">
          <Modal.Title>Agreement View</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div
            dangerouslySetInnerHTML={{
              __html: agreementHTML,
            }}
          />
        </Modal.Body>
      </Modal>
    );
  };

  const getAgreement = async (agreement, house_id) => {
    try {
      const res = await auth.getAgreement(house_id, agreement.agreement_id);
      const template = Handlebars.compile(res.data);
      setAgreementHTML(template(agreement?.agreement_variables));
      setOpenModal(true);
      setOpenTestingModal(false);
    } catch (error) {
      console.error('Error fetching the agreement:', error);
    }
  };
  return (
    <>
      <Modal
        show={openTestingModal}
        onHide={() => {
          setOpenTestingModal(false);
        }}
        backdrop="static"
        keyboard={false}
        className="fade"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Release the lease</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive">
            <table className="table align-items-center justify-content-center mb-0">
              <thead>
                <tr>
                  <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Room</th>
                  <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">
                    Current agreement Type
                  </th>
                  <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">
                    Current Agreement Start Date
                  </th>
                  <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">
                    Current Agreement Start Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {isRoomsReady ? (
                  rooms
                    .filter((room) => room.did_move_in)
                    ?.map((room, i) => (
                      <tr key={i}>
                        <td>
                          <div className="d-flex">
                            <div>
                              <img src={room.url} className="avatar avatar-sm me-3 border-radius-lg" alt="" />
                            </div>
                            <div className="my-auto">
                              <h6 className="mb-0 text-sm">{room.room_name}</h6>
                            </div>
                          </div>
                        </td>
                        <td className="align-middle">
                          {room.all_agreements[room.all_agreements?.length - 1]?.agreement_title || 'No agreement'}
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="text-secondary text-xs font-weight-bold">
                              {
                                // put the date in the format: Sun Oct 1 2023
                                new Date(
                                  room.all_agreements[room.all_agreements?.length - 1]?.start_date
                                ).toLocaleDateString('en-US', {
                                  timeZone: 'GMT',
                                  year: 'numeric',
                                  month: 'short',
                                  day: 'numeric',
                                })
                              }
                            </span>
                          </div>
                        </td>
                        <td>
                          <button
                            className="btn btn-sm btn-secondary ms-2"
                            onClick={() => {
                              const agreement = room.all_agreements[room.all_agreements?.length - 1];
                              if (agreement) {
                                getAgreement(agreement, room.house._id);
                              }
                            }}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td>Loading...</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setOpenTestingModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => handleRelease()} disabled={buttonDisabled}>
            Release the leases
          </Button>
        </Modal.Footer>
      </Modal>
      <AgreementModal />
    </>
  );
}

export default Testing;
