import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import ScheduleSelector from 'react-schedule-selector';
import auth from '../../services/authService';
import { toast } from 'react-toastify';
import { useStateValue } from '../../context/StateProvider';
import GCard from '../utils/GCard';
import useWindowDimensions from '../../hooks/useWindowDimensions';

function Showings() {
  const [schedule, setSchedule] = useState([]);
  const [{ user }] = useStateValue();
  const { width } = useWindowDimensions();

  useEffect(() => {
    auth.getRoomieScd(user._id).then((res) => setSchedule(res.data[0]['schedule']));
  }, [user._id]);

  const handleChange = (newSchdule) => {
    setSchedule(newSchdule);
  };

  const submit = () => {
    auth
      .addRoomieScd(schedule, user._id)
      .then(() => {
        toast.success('Schedule submitted successfully');
        window.location = '/';
      })
      .catch(() => {
        toast.error('Schedule not submitted');
      });
  };

  //   const renderCustomDateCell = (time, selected, innerRef) => (
  //     <div style={{ textAlign: "center" }} ref={innerRef}>
  //       {selected ? "✅" : "❌"}
  //     </div>
  //   );
  return (
    <>
      <GCard header="What times are you available for showings?">
        <div className="m-4">
          <ScheduleSelector
            selection={schedule}
            numDays={width >= 560 ? 15 : 7}
            minTime={8}
            maxTime={22}
            hourlyChunks={1}
            dateFormat="ddd M/D"
            //renderDateCell={renderCustomDateCell}
            onChange={handleChange}
          />
        </div>

        <div className="ms-7 me-7 row">
          <Button variant="info" className="m-2" onClick={submit}>
            Submit
          </Button>
        </div>
      </GCard>
    </>
  );
}

export default Showings;
