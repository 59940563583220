import React from "react";

function GCard({ header, children }) {
  return (
    <div className="card mt-4 mb-4">
      <div className="card-header p-0 ">
        <div className="bg-warning shadow-black rounded-top pt-3 pb-2">
          <h5 className="text-white text-capitalize text-center ps-2">
            {header}
          </h5>
        </div>
      </div>
      <div className="card-body px-0 pb-2">{children}</div>
    </div>
  );
}

export default GCard;
