// This is a page that details information about us, using cards to display information about each of us.
import React from 'react';
import PersonalCard from '../components/utils/PersonalCard';

function AboutUs() {
  return (
    <section className="pb-5 min-vh-100">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-start mb-5 mt-5 text-justify">
            <h3 className="text-white z-index-1 position-relative">Welcome to Sweep and Sleep!</h3>
            <p className="text-white opacity-8 mb-0">
              At Sweep and Sleep, we are dedicated to providing a fair and transparent service for everyone involved. We
              understand the importance of living in a clean and quiet environment and we are committed to connecting
              landlords with responsible and respectful roommates. We look forward to helping you find the perfect
              living environment.
            </p>
            <p className="text-white opacity-8 mt-2 mb-0">
              Together, Todd and Mohamad have combined their expertise to create Sweep and Sleep, a platform that offers
              a clean and quiet living environment, private and locked rooms, and a suite of tools for landlords to
              manage their rental properties.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-12">
            <PersonalCard
              name="Todd Ogryzlo"
              title="Co-Founder"
              description="Todd is a landlord who has years of experience managing rental properties. He understands the challenges landlords face in finding responsible and respectful tenants. He has used his experience to provide landlords with the tools they need to manage their properties efficiently."
              image="../Todd.jpg"
            />
          </div>
          <div className="col-lg-6 col-12">
            <PersonalCard
              name="Mohamad Elzohbi"
              title="Co-Founder"
              description="Mohamad is a roomie who is a PhD candidate in Computer Science. He understands the importance of living in a clean and quiet environment and has used his knowledge in computer science to develop a platform that connects landlords with responsible and respectful roommates."
              image="../Mohamad.jpeg"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
