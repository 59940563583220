import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ObjectID from 'bson-objectid';
import usePagination from '../../hooks/usePagination';
import auth from '../../services/authService';
import { toast } from 'react-toastify';
import Handlebars from 'handlebars';
import RoomForm from './RoomForm';

const AgreementsHistory = ({ roomState, setAgreementModal, setAgreementState, getAgreement, setType }) => {
  return (
    <div className="card m-3">
      <div className="table-responsive">
        <table className="table align-items-center mb-0 table-striped text-center">
          <thead>
            <tr>
              <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7">Lease Month</th>
              <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7">Lease Status</th>
              <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7">Actions</th>
            </tr>
          </thead>
          <tbody>
            {roomState.all_agreements.map((agreement, i) =>
              !agreement.is_hidden ? (
                <tr key={'agreement-' + i}>
                  <td className="text-center">
                    <h4>
                      {new Date(agreement.end_date).toLocaleDateString('default', {
                        month: 'long',
                        year: 'numeric',
                      })}
                    </h4>
                  </td>
                  <td className="align-middle">
                    {agreement.is_hidden ? (
                      <span className="badge badge-overdue">HIDDEN</span>
                    ) : agreement.is_active ? (
                      <span className="badge badge-active">ACTIVE</span>
                    ) : agreement.is_signed ? (
                      <span className="badge badge-signed">SIGNED</span>
                    ) : agreement.is_overdue ? (
                      <span className="badge badge-overdue">OVERDUE</span>
                    ) : agreement.not_offered ? (
                      <span className="badge badge-overdue">NO OFFER</span>
                    ) : (
                      <span className="badge badge-overdue">CLOSED</span>
                    )}
                  </td>
                  <td className="align-middle">
                    <button
                      className="btn btn-sm btn-primary ms-2"
                      disabled={agreement.is_active || agreement.is_signed}
                      onClick={() => {
                        setAgreementState(agreement);
                        setType('OPEN');
                        setAgreementModal(true);
                      }}
                    >
                      Open
                    </button>
                    <button
                      className="btn btn-sm btn-danger ms-2"
                      disabled={!agreement.is_active}
                      onClick={() => {
                        setAgreementState(agreement);
                        setType('CLOSE');
                        setAgreementModal(true);
                      }}
                    >
                      Close
                    </button>
                    <button
                      className="btn btn-sm btn-secondary ms-2"
                      onClick={() => {
                        getAgreement(agreement);
                      }}
                    >
                      View
                    </button>
                  </td>
                </tr>
              ) : null
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
// display a modal that verify if the user wants to open the agreement
const EditAgreementModal = ({ show, type, onHide, agreement, editAgreement }) => {
  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Are you sure you want to {type} this agreement?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-auto">
            <div className="d-flex flex-column mt-0 mb-md-4 ms-4 me-4">
              <span className="mb-2 text-xs">
                Start Date
                <span className="text-dark font-weight-bold ms-sm-2">
                  {new Date(agreement.start_date).toLocaleString('default', {
                    month: 'long',
                    year: 'numeric',
                  })}
                </span>
              </span>
            </div>
          </div>
          <div className="col-auto">
            <div className="d-flex flex-column mt-0 mb-md-4 ms-4 me-4">
              <span className="mb-2 text-xs">
                End Date
                <span className="text-dark font-weight-bold ms-sm-2">
                  {new Date(agreement.end_date).toLocaleString('default', {
                    month: 'long',
                    year: 'numeric',
                  })}
                </span>
              </span>
            </div>
          </div>
          <div className="col-auto">
            <div className="d-flex flex-column mt-0 mb-md-4 ms-4 me-4">
              <span className="mb-2 text-xs">
                Rent Amount
                <span className="text-dark font-weight-bold ms-sm-2">
                  {'$' + agreement.agreement_variables?.allprice}
                </span>
              </span>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {type === 'OPEN' ? (
          <Button variant="primary" onClick={() => editAgreement('open')}>
            {type} Agreement
          </Button>
        ) : (
          <Button variant="danger" onClick={() => editAgreement('close')}>
            {type} Agreement
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

const AgreementModal = ({ show, agreementHTML, onHide }) => {
  return (
    <Modal show={show != 0} onHide={onHide} backdrop="static" keyboard={false} className="fade" fullscreen>
      <Modal.Header closeButton className="bg-light">
        <Modal.Title>Agreement View</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div
          dangerouslySetInnerHTML={{
            __html: agreementHTML,
          }}
        />
      </Modal.Body>
    </Modal>
  );
};

// admin comments
const AdminComments = ({ roomState }) => {
  const displayComments = roomState.admin_com.split('**').map((com, i) => (
    <tr key={i}>
      <td>{com}</td>
    </tr>
  ));

  return (
    <div className="card m-3">
      <div className="table-responsive">
        <table className="table align-items-center mb-0 table-striped">
          <thead>
            <tr>
              <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7">Admin Comments</th>
            </tr>
          </thead>
          <tbody>{displayComments}</tbody>
        </table>
      </div>
    </div>
  );
};

const Feedbacks = ({ roomState }) => {
  const { Pagination, displayData } = usePagination({
    data: roomState.feedbacks.sort((a, b) => ObjectID(b._id).getTimestamp() - ObjectID(a._id).getTimestamp()),
    amountPerPage: 5,
  });

  const displayFeeds = displayData.map((feed, i) => (
    <tr key={i}>
      <td>{feed.date}</td>
      <td>{feed.chore}</td>
      <td>
        {'% ' +
          parseFloat(
            ((feed.feedback.match(/\b(yes)(?=,)/g) || [])?.length /
              (feed.feedback.match(/\b(?:yes|no)(?=,)/g) || [])?.length) *
              100
          ).toFixed(0)}
      </td>
      <td className="text-wrap">{feed.feedback}</td>
    </tr>
  ));

  return (
    <div className="card m-3">
      <div className="table-responsive">
        {Pagination}
        <table className="table align-items-center mb-0 table-striped">
          <thead>
            <tr>
              <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7">Date</th>
              <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 p-2">Chore</th>
              <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 p-2">Percentage</th>
              <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 p-2">Feedback</th>
            </tr>
          </thead>
          <tbody>{displayFeeds}</tbody>
        </table>
      </div>
    </div>
  );
};

const RoomHeader = ({ roomState, setEditRoomForm, editRoomForm }) => {
  return (
    <div className="card-header pb-0 px-3">
      <div className="row gx-4 mb-2">
        <div className="col-auto">
          <div className="avatar avatar-xl position-relative">
            <img src={roomState.url} alt="" className="w-100 border-radius-lg shadow-sm" />
          </div>
        </div>
        <div className="col-auto my-auto">
          <div className="h-100">
            <h5 className="mb-1">{roomState.room_name + ' Room'}</h5>
            <p className="mb-0 font-weight-normal text-sm">
              {roomState.is_empty ? (
                <span className="badge badge-sm bg-danger">Vacant</span>
              ) : (
                <span className="badge badge-sm bg-success">Occupied</span>
              )}
            </p>
          </div>
        </div>
        <div className="col ">
          <Button
            className="ms-4 float-end"
            onClick={() => setEditRoomForm(!editRoomForm)}
            style={{ cursor: 'pointer' }}
            variant="info"
          >
            Edit Room info
          </Button>
        </div>
      </div>
      <div className="mb-2 ps-2">
        <p className="text-sm">
          This room is Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
          the industry&apos;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type
          and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
          electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of
          Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus
          PageMaker including versions of Lorem Ipsum.
        </p>
      </div>
    </div>
  );
};

const Roomie = ({ roomState }) => {
  return (
    <>
      <div className="row gx-3 mb-2 mx-0">
        <div className="col-auto my-3">
          <img
            src={roomState.url}
            alt=""
            className="avatar avatar-md rounded-circle border-radius-md shadow-md position-relative"
          />
        </div>
        <div className="col-auto my-auto">
          <div className="h-100">
            <h6 className="mb-1">{roomState.first_name + ' ' + roomState.last_name}</h6>
            <p className="mb-0 font-weight-normal text-xs">Current Roomie</p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
          <Link to={'/add'} state={roomState}>
            <Button className="ms-4 float-end" style={{ cursor: 'pointer' }} variant="info">
              Edit Roomie
            </Button>
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-auto">
          <div className="d-flex flex-column mt-0 mb-md-4 ms-4 me-4">
            <span className="mb-2 text-xs">
              Email Address
              <span className="text-dark font-weight-bold ms-sm-2">{roomState.email}</span>
            </span>

            <span className="mb-2 text-xs">
              Phone Number
              <span className="text-dark font-weight-bold ms-sm-2">{roomState.phone}</span>
            </span>

            <span className="mb-2 text-xs">
              Base Rent
              <span className="text-dark font-weight-bold ms-sm-2">{'$' + roomState.base_rent}</span>
            </span>

            <span className="mb-2 text-xs">
              Co-OP Deposit
              <span className="text-dark font-weight-bold ms-sm-2">{'$' + roomState.coop_fee}</span>
            </span>

            <span className="mb-2 text-xs">
              Damage Deposit
              <span className="text-dark font-weight-bold ms-sm-2">{'$' + roomState.deposit}</span>
            </span>
          </div>
        </div>

        <div className="col-auto">
          <div className="d-flex flex-column mt-0 m-4">
            <span className="mb-2 text-xs">
              Week&apos;s Chore:
              <span className="text-dark font-weight-bold ms-sm-2">
                {roomState.chore_weekly.map((chore) => chore.name + ', ')}
              </span>
            </span>

            <span className="mb-2 text-xs">
              Permanent Chore
              <span className="text-dark font-weight-bold ms-sm-2">{roomState.chore_permanent}</span>
            </span>

            <span className="mb-2 text-xs">
              Discounts
              <span className="text-dark font-weight-bold ms-sm-2">{'$' + roomState.discounts}</span>
            </span>

            <span className="mb-2 text-xs">
              Signing Disabled?
              <span className="text-dark font-weight-bold ms-sm-2">{roomState.did_sign_lease ? 'YES' : 'NO'}</span>
            </span>
            <span className="mb-2 text-xs">
              Last Lease Signed on:
              <span className="text-dark font-weight-bold ms-sm-2">{roomState.rents?.last_rent?.last_rent_date}</span>
            </span>
          </div>
        </div>
        <div className="col-auto text-center ms-3">
          <table className="card table">
            <thead>
              <tr className="mb-2 text-xs">
                <th colSpan="2" className="align-middle">
                  Signed Rent History
                </th>
              </tr>
            </thead>
            <tbody>
              {roomState.rents.rent_list?.map((rent, i) => (
                <tr key={'rents-' + i} className="text-xxs">
                  <td className="text-dark font-weight-bold ms-sm-2">
                    {ObjectID(rent._id).getTimestamp().toISOString().slice(0, 10)}
                  </td>
                  <td>{'$' + rent.rent_amount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="col-auto text-center ms-3">
          <table className="card table">
            <thead>
              <tr className="mb-2 text-xs">
                <th colSpan="2" className="align-middle">
                  Payments History
                </th>
              </tr>
            </thead>
            <tbody>
              {roomState.payments.payments_invoices?.map((inv, i) => (
                <tr key={'invoices-' + i} className="text-xxs">
                  <td className="text-dark font-weight-bold ms-sm-2">
                    {ObjectID(inv._id).getTimestamp().toISOString().slice(0, 10)}
                  </td>
                  <td>{'$' + inv.payment_amount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

function View() {
  let location = useLocation();
  const [editRoomForm, setEditRoomForm] = useState(false);
  const [roomState, setRoomState] = useState(location.state);
  const [agreementModal, setAgreementModal] = useState(false);
  const [agreementState, setAgreementState] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [agreementHTML, setAgreementHTML] = useState('<div></div>');
  const [type, setType] = useState('open');

  const getAgreement = async (agreement) => {
    try {
      const res = await auth.getAgreement(
        // if the roomState.house is not an object id, then it is the id of the house
        typeof roomState.house === 'string' ? roomState.house : roomState.house._id,
        agreement.agreement_id
      );
      const template = Handlebars.compile(res.data);
      setAgreementHTML(template(agreement?.agreement_variables));
      setOpenModal(true);
    } catch (error) {
      console.error('Error fetching the agreement:', error);
    }
  };

  const editAgreement = (editType) => {
    auth
      .editagreement(roomState._id, agreementState._id, editType)
      .then(() => {
        toast.success('Agreement edited successfully');

        // set agreement state with updating the is_active
        setRoomState((prevState) => {
          let newAgreements = [...prevState.all_agreements];
          newAgreements.forEach((agreement) => {
            if (agreement._id === agreementState._id) {
              agreement.is_active = !agreement.is_active;
              agreement.not_offered = !agreement.not_offered;
            }
          });
          return { ...prevState, all_agreements: newAgreements };
        });
      })
      .catch((err) => {
        console.log(err);
      });
    setAgreementModal(false);
  };

  return (
    <div className="card">
      <RoomHeader roomState={roomState} setEditRoomForm={setEditRoomForm} editRoomForm={editRoomForm} />
      <div className="card-body p-4">
        <div className="card bg-gray-200">
          {editRoomForm && (
            <RoomForm setEditRoomForm={setEditRoomForm} roomState={roomState} setRoomState={setRoomState} />
          )}
          {!roomState.is_empty && (
            <>
              <Roomie roomState={roomState} />
              <AgreementsHistory
                roomState={roomState}
                setAgreementModal={setAgreementModal}
                setAgreementState={setAgreementState}
                getAgreement={getAgreement}
                setType={setType}
              />
              <AdminComments roomState={roomState} />
              <Feedbacks roomState={roomState} />
              <EditAgreementModal
                show={agreementModal}
                type={type}
                agreement={agreementState}
                onHide={() => setAgreementModal(false)}
                editAgreement={editAgreement}
              />
              <AgreementModal show={openModal} agreementHTML={agreementHTML} onHide={() => setOpenModal(false)} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default View;
