import React, { useEffect, useState } from 'react';
import { useStateValue } from '../../context/StateProvider';
import GCard from '../utils/GCard';
import RentalAgreementList from './RentalAgreementList';
import auth from '../../services/authService';

function LeaseRoute() {
  const [{ user }] = useStateValue();
  const [agreements, setAgreements] = useState([]);

  // get all agreements for the user
  useEffect(() => {
    auth.getAllAgreements(user._id).then((res) => {
      setAgreements(res.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // return user.agreements?.current_agreement ? <LeaseTemplate /> : <Lease />;
  return (
    <GCard header={'Your Agreements'}>
      <div className="container mb-5">
        {agreements?.map(
          (agreement, i) => !agreement.is_hidden && <RentalAgreementList key={'agreement-' + i} agreement={agreement} />
        )}
      </div>
    </GCard>
  );
}

export default LeaseRoute;
