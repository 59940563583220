import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';
import auth from '../../services/authService';
import ErrorMessage from './ErrorMessage';
import { toast } from 'react-toastify';

const roomSchema = Joi.object({
  room_name: Joi.string().min(1).max(50).required(),
  room_key: Joi.string().min(0).max(20),
  chore_permanent: Joi.string().min(1).max(50),
}).options({ allowUnknown: true });

function RoomForm({ roomState, setEditRoomForm, setRoomState }) {
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(roomSchema),
  });

  useEffect(() => {
    reset(roomState);
  }, [roomState, reset]);

  const onSubmit = async (data) => {
    data = {
      ...data,
      house: typeof roomState.house === 'string' ? roomState.house : roomState.house._id,
      pay_rent: !data.pay_rent ? '' : data.pay_rent,
      additional_comments: !data.additional_comments ? '' : data.additional_comments,
    };

    try {
      await auth.editRoomInfo(data, roomState._id);
      setEditRoomForm(false);
      setRoomState((prevState) => ({
        ...prevState,
        ...data,
      }));
      toast.success('Room info updated successfully');
    } catch (err) {
      console.error(err.message);
      setIsErrorVisible(true);
    }
  };

  return (
    <>
      <Form className="m-4" onSubmit={handleSubmit(onSubmit)} onReset={reset}>
        <Row className="mb-3">
          <Form.Group className="col-md-4 mb-3" controlId="formGridRoomName">
            <Form.Label>Room Name</Form.Label>
            <Form.Control
              {...register('room_name')}
              name="room_name"
              placeholder="Room Name"
              isValid={!errors.room_name}
              isInvalid={!!errors.room_name}
            />
            <Form.Control.Feedback type="invalid">{errors.room_name?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="col-md-4 mb-3" controlId="formGridRoomKey">
            <Form.Label>Room Key</Form.Label>
            <Form.Control
              {...register('room_key')}
              name="room_key"
              placeholder="Room Key"
              isValid={!errors.room_key}
              isInvalid={!!errors.room_key}
            />
            <Form.Control.Feedback type="invalid">{errors.room_key?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="col-md-4 mb-3" controlId="formGridChorePerm">
            <Form.Label>Permanent Chore</Form.Label>
            <Form.Control
              {...register('chore_permanent')}
              name="chore_permanent"
              type="chore_permanent"
              placeholder="Permanent chore"
              isValid={!errors.chore_permanent}
              isInvalid={!!errors.chore_permanent}
            />
            <Form.Control.Feedback type="invalid">{errors.chore_permanent?.message}</Form.Control.Feedback>
          </Form.Group>
        </Row>

        <div className="text-center">
          <Button variant="info" type="submit">
            Save
          </Button>
        </div>
      </Form>

      <ErrorMessage
        show={isErrorVisible}
        message={'Error saving room info, please contact the support.'}
        onClose={() => setIsErrorVisible(false)}
      />
    </>
  );
}

export default RoomForm;
