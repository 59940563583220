import React from "react";
import { Button, Modal } from "react-bootstrap";

function AgreementViewModal({ open, setOpen, agreement }) {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Modal
      show={open}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="fade"
    >
      <Modal.Header closeButton>
        <Modal.Title>View the agreement</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div dangerouslySetInnerHTML={{ __html: agreement?.agreement_text }} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AgreementViewModal;
