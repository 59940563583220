import { React, useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';
import auth from '../../services/authService';
import { useStateValue } from '../../context/StateProvider';
import { toast } from 'react-toastify';
import GCard from '../utils/GCard';

function SuppliesPage() {
  const [{ user }] = useStateValue();
  const [suppliesList, setSuppliesList] = useState();
  const [loadingState, setLoadingState] = useState('loading');

  const { register, handleSubmit, reset } = useForm();

  async function getAllSupplies() {
    await auth.getAllHouseSupplies(user.house._id).then((res) => {
      setSuppliesList(res.data);
      setLoadingState('housesReady');
    });
  }

  useEffect(() => {
    getAllSupplies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function SuppliesTable() {
    return suppliesList.map((supply, j) => (
      <tr key={j}>
        <td>{supply.room_name}</td>
        <td>
          <div className="card table-responsive p-0 ">
            <table className="table mb-2 table-striped">
              <thead className="bg-warning">
                <tr>
                  <th className="text-white">Date</th>
                  <th className="text-white">Description</th>
                  <th className="text-white">Price</th>
                </tr>
              </thead>
              <tbody>
                {supply.supplies.supplies_items
                  .filter((sup) => sup.verified === 1)
                  .map((supplyItem, i) => (
                    <tr className="" key={i}>
                      <td>{new Date(supplyItem.date).toDateString()}</td>
                      <td className="text-wrap">{supplyItem.what}</td>
                      <td>{supplyItem.price}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </td>
      </tr>
    ));
  }

  function UnverifiedSuppliesTable() {
    return (
      <div className="card table-responsive p-0 ">
        <table className="table mb-2 table-striped">
          <thead className="bg-secondary">
            <tr>
              <th colSpan={3} className="text-white">
                <div className="text-center">The following will be verified soon</div>
              </th>
            </tr>
          </thead>
          <thead>
            <tr>
              <th>Date</th>
              <th>Description</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {suppliesList
              .filter((sup) => sup.room_name === user.room_name)[0]
              ?.supplies.supplies_items.filter((sup) => sup.verified === 0)
              .map((supplyItem, i) => (
                <tr className="" key={i}>
                  <td>{new Date(supplyItem.date).toDateString()}</td>
                  <td className="text-wrap">{supplyItem.what}</td>
                  <td>{supplyItem.price}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  }

  function onSubmit(data) {
    auth
      .addRoomieSupplies({ ...data, date: new Date(), verified: 0 }, user._id)
      .then(() => {
        toast.success("Your Supplies' claim is submited");
        getAllSupplies();
      })
      .catch((err) => console.log(err.message));
  }

  // <div className="card mt-4">
  // <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2 bg-transparent">
  //   <div className="bg-info shadow-info border-radius-lg p-3">
  //     <h3 className="text-white text-info text-center mb-0">
  //       Submit your Supplies
  //     </h3>
  //   </div>
  // </div>

  return (
    <div>
      <GCard header="Submit your Supplies">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-6 mb-4">{loadingState === 'loading' ? 'loading' : <UnverifiedSuppliesTable />}</div>
            <div className="col-lg-6 mb-4">
              <div className="card bg-light">
                <div className="card-body">
                  <span className="text-bold">Note: </span>Please send a copy of your reciept to
                  moneybackreceipts@gmail.com. The amount will be added to your total supplies discount in 2 business
                  days. All supplies submitted before the 10th of each month will be discounted from the following month
                  lease released on the 11th.
                </div>
              </div>
              <Form className="m-4" onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                <Col>
                  <Form.Group as={Row} controlId="formGridWhat" className="input-group input-group-static mb-4">
                    <label>What did you buy?</label>
                    <Form.Control
                      as="textarea"
                      {...register('what')}
                      name="what"
                      // placeholder="describe what you bought"
                    />
                  </Form.Group>

                  <Form.Group as={Row} controlId="formGridPrice" className="input-group input-group-static mb-4">
                    <label>Price</label>
                    <Form.Control
                      {...register('price')}
                      name="price"
                      type="number"
                      step="any"
                      // placeholder="Price"
                    />
                  </Form.Group>

                  <Form.Group as={Row} controlId="formButtons">
                    <Button variant="info" className="m-2" type="submit">
                      Send
                    </Button>
                  </Form.Group>
                </Col>
              </Form>
            </div>
          </div>
        </div>
      </GCard>

      <GCard header="Verified Supplies History">
        <div className="card-body">
          <div className="card table-responsive p-0 ">
            <table className="table mb-2">
              <thead className="bg-warning">
                <tr>
                  <th className="text-white">Room Name</th>
                  <th className="text-white">Supplies Bought</th>
                </tr>
              </thead>
              <tbody>
                {loadingState === 'loading' ? (
                  <tr>
                    <th>loading</th>
                  </tr>
                ) : (
                  <SuppliesTable />
                )}
              </tbody>
            </table>
          </div>
        </div>
      </GCard>
    </div>
  );
}

export default SuppliesPage;
