import React from 'react';
import Typography from '@mui/material/Typography';
import Button from 'react-bootstrap/Button';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards, Autoplay } from 'swiper';
import { Link } from 'react-router-dom';
import 'swiper/swiper.min.css'; // core Swiper
import 'swiper/css/effect-cards'; // Navigation module
import '../css/style.css';
import { Box } from '@mui/system';

function VideoHeader() {
  return (
    <div className="bg-image pt-5 vh-100 w-100" style={{ backgroundImage: "url('/media/background.png')" }}>
      <div className="container-xxl mt-md-5">
        <div className="row">
          <div className="mb-sm-3 col">
            <Typography
              sx={{
                flexGrow: 0,
                fontFamily: 'Chalkduster',
                fontSize: { xs: 20, sm: 30, md: 35 },
                letterSpacing: { xs: '0rem', md: '.1rem' },
                textAlign: 'center',
                color: 'white',
                textDecoration: 'none',
                position: 'relative',
              }}
            >
              PERFECT SOLUTION <br /> FOR ALL LANDLORDS!!
            </Typography>

            <Box
              sx={{
                mt: 4,
                // display: { xs: "none", md: "block" },
                flexGrow: 0,
                fontFamily: 'Chalkduster',
                fontSize: { xs: 20, sm: 30, md: 35 },
                letterSpacing: { xs: '0rem', md: '.1rem' },
                textAlign: 'center',
                color: 'white',
                textDecoration: 'none',
                position: 'relative',
              }}
            >
              <Swiper
                effect={'cards'}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                grabCursor={true}
                modules={[Autoplay, EffectCards]}
                className="mySwiper"
              >
                <SwiperSlide className="text-center">Manage</SwiperSlide>
                <SwiperSlide>Automate</SwiperSlide>
                <SwiperSlide>Simplify</SwiperSlide>
                <SwiperSlide>Organize</SwiperSlide>
              </Swiper>
              <Link to="/apply" className="text-white">
                <Button className="btn btn-secondary border rounded-pill mt-5">Sign Up Now</Button>
              </Link>
            </Box>
          </div>

          {/* <div className="mb-sm-3 col-md-6">
            <Typography
              sx={{
                flexGrow: 0,
                fontFamily: "Chalkduster",
                fontSize: { xs: 20, sm: 30, md: 35 },
                letterSpacing: { xs: "0rem", md: ".1rem" },
                textAlign: "center",
                color: "white",
                textDecoration: "none",
                position: "relative",
              }}
            >
              QUIET & CLEAN <br /> HOME FOR ROOMIES!!
            </Typography>

            <Box
              sx={{
                mt: 4,
                // display: { xs: "none", md: "block" },
                flexGrow: 0,
                fontFamily: "Chalkduster",
                fontSize: { xs: 20, sm: 30, md: 35 },
                letterSpacing: { xs: "0rem", md: ".1rem" },
                textAlign: "center",
                color: "white",
                textDecoration: "none",
                position: "relative",
              }}
            >
              <Swiper
                effect={"cards"}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                grabCursor={true}
                modules={[Autoplay, EffectCards]}
                className="mySwiper"
              >
                <SwiperSlide className="hello1 text-center">
                  Manage{" "}
                </SwiperSlide>
                <SwiperSlide className="hello1 text-center">
                  Automate
                </SwiperSlide>
                <SwiperSlide className="hello1 text-center">
                  Simplify
                </SwiperSlide>
                <SwiperSlide className="hello1 text-center">
                  Organize
                </SwiperSlide>
              </Swiper>

              <Button className="btn btn-secondary border rounded-pill mt-5">
                Sign Up Now
              </Button>
            </Box>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default VideoHeader;
