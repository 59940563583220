import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useStateValue } from "../../context/StateProvider";
import useFetchOwnersData from "../../hooks/useFetchOwnersData";
import UserAvatar from "../utils/UserAvatar";
import AdminTabs from "./AdminTabs";

function AdminBody() {
  const [{ user }] = useStateValue();
  const [houses, isHousesReady] = useFetchOwnersData("owner_houses");
  const [rooms, isRoomsReady] = useFetchOwnersData("owner_rooms");
  const [supps, setSupps] = useState();

  useEffect(() => {
    setSupps(
      rooms?.filter((filtered) => {
        let result = false;
        filtered.supplies.supplies_items.forEach((verified) => {
          if (verified.verified === 0) result = true;
        });
        return result;
      })
    );
  }, [rooms]);

  return (
    <div className="card card-body mx-2 mx-md-2 mt-3 bg-light">
      <UserAvatar
        avatar="/avatar.jpg"
        title={user.first_name + " " + user.last_name}
      >
        <AdminTabs supps={supps} />
      </UserAvatar>
      <div className="container-fluid py-4 px-0">
        {isHousesReady && isRoomsReady ? (
          <Outlet context={[houses, rooms]} />
        ) : (
          <h2>loading ...</h2>
        )}
      </div>
    </div>
  );
}

export default AdminBody;
