import React from 'react';
import Form from 'react-bootstrap/Form';

function ContactUs() {
  return (
    <section className="pb-5 min-vh-100">
      <div className="container my-auto">
        <div className="row">
          <div className="col-lg-8 col-md-10 col-10 mx-auto mt-8">
            <div className="card ">
              <div className="card-header p-0">
                <div className="bg-black shadow-black rounded-top pt-3 pb-2">
                  <h1 className="text-center text-white mt-2">Contact Us</h1>
                </div>
              </div>
              <div className="card-body p-4">
                <div className="card bg-light">
                  <div className="card-body">
                    Welcome to Sweep and Sleep! We are dedicated to providing excellent customer service and are here to
                    answer any questions or concerns you may have. If you&apos;re a landlord looking for responsible and
                    respectful tenants, or a tenant looking for a clean and quiet living environment, please don&apos;t
                    hesitate to reach out to us. We&apos;ll be happy to assist you in any way we can. You can reach us
                    by:
                    <br />
                    <br />
                    <ul className="list-group">
                      <li> Phone: </li>
                      <li> Email: info@sweepnsleep.com</li>
                    </ul>
                  </div>
                </div>

                <Form className="mt-4">
                  <Form.Group size="lg" className="my-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" />
                    <Form.Text className="text-muted m-1">
                      We&apos;ll never share your email with anyone else.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicMessage">
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                  </Form.Group>
                  <div className="col-md-12 text-center">
                    <button type="submit" className="btn btn-primary mt-3 mb-0">
                      Send Message
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
