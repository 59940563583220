import React, { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import auth from '../../services/authService';
import GCard from '../utils/GCard';
import RoomTable from '../utils/RoomTable';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useStateValue } from '../../context/StateProvider';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Select from 'react-select';
import AddChoreModal from './AddChoreModal';

function ViewHouse() {
  const [roomieFromHomeList, setRoomieFromHomeList] = useState();
  const [loadingState, setLoadingState] = useState('loading');
  const [loadingHouseState, setHouseLoadingState] = useState('loading');
  const [suppliesList, setSuppliesList] = useState();
  const [{ user }] = useStateValue();
  const [showRoomModal, setShowRoomModal] = useState(false);
  const [showNewChoreModal, setShowNewChoreModal] = useState(false);
  const { handleSubmit, register } = useForm({});
  const house = useLocation().state;
  const [houseChores, setHouseChores] = useState(
    house.weekly_chores_0.reduce((prev, curr) => {
      const { chore: chores } = curr;
      for (const chore of chores) {
        if (prev[chore._id] == null) {
          // We leave the roomies empty to start the selection field empty. Removes complexity
          prev[chore._id] = { roomies: [], chore };
        }
      }
      return prev;
    }, {})
  );
  const lastWeeksChores =
    roomieFromHomeList?.reduce((prev, roomie) => {
      if (roomie.past_chore_weekly != null && roomie.past_chore_weekly.length > 0) {
        for (const chore of roomie.past_chore_weekly) {
          if (prev[chore._id] != null) {
            prev[chore._id].roomies.push(roomie.first_name);
          } else {
            prev[chore._id] = { roomies: [roomie.first_name], chore };
          }
        }
      }
      return prev;
    }, {}) ?? {};

  useEffect(() => {
    async function fetchRoomieFromHouseList() {
      await auth.getRoomieFromHouse(house._id).then((res) => {
        setRoomieFromHomeList(res.data);
        setLoadingState('roomiesReady');
      });
    }
    async function getAllSupplies() {
      await auth.getAllHouseSupplies(house._id).then((res) => {
        setSuppliesList(res.data);
        setHouseLoadingState('housesReady');
      });
    }
    fetchRoomieFromHouseList();
    getAllSupplies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function saveChoresAssignment() {
    const roomieIdsToChores = Object.values(houseChores).reduce((prev, { roomies, chore }) => {
      for (const roomie of roomies) {
        if (prev[roomie._id]) {
          prev[roomie._id].chores.push(chore._id);
        } else {
          prev[roomie._id] = { chores: [chore._id] };
        }
      }
      return prev;
    }, {});
    // Fill the remaining roomies
    roomieFromHomeList.forEach((roomie) => {
      if (roomieIdsToChores[roomie._id] == null) {
        roomieIdsToChores[roomie._id] = { chores: [] };
      }
    });
    await auth.saveChoresAssignment(roomieIdsToChores, house._id);
    toast.success('Chores Assigned');
  }
  async function onSubmit(data) {
    await auth
      .addRoom(user._id, house._id, data.room_name)
      .then((res) => {
        console.log(res.data);
        setShowRoomModal(false);
        toast.success('Room Added');
      })
      .catch((err) => {
        toast.error(err.response.data);
      });
  }

  const options = useMemo(
    () => roomieFromHomeList?.map((roomie) => ({ label: roomie.first_name, value: roomie._id })),
    [roomieFromHomeList]
  );

  // specify room name Modal
  const RoomModal = ({ show, handleClose }) => {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add a Room</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)} id="myform-addroom">
            <Form.Group controlId="formRoomName">
              <Form.Label>Room Name</Form.Label>
              <Form.Control name="room_name" {...register('room_name')} type="text" placeholder="Enter Room Name" />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

          <Button variant="primary" type="submit" form="myform-addroom">
            Add the Room
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  function addCreatedChore(chore) {
    // We leave the roomies empty to set the select field as empty initially. It removes complexity
    setHouseChores({ ...houseChores, [chore._id]: { chore: chore, roomies: [] } });
  }
  return (
    <>
      <GCard header="Rooms">
        <div className="row m-4">
          {loadingState === 'roomiesReady' ? <RoomTable rooms={roomieFromHomeList} /> : <h4>loading ...</h4>}
        </div>
        <div className="row m-4">
          <Button variant="primary" onClick={() => setShowRoomModal(true)}>
            Add Room
          </Button>
        </div>
      </GCard>

      <GCard header="Supplies Whiteboard">
        <div className="row m-4 align-center">
          <div className="col-4" />
          <div className="col-4 text-center">
            <div className="card table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th className="">What</th>
                    <th className="">Who</th>
                  </tr>
                </thead>
                <tbody>
                  {house.supplies_whiteboard.map((supply) => (
                    <tr key={supply._id}>
                      <td>{supply.supply}</td>
                      <td>{roomieFromHomeList?.filter((el) => el._id == supply.who)[0].first_name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-4" />
        </div>
      </GCard>
      <GCard header="Chores Assignment">
        <div className="row m-4 align-center">
          <div className=" text-center">
            <h5>Last week&apos;s chores</h5>
            <div className="card table-responsive mb-4">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th className="">Chore</th>
                    <th className="">Who</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.values(lastWeeksChores).map(({ chore, roomies }) => (
                    <tr key={chore._id}>
                      <td>{chore.name}</td>
                      <td>{roomies.join(', ')}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row m-4 align-center">
          <div className=" text-center">
            <h5>This week&apos;s chores</h5>
            <div className="card table-responsive mb-4">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th className="">Chore</th>
                    <th className="">Description</th>
                    <th className="">Who</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.values(houseChores).map(({ chore, roomies }) => (
                    <tr key={chore._id}>
                      <td>{chore.name}</td>
                      <td>{chore.description ?? 'No description provided'}</td>
                      <td>
                        <Select
                          classNamePrefix="addl-class"
                          options={options}
                          onChange={(newVal) => {
                            const newChores = JSON.parse(JSON.stringify(houseChores));
                            newChores[chore._id].roomies = newVal
                              .map((v) => v.value)
                              .map((newRoomieId) => roomieFromHomeList.find((r) => r._id === newRoomieId));
                            setHouseChores(newChores);
                          }}
                          value={roomies.map((roomie) => ({ label: roomie.first_name, value: roomie._id }))}
                          isMulti
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="text-center">
              <Button
                variant="primary"
                onClick={() => {
                  setShowNewChoreModal(true);
                }}
              >
                Add Chore
              </Button>
              <Button variant="info" className="ms-3 pe-3 ps-3" onClick={saveChoresAssignment}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </GCard>

      <GCard header="Verified Supplies History">
        <div className="card-body">
          <div className="card table-responsive p-0 ">
            <table className="table mb-2">
              <thead className="bg-warning">
                <tr>
                  <th className="text-white">Room Name</th>
                  <th className="text-white">Supplies Bought</th>
                </tr>
              </thead>
              <tbody>
                {loadingHouseState === 'loading' ? (
                  <tr>
                    <th>loading</th>
                  </tr>
                ) : (
                  suppliesList.map((supply, j) => (
                    <tr key={j}>
                      <td>{supply.room_name}</td>
                      <td>
                        <div className="card table-responsive p-0 ">
                          <table className="table mb-2 table-striped">
                            <thead className="bg-warning">
                              <tr>
                                <th className="text-white">Date</th>
                                <th className="text-white">Description</th>
                                <th className="text-white">Price</th>
                              </tr>
                            </thead>
                            <tbody>
                              {supply.supplies.supplies_items
                                .filter((sup) => sup.verified === 1)
                                .map((supplyItem, i) => (
                                  <tr className="" key={i}>
                                    <td>{new Date(supplyItem.date).toDateString()}</td>
                                    <td className="text-wrap">{supplyItem.what}</td>
                                    <td>{supplyItem.price}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </GCard>
      <RoomModal
        show={showRoomModal}
        handleClose={() => {
          setShowRoomModal(false);
        }}
      />
      <AddChoreModal
        houseId={house._id}
        setShow={setShowNewChoreModal}
        show={showNewChoreModal}
        actionAfterSave={addCreatedChore}
      />
    </>
  );
}

export default ViewHouse;
