import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import auth from '../services/authService';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

function Login() {
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: { accountType: 'roomie' },
  });

  async function onSubmit(data) {
    await auth
      .login(data.email, data.password, data.account_type)
      .then(() => {
        data.account_type === 'homeowner'
          ? (window.location = '/admin')
          : data.account_type === 'neatkeeper'
          ? (window.location = '/neatkeepers')
          : data.account_type === 'admin'
          ? (window.location = '/admino')
          : (window.location = '/user');
      })
      .catch((error) =>
        error.response.status === 400 ? toast.error('Wrong email or password') : toast.error('Something Wrong Happened')
      );
  }

  return (
    <section className="presentation-page bg-gray-200">
      <div
        className="page-header align-items-start min-vh-100"
        loading="lazy"
        style={{ backgroundImage: `url("/media/greenhouse.jpeg")` }}
      >
        <span className="mask bg-dark opacity-6"></span>
        <div className="container my-auto">
          <div className="row">
            <div className="col-lg-8 col-md-10 col-10 mx-auto mt-8">
              <div className="card ">
                <div className="card-header p-0">
                  <div className="bg-black shadow-black rounded-top pt-3 pb-2">
                    <h1 className="text-center text-white mt-2">Sign In</h1>
                  </div>
                </div>
                <div className="card-body">
                  <Form onSubmit={handleSubmit(onSubmit)} role="form" className="text-start">
                    <Form.Group controlId="email" size="lg" className="my-3">
                      <Form.Label className="form-label">Email</Form.Label>
                      <Form.Control
                        autoFocus
                        type="email"
                        {...register('email')}
                        name="email"
                        className="form-control"
                      />
                    </Form.Group>
                    <Form.Group controlId="password" size="lg" className="mb-3">
                      <Form.Label>Password</Form.Label>
                      <Form.Control name="password" type="password" {...register('password')} />
                    </Form.Group>

                    <Form.Group controlId="AccountType" size="lg" className="mb-3">
                      <Form.Label>Type</Form.Label>
                      <Form.Control
                        {...register('account_type')}
                        onChange={(e) => setValue('account_type', e.target.value)}
                        name="account_type"
                        as="select"
                      >
                        <option key="op-1" value="roomie">
                          Roomie
                        </option>
                        <option key="op-2" value="homeowner">
                          Homeowner
                        </option>
                        <option key="op-3" value="neatkeeper">
                          Neatkeeper
                        </option>
                        <option key="op-4" value="admin">
                          Admin
                        </option>
                      </Form.Control>
                    </Form.Group>

                    <Button size="lg" type="submit" className="btn btn-primary border rounded-pill w-100 my-4 mb-2">
                      Login
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    </section>
  );
}

export default Login;
